import React, { useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import { overviewAPI } from '../../../api/api';


// const data = {
//   // let data = overviewAPI.get_overview_data(model)
//   labels: [12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3],
//       fill: false,
//       backgroundColor: 'rgb(255, 99, 132)',
//       borderColor: 'rgba(255, 99, 132, 0.2)',
//     },
//     {
//       label: '# of No Votes',
//       data: [10,14,1,4,2,1,10,14,1,4,2,1,10,14,1,4,2,1,10,14,1,4,2,1,10,14,1,4,2,1,10,14,1,4,2,1,10,14,1,4,2,1,],
//       fill: false,
//       backgroundColor: 'rgb(54, 162, 235)',
//       borderColor: 'rgba(54, 162, 235, 0.2)',
//     },
//   ],
// };

// const options = {
//   maintainAspectRatio: true,
//   legend: {
//     display: false
//   },
//   scales: {
//     xAxes: [{
//       scaleLabel: {
//         display: false
//       },
//       ticks: {
//         display: false
//       }
//     }],
//     yAxes: [{
//       ticks: {
//         display: false,
//         beginAtZero: true,
//       },
//     }, ],
//   },
// };

const MultiAxisLine = (props) => {


  // const result = get_data_from_api()
  const [date, setDate] = useState()
  const [model_rate, setModelRate] = useState()
  const [kospi_rate, setKospiRate] = useState()
  const [kosdaq_rate, setKosdaqRate] = useState()



  useEffect( () => {
    const get_data_from_api = async () => {
      console.log('get_data_from_api')
      let overview_data = await overviewAPI.get_overview_data(5)
      console.log("overview_data: ", overview_data)
      console.log("overview_data.date: ", overview_data.date)
      console.log("overview_data.model_rate: ", overview_data.model_rate)
      console.log("overview_data.kospi_rate: ", overview_data.kospi_rate)
      setDate(overview_data.date)
      setModelRate(overview_data.model_rate)
      setKospiRate(overview_data.kospi_rate)
      setKosdaqRate(overview_data.kosdaq_rate)
      return overview_data
    }
    
    get_data_from_api();
  }, [])

  return < >
  <Line
    data = {
      {
        labels: date,
        datasets: [{
            label: props.isPush ? 'DeepTrade' : 'XPercent',
            data: model_rate,
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            // borderColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 0.5)',
            pointRadius: 2,
          },
          {
            label: '코스피',
            data: kospi_rate,
            fill: false,
            backgroundColor: 'rgb(54, 162, 235)',
            // borderColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 0.5)',
            pointRadius: 2,
          },
          {
            label: '코스닥',
            data: kosdaq_rate,
            fill: false,
            backgroundColor: 'rgb(100, 100, 100)',
            // borderColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(100, 100, 100, 0.5)',
            pointRadius: 2,
          }
        ]
      }
    }
    options = {
      {
        plugins:
          {
            legend: {
              display: true,
              labels: {
                boxWidth: 20
              }
            },
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                ticks: {
                  display: false,
                }
              },],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              },],
            },
            datalabels: {
              display: false
            }
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10
          }
        }
      }
    }
    
    height={'250vh'}
  />

  </>
}

export default MultiAxisLine;