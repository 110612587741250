import s from "./Admin.module.css";
import { adminApi, appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Dropdown, Menu, Icon, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";

const MobileInviteAdmin = (props) => {
  const [users, setUsers] = React.useState([]);
  const [freeUsers, setFreeUsers] = useState([]);
  const [basicUsers, setBasicUsers] = useState([]);
  const [premiumUsers, setPremiumUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);

  const [value, setValue] = React.useState();

  // const handleMenuClick = async (e) => {
  //   setDropdownLabel(e.domEvent.target.innerText);

  //   let filterResult = await appAdminApi.appMobileUserFilter(e.key);
  //   if (filterResult.status == 200) {
  //     setUsers(filterResult.data);
  //     setFilteredUsers(filterResult.data);
  //   } else {
  //     message.info("데이터를 가져오지 못했습니다.");
  //   }
  // };

  //   const menu = (
  //     <Menu onClick={handleMenuClick}>
  //       <Menu.Item key="1">전체</Menu.Item>
  //       <Menu.Item key="2">Free</Menu.Item>
  //       <Menu.Item key="3">Basic</Menu.Item>
  //       <Menu.Item key="4">Premium</Menu.Item>
  //       <Menu.Item key="5">Admin</Menu.Item>
  //     </Menu>
  //   );
  const columns = [
    // {
    //     cell:(row) => <button className={s.button_cancel}>탈퇴</button>,
    //     ignoreRowClick: true,
    //     allowOverflow: true,
    //     button: true,
    // },
    {
      name: "추천자 이름",
      selector: (row) => row.inviter_name || "-",
      sortable: true,
    },
    {
      name: "추천자 아이디",
      selector: (row) => row.inviter_username,
      sortable: true,
    },
    {
      name: "신규 회원",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "신규 회원 아이디",
      selector: (row) => row.username,
      sortable: true,
    },

    {
      name: "가입일시",
      selector: (row) => row.register_datetime,
      sortable: true,
    },
  ];

  useEffect(async () => {
    let result = await appAdminApi.appInvitersUserList();
    // console.log("result.data in mobileInviteadmin", result.data);
    setUsers(result.data);

    setFilteredUsers(result.data);
  }, []);

  const search = (keyword) => {
    let data = users.filter((val) => {
      return (
        val["username"].includes(keyword) ||
        val["name"].includes(keyword) ||
        val["inviter_username"] == keyword ||
        val["inviter_name"].includes(keyword)
      );
    });
    setFilteredUsers(data);
  };

  return (
    <>
      <div
        className={s.list_container}
        style={{ height: props.isSummary ? 567 : "calc(100vh - 90px)" }}
      >
        <div className={s.title}>추천 History</div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          {" "}
          <div className={s.sub_title}>회원수: {users.length}</div>
        </div>

        <Input
          onChange={(e) => {
            search(e.target.value);
          }}
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="회원 검색 (추천자 아이디, 이름)"
          prefix={<SearchOutlined />}
        />
        <DataTable
          columns={columns}
          data={filteredUsers}
          pagination="true"
          paginationPerPage={props.rows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            tableWrapper: {
              style: {
                display: "block",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default MobileInviteAdmin;
