import s from "./About.module.css";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { WEB_APP_ROUTES } from "../../../constants/routes";
// import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
// import SwiperCore, {Pagination} from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import "swiper/swiper-bundle.min.css";
import "swiper/modules/pagination/pagination.min.css";
// import MultiAxisLine from '../../../components/Charts/examples/MultiAxisLine';
// import { overviewAPI } from '../../../api/api';
Chart.register(ChartDataLabels);

const AboutPart = () => {
  const [model, setModel] = useState();
  const [kospi, setKospi] = useState();
  const [kosdaq, setKosdaq] = useState();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        labels: {
          index: {
            display: true,
            color: ["#646464", "#646464", "#ba5252"],
            align: "end",
            anchor: "end",
            formatter: function (value, context) {
              var label = context.chart.data.labels[context.dataIndex];
              return label;
            },
            font: {
              weight: "bold",
              size: 30,
            },
          },
          value: {
            display: true,
            color: "white",
            align: "middle",
            formatter: function (value, context) {
              return parseFloat(value).toFixed(1) + "%";
            },
            font: {
              weight: "bold",
              size: 15,
            },
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 50,
        left: -30,
        right: 50,
      },
    },
  };

  //   const labels = ['KOSPI', 'KOSDAQ', 'XPercent'];

  //   const data = {
  //     labels,
  //     datasets: [
  //       {
  //         label: 'Dataset 1',
  //         data: [kospi, kosdaq, model],
  //         backgroundColor: ['#646464', '#646464', '#ba5252'],
  //       },
  //     ],
  //   };

  //   useEffect( () => {
  //     const get_data_from_api = async () => {
  //       console.log('get_data_from_api')
  //       let overview_data = await overviewAPI.get_overview_data(5)

  //       var model_rate = overview_data.model_rate;
  //       var kospi_rate = overview_data.kospi_rate;
  //       var kosdaq_rate = overview_data.kosdaq_rate;
  //       var model_value = (((model_rate[model_rate.length - 1]) - 1) * 100);
  //       var kospi_value = (((kospi_rate[kospi_rate.length - 1]) - 1) * 100);
  //       var kosdaq_value = (((kosdaq_rate[kosdaq_rate.length - 1]) - 1) * 100);
  //       console.log(model_value)
  //       console.log(kospi_value)
  //       console.log(kosdaq_value)
  //       setModel(model_value);
  //       setKospi(kospi_value);
  //       setKosdaq(kosdaq_value);
  //     }

  //     get_data_from_api();
  //   }, [])

  return (
    <>
      <div className={s.about}>
        <div className="container-fluid p-0">
          <div className={`${s.container}`}>
            {/* <div className={s.about_slider}>
                        <Swiper
                            spaceBetween={50}
                            pagination={{
                                "dynamicBullets": true
                            }}
                            slidesPerView={1}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            >
                            <SwiperSlide>
                                <div>
                                    <div className='row overview-chart' style={{width: '40%'}}>
                                        <div className="col-12 pt-3 pb-2 text-center">
                                            XPercent 예측 현황
                                        </div>
                                        <div className="col-12 px-3" id="chart_plot">
                                            <MultiAxisLine/>
                                        </div>
                                    </div>
                                    <p>
                                        (XPercent 연 수익률: 1주 모델)
                                        <br/><br/>
                                        XPercent 기본 모델은 지난 1년 간 <strong>51.1%</strong>의 수익률을 기록하였으며, 이는 동일 기간 코스피 지수보다 
                                        <strong> 65.2%</strong>, 코스닥 지수보다 <strong>57.6%</strong> 높은 수치입니다.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div style={{margin: '0 0 50px 0', width: '50%'}}>
                                    <img src="/static/main_signal_example.png" width={'30%'} style={{float: 'left', paddingRight: 50}}/>
                                    <p>
                                        <strong>XPercent 서비스: Signal</strong>
                                        <br/><br/>
                                        Signal은 ~~~~~한 서비스입니다.<br/>
                                        (본문 내용)<br/><br/>
                                        (추가 내용: 사용법)<br/>
                                        (추가 내용: 뭐가 좋은가)<br/>
                                        등
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div style={{width: '50%'}}>
                                    <p style={{margin: '0px 20px'}}>
                                        XPercent는 <strong>인공지능 기반의 모델</strong>이 상승 종목을 선정해 추천하는 주가 예측 서비스입니다.
                                        일반적인 투자 프로그램, 사람이 보지 못하는 패턴을 파악해서 안정적이고 높은 수익률을 제공합니다.
                                        <br/><br/><br/>
                                        투자자는 XPercent를 이용해 본인의 투자 성향 등 여러 조건을 고려, 예측 기간이나 분야별 섹터 등을 설정할 수 있습니다.
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div> */}
            {/* 
                    <div className={s.about_1} style={{display: 'none'}}>
                        <div className={'container p-0 '}>
                            <div className={'row'}>
                                <div className={'col-6'}
                                     data-aos={"fade-right"} data-aos-duration={"1000"}
                                >
                                    <div className={s.about_left_topic}>
                                        인공지능이 <br/>
                                        주가 예측을
                                        수행할 수 있을까?
                                    </div>
                                    <div className={s.left_hr}/>
                                    <div
                                        className={s.about_content}>
                                        XPercent는
                                        서울대학교 출신 연구팀이 만든 스타트업 DeepTrade가<br/>
                                        위 질문에 대답하기 위해 개발한
                                        인공 지능 모델입니다.
                                    </div>
                                    <div
                                        className={s.about_content}>
                                        XPercent 모델은 지난 <span className={s.red_bold}>1년 간 {parseFloat(model).toFixed(1)}%</span>의 <span className={s.red_bold}>수익률</span>을 기록하였으며, <br />
                                        이는 동일 기간 코스피 지수보다 <span className={s.red_bold}>{parseFloat(model-kospi).toFixed(1)}%</span>, 코스닥 지수보다 <span className={s.red_bold}>{parseFloat(model-kosdaq).toFixed(1)}%</span> 높은 수치입니다.
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: -20
                                        }}
                                    >
                                        <Bar 
                                            plugins={[ChartDataLabels]}
                                            options={options}
                                            data={data}
                                        />
                                    </div>
                                </div>
                                <div className={'col-6'}
                                    data-aos={"fade-left"} data-aos-duration={"1000"}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}

                                >
                                    <MultiAxisLine />
                                </div>
                            </div>

                        </div>
                    </div> */}
            {/* 
                    <div className={s.about_2}>
                        <div className={'container p-0 '}>
                            <div className={'row'}>
                                <div className={'col-4'}
                                     data-aos={"fade-right"} data-aos-duration={"1000"}
                                >
                                    <img src="/static/icons/main2.png" alt="xpct logo" width={'300px'}/>
                                </div>
                                <div className={'col-8'}
                                     data-aos={"fade-left"} data-aos-duration={"1000"}
                                >
                                    <div className={s.about_right_topic}>
                                        현재의 <br/>
                                        로보 어드바이저
                                    </div>
                                    <div className={s.right_hr}/>
                                    <div className={s.about_content} style={{textAlign: "right"}}>
                                        로보 어드바이저라 불리던 지금까지의 투자 모델은 기존의
                                        재무학 이론을 프로그램화한 계량 분석 수준에서 크게 벗어나지 않습니다.
                                        <br/>
                                        이러한 모델은 정해진 자산 내에서의 배분에만 집중하고,
                                        주가의 복잡한 패턴을 지나치게 단순화하여 파악하기 때문에,
                                        단지 시장의 흐름에 편승하여 매수와 매도를 반복하는 정도의 기능밖에 수행하지
                                        못했습니다.
                                        <br/>
                                        로보 어드바이저는 수년 전까지 크게 주목을 받았지만 이제 많은
                                        이들의 관심에서 멀어졌습니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* 
                    <div className={s.about_1}>
                        <div className={'container p-0 '}>
                            <div className={'row'}>
                                <div className={'col-6'}
                                     data-aos={"fade-right"} data-aos-duration={"1000"}
                                >
                                    <img 
                                        src="../../../static/main_2.png"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                    <div className={s.left_hr}/>
                                    <div
                                        className={s.about_content}>
                                        XPercent는 <strong>인공지능 기반의 모델</strong>이 상승 종목을 선정해 추천하는 주가 예측 서비스입니다. 일반적인 투자 프로그램, 사람이 보지 못하는 패턴을 파악해서 안정적이고 높은 수익률을 제공합니다.<br />투자자는 XPercent를 이용해 본인의 투자 성향 등 여러 조건을 고려하여 예측 기간이나 섹터 등을 설정할 수 있습니다.
                                    </div>
                                </div>
                                <div className={'col-6'}
                                    data-aos={"fade-left"} data-aos-duration={"1000"}

                                >
                                    <img 
                                        src="../../../static/main_1.png"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </div> */}

            {/* <div className={s.about_3}>
                        <div className={'container p-0 '}
                             data-aos={"fade-right"} data-aos-duration={"1000"}
                        >
                        <div className={s.about_left_topic}>
                            <strong>XPercent</strong>
                        </div>
                        <div className={s.left_hr}/>
                        <div className={s.about_content}>
                            인덱스 지수보다 X% 더 높은 수익률을 제공한다는 의미의
                            XPercent 인공 지능 모델은 <br/> 주가 외에도 접근할 수 있는 모든
                            정보를 사용하여 사람이 찾아낼 수 없는 복잡한 패턴을 탐색합니다.
                            <br/>
                            이러한 패턴을 바탕으로 XPercent 모델은 안정적인 수익을 제공하는
                            개별 종목을 선택하고 최적화된 포트폴리오를 제공합니다.
                        </div>
                        </div>
                    </div> */}

            <div className={s.footer}>
              <div className={s.footer_container}>
                <div className={s.footer_tags} data-aos="fade-right">
                  {/* <a href={'/terms_of_use'}>회원이용약관</a> · <a href={'/privacy_policy'}>개인정보처리방침</a> */}
                  <span
                    style={{
                      fontSize: 24,
                      fontWeight: "bold",
                      color: " #b1b1b1",
                    }}
                  >
                    DeepTrade Technologies는 AI 기반 주식 투자 기술의 미래를
                    선도합니다.
                  </span>
                </div>
                {/* <NavLink className={s.link} to={WEB_APP_ROUTES.TERMS_OF_USE}>
                                회원이용약관
                            </NavLink>
                            &#8226;
                            <NavLink className={s.link} to={WEB_APP_ROUTES.PRIVACY_POLICY}>
                                개인정보처리방침
                            </NavLink> */}
              </div>
              <hr className={s.incHr} />
              <div className={"container p-0"}>
                <div
                  className="row col-md-9 col-9"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div
                    className={`${s.incName} col my-auto`}
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    딥트레이드테크놀로지스 주식회사 <br /> (DeepTrade
                    Technologies)
                  </div>
                  <div
                    className={`${s.incDetail} col text-left`}
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    대표자명: 김남균 <br />
                    TEL: 02-875-5677 <br />
                    EMAIL: deeptrade.contact@gmail.com <br />
                    주소: 서울특별시 관악구 관악로 1, 138동 3층 314호 (신림동,
                    서울대학교) <br />
                    사업자등록번호: 361-88-01222 <br />
                    통신판매업신고번호: 제 A07-20211216-0003 호 <br />
                    Copyright © 2021 DeepTrade Technologies <br />
                    All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPart;
