import s from './ViewPost.module.css';

import Table from '../../Table/Table';
import TableColumn from '../../Table/TableColumn';
import TableRow from '../../Table/TableRow';

const ViewPost = ( props ) => {
  console.log(props)
    return <>
        <Table headersName={['제목', props.post.title]}>
        {
            <TableRow>
                <TableColumn>{ '' }</TableColumn>
                <TableColumn>{ props.post.content }</TableColumn>
            </TableRow>
        }
        </Table>

        <div style={{margin: '30px'}}/>

        <button className={`btn btn-dark shadow ${s.button}`} onClick={() => props.listView()}>
            목록
        </button>
    </>
}

export default ViewPost