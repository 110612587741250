import s from "../Admin.module.css";
import Header from "../../../components/Header/Signed/Header";
import { useSelector } from "react-redux";
import { getUserSelector } from "../../../redux/user-selector";
import HeaderUnsigned from "../../../components/Header/UnSigned/HeaderUnsigned";
import { NavLink } from "react-router-dom";
import { WEB_APP_ROUTES } from "../../../constants/routes";
import React, { useEffect, useState } from "react";

import MobileAppAnalytics from "../MobileAppAnalytics";

const MobileAppAnalyticsStackScreen = () => {
  const user = useSelector(getUserSelector);
  const [update, setUpdate] = React.useState(false);

  return (
    <>
      {user.role ? <Header /> : <HeaderUnsigned />}
      <div className="container-fluid">
        <div class="row" style={{ position: "relative" }}>
          <nav class="col-2 d-none d-md-block bg-light sidebar">
            <div class="sidebar-sticky">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <NavLink class="nav-link" to={WEB_APP_ROUTES.APPADMIN}>
                    대시보드
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink class="nav-link" to={WEB_APP_ROUTES.APPUSERLIST}>
                    회원 목록
                  </NavLink>
                </li>

                <li class="nav-item">
                  <NavLink class="nav-link" to={WEB_APP_ROUTES.APPINQUIRYLIST}>
                    문의 목록
                  </NavLink>
                </li>

                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to={WEB_APP_ROUTES.APPANNOUNCEMENTADMIN}
                  >
                    공지사항
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink class="nav-link" to={WEB_APP_ROUTES.APPPAYMENTADMIN}>
                    결제 내역
                  </NavLink>
                </li>
                <li class="nav-item ">
                  <NavLink class="nav-link" to={WEB_APP_ROUTES.APPINVITERADMIN}>
                    추천 History
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to={WEB_APP_ROUTES.APPXPERCENTTIPADMIN}
                  >
                    주식 투자 IQ 테스트
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink class="nav-link" to={WEB_APP_ROUTES.APPLOGINRECORD}>
                    로그인 내역
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    class="nav-link active"
                    to={WEB_APP_ROUTES.APPANALYTICS}
                  >
                    App Analytics
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    class="nav-link"
                    to={WEB_APP_ROUTES.ENGINEMONITORING}
                  >
                    Engine Monitoring
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink class="nav-link" to={WEB_APP_ROUTES.DTENTERPRISE}>
                    DT Enterprise Admin
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
          <main
            role="main"
            class="col-10"
            style={{ marginLeft: "auto", paddingTop: 10 }}
          >
            <MobileAppAnalytics rows={10} isSummary={false} />
          </main>
        </div>
      </div>
    </>
  );
};

export default MobileAppAnalyticsStackScreen;
