import React, { PureComponent } from 'react';
import ErrorPage from "../../pages/ErrorPage";

/**
 *    Don't touch
 * */


class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(err, info) {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage />;
    }

    return children;
  }
}


export { ErrorBoundary };
