import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";

import "./App.css";
import ROUTES from "./features/routing/config";
import { displayRouteMenu } from "./features/routing/Navigation";
import RenderRoutes from "./features/routing";
import { reduxStore, persistor } from "./redux/redux-store";
import { ErrorBoundary } from "./features/ErrorBoundary";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { WithNavbar } from "./templates/WithNavbar";
import ScrollToTop from "./ScrollToTop";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  useEffect(() => {
    if (window.location.host == "xpct.net") {
      window.location.replace("https://xpercent.io" + window.location.pathname);
    }
    Aos.init({
      /** this is for animations don't touch */ duration: 2000,
    });
  }, []);

  return (
    <Router>
      <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <ScrollToTop />
            <RenderRoutes routes={ROUTES} />
            {/* <WithNavbar/> */}
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
