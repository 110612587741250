import {useSelector} from 'react-redux';
import {message} from 'antd';
import PERMISSIONS_FOR from "../../constants/FeatureFlags";
import {getUserSelector} from "../../redux/user-selector";

const useCheckPermission = ( ) => {
        /**
         * Don't touch - Recommended
         * */
    const user = useSelector(getUserSelector)


   const couldShow = (permission) => {
       let ok = PERMISSIONS_FOR[user.role].includes(permission);
       // ok || message.info('upgrade to use it', 1)
       console.log('COULDSHOW OK: ', ok)
       return ok
   }


    return {
        couldShow
    };
};

export default useCheckPermission;
