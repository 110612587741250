import {FEATURE_PERMISSIONS} from "./permissions";

/**
 * Permissions based on auth Role
 */

const PERMISSIONS_FOR = {
    'ADMIN': [FEATURE_PERMISSIONS.EIGHT_WEEKS_MODEL, FEATURE_PERMISSIONS.FOUR_WEEKS_MODEL, 
        FEATURE_PERMISSIONS.TWO_WEEKS_MODEL, FEATURE_PERMISSIONS.ONE_WEEK_MODEL, 
        FEATURE_PERMISSIONS.SECTORS, FEATURE_PERMISSIONS.TOP_SECTORS, 
        FEATURE_PERMISSIONS.ONE_DAY_MODEL],
    'PRO': [FEATURE_PERMISSIONS.EIGHT_WEEKS_MODEL, FEATURE_PERMISSIONS.FOUR_WEEKS_MODEL, 
            FEATURE_PERMISSIONS.TWO_WEEKS_MODEL, FEATURE_PERMISSIONS.ONE_WEEK_MODEL, 
            FEATURE_PERMISSIONS.SECTORS, FEATURE_PERMISSIONS.TOP_SECTORS, 
            FEATURE_PERMISSIONS.ONE_DAY_MODEL],
    'LITE': [FEATURE_PERMISSIONS.ONE_WEEK_MODEL, FEATURE_PERMISSIONS.ONE_DAY_MODEL, 
             FEATURE_PERMISSIONS.TOP_SECTORS],
    'LITE-FREE': [FEATURE_PERMISSIONS.ONE_WEEK_MODEL, FEATURE_PERMISSIONS.ONE_DAY_MODEL, 
                FEATURE_PERMISSIONS.TOP_SECTORS],
    'FREE': [FEATURE_PERMISSIONS.ONE_WEEK_MODEL, FEATURE_PERMISSIONS.ONE_DAY_MODEL, 
             FEATURE_PERMISSIONS.TOP_SECTORS],
    'PUSH_ADMIN': []
};

export default PERMISSIONS_FOR;