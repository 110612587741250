import s from './ViewList.module.css';

import Table from '../../Table/Table';
import TableColumn from '../../Table/TableColumn';
import TableRow from '../../Table/TableRow';

const ViewList = ( props ) => {
    return <>
        <Table headersName={['번호', '제목', '등록일']}>
        {
          props.dataList ? props.dataList.map((item, index) => {
            return (
                    <TableRow key={index}>
                        <TableColumn>{ item.id }</TableColumn>
                        <TableColumn><div onClick={() => props.postView(item.id)}>{ item.title }</div></TableColumn>
                        <TableColumn>{ item.create_date.substring(0, 10) }</TableColumn>
                    </TableRow>
            )
          }) : ''
        }
        </Table>

        <div style={{margin: '10vh'}}/>

        {props.user.role == 'ADMIN' &&
            <button className={`btn btn-dark shadow ${s.button}`} onClick={() => props.createPost()}>
                글쓰기
            </button>}
    </>
}

export default ViewList