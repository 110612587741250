import s from "./Admin.module.css";
import { appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Input, Modal, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";
const MobileInquiryList = (props) => {
  const [inquiry, setInquiry] = React.useState([]);
  const [filteredInquiry, setFilteredInquiry] = React.useState([]);
  const [searchInquiry, setSearchInquiry] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [id, setID] = useState(0);
  const [userID, setUserID] = useState(0);
  const [answer, setAnswer] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [inquiryDate, setInquiryDate] = useState("");
  const [answerDate, setAnswerDate] = useState("");
  const { TextArea } = Input;

  const showModal = async (
    title,
    content,
    id,
    answer,
    user_id,
    inquiry_date,
    answer_date,
    username
  ) => {
    setTitle(title);
    setContent(content);
    setID(id);
    setAnswer(answer);
    setNewAnswer("");
    setUserID(user_id);
    const inquiry_date_object = new Date(inquiry_date);
    let inquiryText = inquiry_date_object.toLocaleString();
    setInquiryDate(inquiryText);
    const answer_date_object = new Date(answer_date);
    let answerText = answer_date_object.toLocaleString();
    setAnswerDate(answerText);
    setName(username);

    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = async () => {
    setLoading(true);
    var sendAnswer = newAnswer;
    var match = /\r|\n/.exec(sendAnswer);
    if (match) {
      const regex = /\r|\n/g;
      sendAnswer = sendAnswer.replaceAll(regex, "<br>");
      // Found one, look at `match` for details, in particular `match.index`
    }
    var result = await appAdminApi.appupdateInquiry(
      title,
      content,
      sendAnswer,
      id,
      userID
    );
    setLoading(false);
    if (result == 200) {
      let result = await appAdminApi.appInquiryListView();

      setInquiry(result.data);
      setFilteredInquiry(result.data);
      setSearchInquiry(result.data);
    }
    setIsModalVisible(false);
  };
  const columns = [
    {
      name: "문의일시",
      selector: (row) => {
        const inquiry_datetime_object = new Date(row.inquiry_datetime);
        let inquiry_datetime_text = inquiry_datetime_object.toLocaleString();
        return inquiry_datetime_text;
      },
      sortable: true,
      grow: 0.5,
      wrap: true,
    },
    {
      name: "아이디",
      selector: (row) =>
        row.inquiry_type_flag === 1 ? `${row.username}_m` : row.username,
      sortable: true,
      grow: 0.5,
    },
    {
      name: "문의 제목",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "문의 내용",
      selector: (row) => row.inquiry,
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          onClick={() =>
            showModal(
              row.title,
              row.inquiry,
              row.id,
              row.answer,
              row.user_id,
              row.inquiry_datetime,
              row.answer_datetime,
              row.username
            )
          }
          className={row.answer == "" ? s.button : s.button_cancel}
        >
          {row.answer == "" ? "답장하기" : "답장보기"}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  useEffect(async () => {
    let result = await appAdminApi.appInquiryListView();
    setInquiry(result.data);
    setFilteredInquiry(result.data);
    setSearchInquiry(result.data);
  }, []);
  const switchFunction = (event) => {
    if (event) {
      let data = inquiry;
      setFilteredInquiry(data);
      setSearchInquiry(data);
    } else {
      let data = inquiry.filter((val) => {
        return val["answer"] == "";
      });
      setFilteredInquiry(data);
      setSearchInquiry(data);
    }
    setInputValue("");
  };
  const search = (keyword) => {
    setInputValue(keyword);
    let data = filteredInquiry.filter((val) => {
      return (
        val["user_id"] == keyword ||
        val["name"].includes(keyword) ||
        val["inquiry"].includes(keyword)
      );
    });
    setSearchInquiry(data);
  };
  return (
    <>
      <div
        className={s.list_container}
        style={{ height: props.isSummary ? 677 : "calc(100vh - 90px)" }}
      >
        <div style={{ float: "right" }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>답 미완료</Typography>
            <Switch
              defaultChecked
              onChange={(checked) => {
                switchFunction(checked);
              }}
            />
            <Typography>전체보기</Typography>
          </Stack>
        </div>
        <div className={s.title}>문의 목록</div>
        <div className={s.sub_title}>
          <span style={{ color: "#2F3A8F" }}>
            답 완료:{" "}
            {
              inquiry.filter((val) => {
                return val["answer"] !== "";
              }).length
            }
          </span>
          <span style={{ color: "#FF1700", marginLeft: 10 }}>
            답 미완료:{" "}
            {
              inquiry.filter((val) => {
                return val["answer"] == "";
              }).length
            }
          </span>
        </div>
        <Input
          style={{ marginTop: 10, marginBottom: 10 }}
          value={inputValue}
          onChange={(e) => {
            search(e.target.value);
          }}
          placeholder="문의 검색"
          prefix={<SearchOutlined />}
        />
        <DataTable
          columns={columns}
          data={searchInquiry}
          pagination="true"
          paginationPerPage={props.rows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            tableWrapper: {
              style: {
                display: "block",
              },
            },
          }}
        />
      </div>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"답장"}
        cancelText={"닫기"}
        okButtonProps={{
          style: { display: answer == "" ? "inline-block" : "none" },
        }}
        confirmLoading={loading}
      >
        <div className={s.inquiry_body}>
          <div>
            <div className={s.inquirer_icon}>
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128.000000 128.000000"
                preserveAspectRatio="xMidYMid meet"
                style={{
                  width: 15,
                  height: 38,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <g
                  transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                  fill="#FFFFFF"
                  stroke="none"
                >
                  <path
                    d="M463 1261 c-222 -65 -391 -239 -448 -461 -26 -99 -17 -282 17 -377
                        65 -182 214 -330 398 -394 48 -16 95 -24 177 -27 138 -5 228 13 329 66 l72 38
                        55 -53 c52 -50 58 -53 107 -53 44 0 56 4 81 29 25 25 29 37 29 81 0 49 -3 55
                        -53 107 l-53 55 38 72 c53 100 71 191 66 329 -3 87 -10 128 -30 184 -65 182
                        -214 330 -398 394 -90 32 -296 37 -387 10z m352 -255 c88 -44 155 -112 198
                        -204 29 -61 32 -76 32 -162 0 -77 -5 -104 -23 -144 l-23 -50 -52 52 c-38 37
                        -64 54 -89 58 -78 13 -147 -56 -134 -134 4 -25 21 -51 58 -89 l52 -52 -50 -23
                        c-40 -18 -67 -23 -145 -23 -90 0 -99 2 -170 37 -93 46 -158 112 -202 206 -47
                        98 -47 226 0 324 40 85 106 156 182 197 79 42 122 51 215 46 66 -3 91 -9 151
                        -39z"
                  />
                </g>
              </svg>
            </div>
            <div className={s.inquirer_name}>
              <div style={{ height: 34, fontWeight: "bold", fontSize: 20 }}>
                {name}
              </div>
              <div className={s.inquirer_time}>{inquiryDate}</div>
            </div>
          </div>
          <div className={s.inquiry_body_question}>{content}</div>
          <div className={s.answer_icon}>
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128.000000 128.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                width: 15,
                height: 38,
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <g
                transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                fill="#FFFFFF"
                stroke="none"
              >
                <path
                  d="M561 1253 c-20 -21 -75 -159 -224 -566 -218 -593 -221 -605 -168
                -658 25 -24 37 -29 80 -29 80 0 100 25 164 205 l54 150 173 0 173 0 54 -150
                c64 -179 85 -205 162 -205 40 0 54 5 80 29 55 51 51 65 -148 608 -99 269 -189
                514 -201 545 -27 74 -58 98 -122 98 -41 0 -54 -5 -77 -27z m122 -545 c20 -57
                37 -106 37 -110 0 -5 -36 -8 -80 -8 -44 0 -80 3 -80 8 0 14 74 212 80 212 3 0
                22 -46 43 -102z"
                />
              </g>
            </svg>
          </div>
          {answer != "" && (
            <div className={s.inquirer_name}>
              <div style={{ height: 34, fontWeight: "bold", fontSize: 20 }}>
                관리자
              </div>
              <div className={s.inquirer_time}>{answerDate}</div>
            </div>
          )}
          {answer == "" ? (
            <TextArea
              maxLength={1000}
              value={newAnswer}
              onChange={(val) => {
                setNewAnswer(val.target.value);
              }}
              className={s.inquiry_body_answer}
            />
          ) : (
            <div style={{ marginTop: 20 }}>
              {answer.split("<br>").map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
export default MobileInquiryList;
