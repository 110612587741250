import {applyMiddleware, combineReducers, createStore} from "redux";
import usersReducer from "./user-reducer";

import thunkMiddleware from "redux-thunk";
import signalReducer from "./signal-reducer";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";
//Library that allows persistence of redux state values
import {persistStore, persistReducer} from "redux-persist";
//Module for sessionStorage
import sessionStorage from 'redux-persist/lib/storage/session';
//Library for redux-persist sessionStorage value encryption
import { encryptTransform } from 'redux-persist-transform-encrypt';
import session from "redux-persist/lib/storage/session";


let rootReducer = combineReducers({
    signal: signalReducer,
    userPage: usersReducer
})

//Encryptor for creating redux-persist reducer
const encryptor = encryptTransform({
    secretKey: "randomKey", //Passphrase used to create 256bit AES key.
    onError: function(error) {
        console.log(error);
    }
})

//Config dict to create persist reducer
const persistConfig = {
    key: 'user',
    storage: sessionStorage,
    whitelist: ['userPage'],
    transforms: [encryptor]
}

//Create pReducer that will be used instead of directly using rootReducer
const pReducer = persistReducer(persistConfig, rootReducer);

const reduxStore = createStore(pReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))
// const reduxStore = createStore(rootReducer, applyMiddleware(thunkMiddleware))

const persistor = persistStore(reduxStore);

// window.__store__ = reduxStore

export {reduxStore, persistor}