import s from "./Admin.module.css";
import { adminApi, appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Dropdown, Menu, Icon, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import toast, { Toaster } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
// const CheckBoxButton = ({ data, name , username}) => {
//   console.log("data in checkbox", data);
//   const onChange = async(e) => {
//     const res_check = e.target.checked;
//     console.log("clicked  ==>", res_check, name, username);
//     const res_update = await appAdminApi.updateDTUserInfoAdmin(res_check, name, username)
//     if(res_update.status===200){
//         console.log(res_update.data)
//     }
//   };

//   return (
//     <div style={{ margin: 20 }}>
//       <label>
//         <Checkbox
//           checked={data}
//           onChange={onChange}
//           //   disabled={data}
//         />
//         &nbsp; {name}
//       </label>
//     </div>
//   );
// };
const DtEnterpriseAdmin = (props) => {
  const [dropdownLabel, setDropdownLabel] = useState("전체");
  const [userDataList, setUserDataList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [shannonTop20State, setShannonTop20State] = useState(null);
  const [shannonTop5State, setShannonTop5State] = useState(null);
  const [indexRm1dState, setIndexRm1dState] = useState(null);
  const [indexRm1wState, setIndexRm1wState] = useState(null);
  const RefreshDTUserinfoData = async () => {
    // setLoader(true);
    let result = await appAdminApi.fetchDtUserInfoAdmin();
    if (result.status === 200) {
      setUserDataList(result.data);
    } else if (result.status === 500) {
      toast("서버에 접근하지 못했습니다. 관리자에게 문의해 주세요.");
    } else {
      toast("데이터를 불러오지 못했습니다. 다시 시도해 보세요.");
    }
    // setLoader(false);
  };
  const onChange = async (e, username, model_type) => {
    const res_check = e.target.checked;
    console.log("clicked  ==>", res_check, model_type, username);
    const res_update = await appAdminApi.updateDTUserInfoAdmin(
      res_check,
      username,
      model_type
    );
    if (res_update.status === 200) {
      console.log(res_update.data);
      setUserDataList(res_update.data);
      //   RefreshDTUserinfoData();
    } else if (res_update.status === 500) {
      toast("서버에 접근하지 못했습니다. 관리자에게 문의해 주세요.");
    } else {
      toast("데이터를 불러오지 못했습니다. 다시 시도해 보세요.");
    }
  };

  const columns = [
    {
      name: "회사 이름",
      selector: (row) => row.company,
      sortable: true,
      wrap: true,
    },
    {
      name: "아이디",
      selector: (row) => row.username,
      sortable: true,
      wrap: true,
    },
    {
      name: "섀넌 개별종목",
      selector: (row) => {
        return (
          <>
            <div style={{ margin: 10 }}>
              <label>
                <Checkbox
                  checked={row.shannon_top5}
                  value={row.shannon_top5}
                  onChange={(e) => {
                    onChange(e, row.username, "shannon_top5");
                  }}
                />
                &nbsp; Top 5
              </label>
            </div>
            <div style={{ margin: 10 }}>
              <label>
                <Checkbox
                  checked={row.shannon_top20}
                  value={row.shannon_top20}
                  onChange={(e) => {
                    onChange(e, row.username, "shannon_top20");
                  }}
                />
                &nbsp; Top 20
              </label>
            </div>
          </>
        );
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "섀넌 지수",
      selector: (row) => {
        return (
          <>
            <div style={{ margin: 10 }}>
              <label>
                <Checkbox
                  checked={row.index_rm_1d}
                  value={row.index_rm_1d}
                  onChange={(e) => {
                    onChange(e, row.username, "index_rm_1d");
                  }}
                />
                &nbsp; 1일
              </label>
            </div>
            <div style={{ margin: 10 }}>
              <label>
                <Checkbox
                  checked={row.index_rm_1w}
                  value={row.index_rm_1w}
                  onChange={(e) => {
                    onChange(e, row.username, "index_rm_1w");
                  }}
                />
                &nbsp; 1주
              </label>
            </div>
          </>
        );
      },
      sortable: true,
    },
  ];

  const getDTUserinfoData = async () => {
    setLoader(true);
    let result = await appAdminApi.fetchDtUserInfoAdmin();
    if (result.status === 200) {
      setUserDataList(result.data);
    } else if (result.status === 500) {
      toast("서버에 접근하지 못했습니다. 관리자에게 문의해 주세요.");
    } else {
      toast("데이터를 불러오지 못했습니다. 다시 시도해 보세요.");
    }
    setLoader(false);
  };

  useEffect(() => {
    getDTUserinfoData();
  }, []);
  console.log("userDataList", userDataList);
  return (
    <>
      <div
        className={s.list_container}
        style={{ height: props.isSummary ? 677 : "calc(100vh - 90px)" }}
      >
        <div className={s.title}>DeepTrade Enterprise </div>
        {loader ? (
          <Oval
            height={50}
            width={50}
            color="#4fa94d"
            wrapperStyle={{ alignItems: "center", justifyContent: "center" }}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <>
            {userDataList && (
              <DataTable
                columns={columns}
                data={userDataList}
                pagination="true"
                paginationPerPage={props.rows}
                paginationComponentOptions={{ noRowsPerPage: true }}
                customStyles={{
                  tableWrapper: {
                    style: {
                      display: "block",
                    },
                  },
                }}
              />
            )}
          </>
        )}
      </div>
      <Toaster />
    </>
  );
};

export default DtEnterpriseAdmin;
