import s from './NotFoundPage.module.css'
import {Link} from "react-router-dom";
import {WEB_APP_ROUTES} from "../../constants/routes";
import {useDispatch} from "react-redux";
import { logout } from '../../redux/user-reducer';
const NotFoundPage = ( ) => {
    const dispatch = useDispatch()
    function refresh() {
        dispatch(logout())
    
    }
    return <div className={`${s.layout}`}>
        <div className={'container'}>
            <div className={'row justify-content-center'}>
                <img className={`${s.image} mt-5`} src="/static/icons/404error.png" alt="not found page image"/>
            </div>
            <div className={'row justify-content-center'}>

                <Link className={`${s.link} col-8 col-md-4 col-lg-2`} to={WEB_APP_ROUTES.MAIN} onClick={refresh}>
                    <div className={`${s.button} btn col-12`}>
                        홈으로 이동
                    </div>
                </Link>
            </div>
        </div>
    </div>
}

export default NotFoundPage