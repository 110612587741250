const MenuIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="white" width="45" height="45" viewBox="0 0 36 36" style={{verticalAlign: "bottom"}}>
        <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/></svg>
}

export default MenuIcon

// const MenuIcon = ({svgClass, ...rest}) => {

//     return   <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
//         {...rest}
//          className={svgClass}
//          width="40.000000pt" height="25.000000pt" viewBox="0 0 300.000000 300.000000"
//          preserveAspectRatio="xMidYMid meet">

//         <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
//            fill="white" stroke="none">
//             <path d="M1195 2975 c-50 -27 -75 -56 -109 -130 -18 -38 -50 -107 -71 -153
// l-39 -84 -85 -50 -86 -51 -125 12 c-227 20 -277 14 -332 -45 -14 -16 -79 -121
// -144 -234 -100 -174 -118 -212 -122 -257 -7 -86 3 -112 103 -253 l95 -133 0
// -102 0 -102 -95 -133 c-100 -141 -110 -167 -103 -253 4 -45 22 -83 122 -257
// 65 -113 127 -216 139 -228 58 -63 118 -72 342 -51 l120 11 86 -51 86 -50 49
// -108 c80 -177 97 -206 137 -237 l39 -31 298 0 298 0 39 31 c40 33 59 65 137
// 237 l49 108 86 51 86 51 145 -14 c288 -26 276 -34 456 281 100 174 118 213
// 122 257 7 86 -3 112 -103 253 l-95 133 0 102 0 102 95 133 c100 141 110 167
// 103 254 -4 44 -20 80 -110 237 -130 225 -153 258 -203 286 -47 27 -99 29 -285
// 12 l-125 -12 -86 51 -86 51 -49 108 c-78 172 -97 204 -137 237 l-39 31 -286 3
// c-246 2 -291 0 -317 -13z m595 -312 c36 -78 75 -155 88 -172 25 -34 206 -145
// 265 -163 30 -9 74 -8 200 4 124 13 165 14 174 5 9 -10 174 -292 211 -363 10
// -18 -1 -38 -83 -155 -55 -79 -98 -151 -104 -176 -15 -58 -13 -270 3 -313 7
// -19 53 -93 104 -164 88 -123 91 -130 78 -155 -39 -73 -199 -347 -209 -357 -9
// -8 -56 -7 -175 4 -124 11 -172 12 -200 4 -58 -18 -239 -129 -264 -163 -13 -17
// -52 -94 -88 -172 l-66 -142 -224 0 -224 0 -71 152 c-38 84 -80 163 -93 176
// -12 13 -65 48 -117 78 -52 30 -95 56 -95 59 0 3 58 35 129 73 l129 67 83 -21
// c118 -29 320 -36 419 -15 191 40 360 172 452 353 53 104 80 188 103 317 20
// 117 19 164 -4 202 -23 38 -77 59 -128 51 -19 -4 -124 -53 -233 -111 -108 -58
// -202 -106 -209 -108 -6 -2 -34 38 -61 88 l-50 91 88 53 c133 81 299 197 321
// 226 46 59 31 144 -33 183 -54 33 -268 100 -360 112 -162 21 -327 -14 -448 -95
// -150 -99 -285 -308 -338 -522 l-13 -51 -130 -79 c-71 -43 -133 -80 -138 -82
// -5 -2 -9 52 -9 120 -1 73 -6 137 -14 158 -7 19 -53 93 -104 164 -88 123 -91
// 130 -78 155 37 70 199 347 209 358 9 9 50 8 174 -5 126 -12 170 -13 200 -4 48
// 14 231 122 260 153 12 13 45 76 73 139 29 63 61 132 71 153 l20 37 222 -2 221
// -3 66 -142z m-156 -663 l77 -23 -94 -62 c-51 -33 -148 -92 -215 -130 -67 -38
// -122 -73 -122 -76 0 -15 268 -503 278 -507 5 -1 112 52 237 119 124 67 228
// 119 230 117 10 -9 -38 -156 -73 -227 -43 -88 -130 -186 -199 -223 -90 -49
// -154 -61 -292 -55 -95 4 -147 11 -214 31 -48 14 -93 26 -100 26 -7 0 -157 -74
// -334 -165 -176 -91 -325 -165 -330 -165 -7 0 -213 350 -213 363 0 3 138 90
// 307 193 169 104 315 196 324 205 9 9 23 48 32 88 48 209 173 403 305 470 110
// 56 251 64 396 21z"/>
//         </g>
//     </svg>
// }

// export default MenuIcon