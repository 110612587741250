//import {createSelector} from "reselect";
/** selectors need to get state value
 * */

export const getUserSelector = state => state.userPage.user;

/**
 *
 * Use this code when selector has logic and contains another selector to avoid re-rendering
 *
export const getUserCourseNumber = createSelector(getUserSelector,
    (user) => {
        return user.courses.filter(c => c.complete === true).length;
    })
*/
