import React from "react";
import "./XpercentTipComponent.css";
import xpercenttipImage from "../../../../../src/images/xpercenttipImage.png";
function XpercentTipComponent() {
  const handleClick = () => {
    const newWindow = window.open(
      "https://xpercent.io/tip",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
    console.log("clicked");
  };
  const styles = {
    button: {
      display: "inline-block",
      padding: "0.5rem 1rem",
      backgroundColor: "#4c8be7",
      color: "#fff",
      border: "none",
      borderRadius: "0.25rem",
      cursor: "pointer",
      width: "137px",
      marginBottom: "10px",
      marginTop: "10px",
      textAlign: "center",
    },
  };
  return (
    <div>
      <div className="tip_home__container1">
        <div className="tip_home__inner">
          <div className="tip_home__content">
            <div className="tip_home__left" data-aos="fade-right">
              <h1>주식 투자 IQ 테스트</h1>
              <h2
                className="tip_white__text_desc"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면!{" "}
              </h2>{" "}
              <div
                className="primary-btn"
                style={styles.button}
                onClick={handleClick}
              >
                테스트하러가기
              </div>
            </div>

            <div
              style={{ display: "flex" }}
              className="tip_home__right1"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {" "}
              <img
                src="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/xpercenttipmainphoto.png"
                alt="favimage"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default XpercentTipComponent;
