import s from "./Admin.module.css";
import { boardAPI, appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  Input,
  Modal,
  Switch,
  Form,
  Radio,
  DatePicker,
  Select,
  InputNumber,
  Checkbox,
  Row,
  Col,
  message,
  Button,
  Alert,
} from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const MobileAnnouncementAdmin = (props) => {
  const [announcementList, setAnnouncementList] = React.useState([]);
  const [filteredAnnouncement, setFilteredAnnouncement] = React.useState([]);
  const [searchAnnouncement, setSearchAnnouncement] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [createAnnouncementModal, setCreateAnnouncementModal] = useState(false);
  const [editAnnouncementModal, setEditAnnouncementModal] = useState(false);
  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();

  // Hooks for form

  const [saveDuplicateLimitType, setSaveDuplicateLimitType] = useState("");

  const [editDefaultValues, setEditDefaultValues] = useState(null);

  const showModal = () => {
    setCreateAnnouncementModal(true);
  };

  const handleCancel = () => {
    setCreateAnnouncementModal(false);
  };

  const handleOk = async () => {
    createForm.submit();
  };

  const confirmDeleteHandle = async (messag = null, idx) => {
    if (window.confirm(messag)) {
      const res = await appAdminApi.appAdminDeleteAnnouncementView(idx);
      console.log("res", res);
      if (res == 200) {
        message.success("삭제가 되었습니다.");
        let result = await appAdminApi.appAdminAnnouncementListView();
        setAnnouncementList(result.data);
        setFilteredAnnouncement(result.data);
        setSearchAnnouncement(result.data);
      } else {
        message.success("문제가 생겼습니다.");
      }
    } else {
      message.success("취소했습니다.");
    }
  };

  const showEditModal = (id, title, content) => {
    // setEditBattleName(battleName);
    var valueDict = {
      id: id,
      title: title,
      content: content,
    };

    setEditDefaultValues(valueDict);
    editForm.setFieldsValue(valueDict);
    setEditAnnouncementModal(true);
  };

  const columns = [
    {
      name: "주제",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "내용",
      selector: (row) => row.content,
      sortable: true,
    },
    {
      name: "날짜",
      selector: (row) => row.create_date,
      sortable: true,
      wrap: true,
    },

    {
      cell: (row) => (
        <button
          onClick={() => {
            showEditModal(row.id, row.title, row.content);
          }}
          className={s.button}
        >
          수정
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <button
          onClick={() => {
            confirmDeleteHandle("삭제하시겠습니까?", row.id);
          }}
          className={s.deleteButton}
        >
          삭제
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(async () => {
    let result = await appAdminApi.appAdminAnnouncementListView();
    setAnnouncementList(result.data);
    setFilteredAnnouncement(result.data);
    setSearchAnnouncement(result.data);
  }, []);

  const search = (keyword) => {
    setInputValue(keyword);
    let data = filteredAnnouncement.filter((val) => {
      return val["title"].includes(keyword) || val["content"].includes(keyword);
    });
    setSearchAnnouncement(data);
  };

  const onFinish = async (values) => {
    const outputValues = {
      title: values["title"],
      content: values["content"],
      announce_type: 1,
    };

    let announcement_create_status =
      await appAdminApi.appAdminCreateAnnouncementView(outputValues);
    if (announcement_create_status == 201) {
      createForm.resetFields();
      let result = await appAdminApi.appAdminAnnouncementListView();
      setAnnouncementList(result.data);
      setFilteredAnnouncement(result.data);
      setSearchAnnouncement(result.data);
    }
    setCreateAnnouncementModal(false);
  };

  return (
    <>
      <div
        className={s.list_container}
        style={{
          position: "relative",
          height: props.isSummary ? 677 : "calc(100vh - 90px)",
        }}
      >
        <button
          className={s.battle_button}
          onClick={() => {
            showModal();
          }}
        >
          <PlusOutlined style={{ marginRight: 10 }} />
          공지사항 생성
        </button>
        {/* <div style={{float: 'right'}}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>대결 기간 미 종료</Typography>
                    <Switch defaultChecked onChange={(checked) => {switchFunction(checked)}} /> 
                    <Typography>전체보기</Typography>
                </Stack>
            </div> */}
        <div className={s.title}>공지사항 목록</div>
        <div className={s.sub_title}>
          <span style={{ color: "#2F3A8F" }}>
            공지사항: {announcementList.length}
          </span>
        </div>
        <Input
          style={{ marginTop: 10, marginBottom: 10 }}
          value={inputValue}
          onChange={(e) => {
            search(e.target.value);
          }}
          placeholder="공지사항 검색"
          prefix={<SearchOutlined />}
        />
        <DataTable
          columns={columns}
          data={searchAnnouncement}
          pagination="true"
          paginationPerPage={props.rows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            tableWrapper: {
              style: {
                display: "block",
              },
            },
          }}
        />
      </div>
      {/* Create Announcement Modal */}
      <Modal
        visible={createAnnouncementModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"추가"}
        cancelText={"닫기"}
        title={"공지사항 생성"}
        wrapClassName={"create_battle"}
        width={800}
      >
        <div style={{ padding: 30 }}>
          <Form form={createForm} layout={"vertical"} onFinish={onFinish}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="주제"
                  name="title"
                  rules={[{ required: true, message: "주제를 입력해 주세요." }]}
                >
                  <Input
                    size={"large"}
                    style={{ padding: 10, borderRadius: 10 }}
                  />
                </Form.Item>
                <Form.Item
                  label="내용"
                  name="content"
                  rules={[{ required: true, message: "내용을 입력해 주세요." }]}
                >
                  <Input.TextArea
                    size={"large"}
                    style={{ padding: 10, borderRadius: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {/* Create Battle Modal End */}

      {/* Edit Battle Modal */}
      <Modal
        visible={editAnnouncementModal}
        onOk={() => {
          editForm.submit();
        }}
        onCancel={() => {
          setEditAnnouncementModal(false);
        }}
        okText={"수정"}
        cancelText={"닫기"}
        title={"공지사항 수정"}
        wrapClassName={"create_battle"}
        width={800}
      >
        <div style={{ padding: 30 }}>
          <Form
            form={editForm}
            layout={"vertical"}
            initialValues={editDefaultValues}
            onFinish={async (values) => {
              const outputValues = {
                id: values["id"],
                title: values["title"],
                content: values["content"],
                type: 1,
              };

              let announcement_update_status =
                await appAdminApi.appAdminUpdateAnnouncementView(outputValues);
              if (announcement_update_status == 200) {
                message.success("수정 되었습니다.");
                let result = await appAdminApi.appAdminAnnouncementListView();
                setAnnouncementList(result.data);
                setFilteredAnnouncement(result.data);
                setSearchAnnouncement(result.data);
              } else {
                message.success("문제가 생겼습니다.");
              }
              setEditAnnouncementModal(false);
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="id"
                  style={{
                    display: "none",
                  }}
                >
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  label="주제"
                  name="title"
                  rules={[{ required: true, message: "주제를 입력해 주세요." }]}
                >
                  <Input
                    size={"large"}
                    style={{ padding: 10, borderRadius: 10 }}
                  />
                </Form.Item>

                <Form.Item
                  label="내용"
                  name="content"
                  rules={[{ required: true, message: "내용을 입력해 주세요." }]}
                >
                  <Input.TextArea
                    style={{ width: "100%", padding: 10, borderRadius: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {/* Edit Battle Modal End */}
    </>
  );
};

export default MobileAnnouncementAdmin;
