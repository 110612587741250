import s from "./Admin.module.css";
import { adminApi, appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Dropdown, Menu, Icon, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";

const MobileUserList = (props) => {
  const [dropdownLabel, setDropdownLabel] = useState("전체");
  const [users, setUsers] = React.useState([]);
  const [freeUsers, setFreeUsers] = useState([]);
  const [freeJoinBenefit, setFreeJoinBenefit] = useState([]);
  const [freeReferralBenefit, setFreeReferralBenefit] = useState([]);
  const [basic2WeekBenefit, setBasic2WeekBenefit] = useState([]);
  const [basicUsers, setBasicUsers] = useState([]);
  const [premiumUsers, setPremiumUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [value, setValue] = React.useState();

  const register_source_values = {
    0: "미완료",
    1: "완료",
  };

  const handleMenuClick = async (e) => {
    setDropdownLabel(e.domEvent.target.innerText);

    let filterResult = await appAdminApi.appMobileUserFilter(e.key);
    if (filterResult.status == 200) {
      setUsers(filterResult.data);
      setFilteredUsers(filterResult.data);
    } else {
      message.info("데이터를 가져오지 못했습니다.");
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">전체</Menu.Item>
      <Menu.Item key="2">Free</Menu.Item>
      <Menu.Item key="3">Free_join_benefit</Menu.Item>
      <Menu.Item key="4">Free_referral_benefit</Menu.Item>
      <Menu.Item key="5">Basic_2week_benefit</Menu.Item>
      <Menu.Item key="6">Basic</Menu.Item>
      <Menu.Item key="7">Premium</Menu.Item>
      <Menu.Item key="8">Admin</Menu.Item>
    </Menu>
  );
  const columns = [
    // {
    //     cell:(row) => <button className={s.button_cancel}>탈퇴</button>,
    //     ignoreRowClick: true,
    //     allowOverflow: true,
    //     button: true,
    // },
    {
      name: "이름",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "아이디",
      selector: (row) => row.username,
      sortable: true,
      wrap: true,
    },
    {
      name: "이메일",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: "전화번호",
      selector: (row) => row.phone_no,
      sortable: true,
    },
    {
      name: "추천횟수",
      selector: (row) => row.total_invite_num,
      sortable: true,
      wrap: true,
    },

    {
      name: "멤버십",
      selector: (row) => row.membership_type,
      sortable: true,
      wrap: true,
    },

    {
      name: "가입일시",
      selector: (row) => row.register_datetime,
      sortable: true,
      wrap: true,
    },

    {
      name: "Survey",
      selector: (row) => {
        return register_source_values[row.iscompletedsurvey];
      },
      sortable: true,
    },
  ];

  useEffect(async () => {
    let result = await appAdminApi.appUserListView();
    if (result.status == 200) {
      setUsers(result.data);

      setFilteredUsers(result.data);
      result.data?.map((u) => {
        if (u.membership_type === "free") {
          setFreeUsers((freeUsers) => [...freeUsers, u]);
        } else if (u.membership_type === "free_join_benefit") {
          setFreeJoinBenefit((freeJoinBenefit) => [...freeJoinBenefit, u]);
        } else if (u.membership_type === "free_referral_benefit") {
          setFreeReferralBenefit((freeReferralBenefit) => [
            ...freeReferralBenefit,
            u,
          ]);
        } else if (u.membership_type === "basic_2week_benefit") {
          setBasic2WeekBenefit((basic2WeekBenefit) => [
            ...basic2WeekBenefit,
            u,
          ]);
        } else if (u.membership_type === "basic") {
          setBasicUsers((basicUsers) => [...basicUsers, u]);
        } else if (u.membership_type === "premium") {
          setPremiumUsers((premiumUsers) => [...premiumUsers, u]);
        } else {
          setAdminUsers((adminUsers) => [...adminUsers, u]);
        }
      });
    } else {
      setUsers([]);

      setFilteredUsers([]);
    }
  }, []);

  const search = (keyword) => {
    let data = users.filter((val) => {
      return (
        val["username"].includes(keyword) ||
        val["name"].includes(keyword) ||
        val["total_invite_num"] == keyword ||
        val["membership_type"].includes(keyword) ||
        val["phone_no"] == keyword
      );
    });
    setFilteredUsers(data);
  };

  return (
    <>
      <div
        className={s.list_container}
        style={{ height: props.isSummary ? 677 : "calc(100vh - 90px)" }}
      >
        <div className={s.title}>회원 목록</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div className={s.sub_title}>회원수: {users.length}</div>
          <Dropdown.Button overlay={menu} icon={<DownOutlined />}>
            {dropdownLabel}
          </Dropdown.Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          <div className={s.sub_title}>Free: {freeUsers?.length}</div>
          <div className={s.sub_title}>
            Free_Join_Benefit: {freeJoinBenefit?.length}
          </div>
          <div className={s.sub_title}>
            Free_Referral_Benefit: {freeReferralBenefit?.length}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          <div className={s.sub_title}>Basic: {basicUsers?.length}</div>
          <div className={s.sub_title}>
            Basic_2Week_Benefit: {basic2WeekBenefit?.length}
          </div>
        </div>{" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "50%",
          }}
        >
          <div className={s.sub_title}>Premium: {premiumUsers?.length}</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "50%",
          }}
        >
          <div className={s.sub_title}>Admin: {adminUsers?.length}</div>
        </div>
        <Input
          onChange={(e) => {
            search(e.target.value);
          }}
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="회원 검색 (아이디, 이름, 멤버십, 전화번호)"
          prefix={<SearchOutlined />}
        />
        <DataTable
          columns={columns}
          data={filteredUsers}
          pagination="true"
          paginationPerPage={props.rows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            tableWrapper: {
              style: {
                display: "block",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default MobileUserList;
