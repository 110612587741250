import s from "./Admin.module.css";
import { adminApi, appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

const MobileXpercentTip = (props) => {
  const [users, setUsers] = React.useState([]);
  const [freeUsers, setFreeUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);

  const kakaoValue = {
    0: "미완료",
    1: "완료",
  };
  const linkValue = {
    0: "미완료",
    1: "완료",
  };
  const iosValue = {
    0: "미완료",
    1: "완료",
  };
  const androidValue = {
    0: "미완료",
    1: "완료",
  };
  const columns = [
    {
      name: "일시",
      selector: (row) => row.register_at,
      sortable: true,
      wrap: true,
    },
    {
      name: "IP 주소",
      selector: (row) => row.user_ip,
      sortable: true,
      wrap: true,
    },
    {
      name: "사용자 수익률",
      selector: (row) => `${row.user_score}%`,
      sortable: true,
    },
    {
      name: "섀넌 수익률",
      selector: (row) => `${row.shannon_score}%`,
      sortable: true,
    },
    {
      name: "승리",
      selector: (row) => row.winner,
      sortable: true,
    },
    {
      name: "기기 타입",
      selector: (row) => row.phone_type,
      sortable: true,
    },
    {
      name: "브라우저 타입",
      selector: (row) => row.browser_type,
      sortable: true,
      wrap: true,
    },

    {
      name: "카카오 공유",
      selector: (row) => {
        return kakaoValue[row.kakao_shared];
      },
      sortable: true,
    },
    {
      name: "링크 복사",
      selector: (row) => {
        return linkValue[row.copy_shared];
      },
      sortable: true,
    },
    {
      name: "앱스토어",
      selector: (row) => {
        return iosValue[row.ios_btn];
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "플레이스토어",
      selector: (row) => {
        return androidValue[row.android_btn];
      },
      sortable: true,
      wrap: true,
    },
  ];

  useEffect(async () => {
    let result = await appAdminApi.appXpercentTipUserList();
    if (result.status === 200) {
      console.log("result.data in appXpercentTipUserList", result.data);
      setUsers(result.data);
      setFilteredUsers(result.data);
    } else {
      setUsers([]);

      setFilteredUsers([]);
    }
  }, []);

  //   const search = (keyword) => {
  //     let data = users.filter((val) => {
  //       return (
  //         val["username"].includes(keyword) ||
  //         val["name"].includes(keyword) ||
  //         val["inviter_username"] == keyword ||
  //         val["inviter_name"].includes(keyword)
  //       );
  //     });
  //     setFilteredUsers(data);
  //   };

  return (
    <>
      <div
        className={s.list_container}
        style={{ height: props.isSummary ? 567 : "calc(100vh - 90px)" }}
      >
        <div className={s.title}>주식 투자 IQ 테스트</div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          {" "}
          <div className={s.sub_title}>방문자 수: {users.length}</div>
        </div>

        {/* <Input
          onChange={(e) => {
            search(e.target.value);
          }}
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="회원 검색 (추천자 아이디, 이름)"
          prefix={<SearchOutlined />}
        /> */}
        <DataTable
          columns={columns}
          data={filteredUsers}
          pagination="true"
          paginationPerPage={props.rows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            tableWrapper: {
              style: {
                display: "block",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default MobileXpercentTip;
