import './styles.css'
import Header from "../../components/Header/Signed/Header";
import AutoTop5 from '../../components/AutoTop5';
import SignalSector from "../../components/SignalSector/SignalSector";
import ChooseDate from "../../components/chooseDate/chooseDate";
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, message, DatePicker } from 'antd';
import {useSelector} from "react-redux";
import {getUserSelector} from "../../redux/user-selector";
import { infoAPI, autoApi } from '../../api/api';
import { Moving, CurrencyExchange, SwapVert  } from '@mui/icons-material';


const SignalAutoWeek = ( ) => {
    const user = useSelector(getUserSelector)
    const [date, setDate] = useState(moment().subtract(1, 'week').weekday(5));
    const [curDate, setCurDate] = useState(moment().weekday(1));
    const [model, setModel] = useState(5)
    const [sector, setSector] = useState(user.survey_result == "attack" ? "attack" : user.survey_result == "mixed" ? "mixed" : "safe");
    const [predKospi, setPredKospi] = useState(null);

    const [startDate, setStartDate] = useState(moment().weekday(1))
    const [endDate, setEndDate] = useState(moment().weekday(1))

    const [buyDate, setBuyDate] = useState("");
    const [sellDate, setSellDate] = useState("");

    useEffect(() => {
      const getWeather = async() => {
        const response = await infoAPI.getWeather();
        setPredKospi(response.data['pred_kospi']);
        const response2 = await autoApi.getRatb(sector);
        // setPredKospi(response.data['pred_kospi']);
        setBuyDate(response2[0]['recom_date']);
        setSellDate(response2[0]['sell_date']);
      }
      getWeather();
    }, [])
    

    useEffect(() => {
        let todayDate;
        if(moment().isSameOrBefore(moment().weekday(6).endOf('day').subtract(4, 'hours')))
        {
          todayDate = moment().weekday(1);
        }
        else
        {
          todayDate = moment().add(1, 'days').weekday(1);
        }
        while (
            todayDate.isSame("2020-04-30", "day") ||
            todayDate.isSame("2020-05-01", "day") ||
            todayDate.isSame("2020-05-05", "day") ||
            todayDate.isSame("2020-08-17", "day") ||
            todayDate.isSame("2020-09-30", "day") ||
            todayDate.isSame("2020-10-01", "day") ||
            todayDate.isSame("2020-10-02", "day") ||
            todayDate.isSame("2020-10-09", "day") ||
            todayDate.isSame("2020-12-25", "day") ||
            todayDate.isSame("2020-12-31", "day") ||
            todayDate.isSame("2021-01-01", "day") ||
            todayDate.isSame("2021-02-11", "day") ||
            todayDate.isSame("2021-02-12", "day") ||
            todayDate.isSame("2021-03-01", "day") ||
            todayDate.isSame("2021-05-05", "day") ||
            todayDate.isSame("2021-05-19", "day") ||
            todayDate.isSame("2021-08-16", "day") ||
            todayDate.isSame("2021-09-20", "day") ||
            todayDate.isSame("2021-09-21", "day") ||
            todayDate.isSame("2021-09-22", "day") ||
            todayDate.isSame("2021-10-04", "day") ||
            todayDate.isSame("2021-10-11", "day") ||
            todayDate.isSame("2021-12-31", "day") ||
            todayDate.isSame("2022-01-31", "day") ||
            todayDate.isSame("2022-02-01", "day") ||
            todayDate.isSame("2022-02-02", "day") ||
            todayDate.isSame("2022-03-01", "day") ||
            todayDate.isSame("2022-03-09", "day") ||
            todayDate.isSame("2022-05-05", "day") ||
            todayDate.isSame("2022-06-01", "day") ||
            todayDate.isSame("2022-06-06", "day") ||
            todayDate.isSame("2022-08-15", "day") ||
            todayDate.isSame("2022-09-09", "day") ||
            todayDate.isSame("2022-09-12", "day") ||
            todayDate.isSame("2022-10-03", "day") ||
            todayDate.isSame("2022-10-10", "day") ||
            todayDate.isSame("2022-12-30", "day")
          ) {
            todayDate.add(1, 'days');
          }
          setCurDate(todayDate)
          setStartDate(todayDate);
          setEndDate(todayDate);
          setDate(todayDate.clone().subtract(1, 'week').weekday(5));
          
    }, [])
    

    function disabledDate(current) {
        // let startDate = moment('2020-04-26');
        // let todayDate = moment();
        
        if( current > moment().weekday(7).endOf("day") ||
            current < moment("2020-04-26") || current < moment(user.date_joined).isoWeekday(1).startOf('day'))
            {
                return true;
            }
        else if (current.weekday() == 1) {
          if (
            current.isSame("2020-04-30", "day") ||
            current.isSame("2020-05-01", "day") ||
            current.isSame("2020-05-05", "day") ||
            current.isSame("2020-08-17", "day") ||
            current.isSame("2020-09-30", "day") ||
            current.isSame("2020-10-01", "day") ||
            current.isSame("2020-10-02", "day") ||
            current.isSame("2020-10-09", "day") ||
            current.isSame("2020-12-25", "day") ||
            current.isSame("2020-12-31", "day") ||
            current.isSame("2021-01-01", "day") ||
            current.isSame("2021-02-11", "day") ||
            current.isSame("2021-02-12", "day") ||
            current.isSame("2021-03-01", "day") ||
            current.isSame("2021-05-05", "day") ||
            current.isSame("2021-05-19", "day") ||
            current.isSame("2021-08-16", "day") ||
            current.isSame("2021-09-20", "day") ||
            current.isSame("2021-09-21", "day") ||
            current.isSame("2021-09-22", "day") ||
            current.isSame("2021-10-04", "day") ||
            current.isSame("2021-10-11", "day") ||
            current.isSame("2021-12-31", "day") ||
            current.isSame("2022-01-31", "day") ||
            current.isSame("2022-02-01", "day") ||
            current.isSame("2022-02-02", "day") ||
            current.isSame("2022-03-01", "day") ||
            current.isSame("2022-03-09", "day") ||
            current.isSame("2022-05-05", "day") ||
            current.isSame("2022-06-01", "day") ||
            current.isSame("2022-06-06", "day") ||
            current.isSame("2022-08-15", "day") ||
            current.isSame("2022-09-09", "day") ||
            current.isSame("2022-09-12", "day") ||
            current.isSame("2022-10-03", "day") ||
            current.isSame("2022-10-10", "day") ||
            current.isSame("2022-12-30", "day")
          ) {
            return true;
          }
        } else if (
          current.weekday() != 1
        ) {
            if (
                current.isSame("2020-04-30", "day") ||
                current.isSame("2020-05-01", "day") ||
                current.isSame("2020-05-05", "day") ||
                current.isSame("2020-08-17", "day") ||
                current.isSame("2020-09-30", "day") ||
                current.isSame("2020-10-01", "day") ||
                current.isSame("2020-10-02", "day") ||
                current.isSame("2020-10-09", "day") ||
                current.isSame("2020-12-25", "day") ||
                current.isSame("2020-12-31", "day") ||
                current.isSame("2021-01-01", "day") ||
                current.isSame("2021-02-11", "day") ||
                current.isSame("2021-02-12", "day") ||
                current.isSame("2021-03-01", "day") ||
                current.isSame("2021-05-05", "day") ||
                current.isSame("2021-05-19", "day") ||
                current.isSame("2021-08-16", "day") ||
                current.isSame("2021-09-20", "day") ||
                current.isSame("2021-09-21", "day") ||
                current.isSame("2021-09-22", "day") ||
                current.isSame("2021-10-04", "day") ||
                current.isSame("2021-10-11", "day") ||
                current.isSame("2021-12-31", "day") ||
                current.isSame("2022-01-31", "day") ||
                current.isSame("2022-02-01", "day") ||
                current.isSame("2022-02-02", "day") ||
                current.isSame("2022-03-01", "day") ||
                current.isSame("2022-03-09", "day") ||
                current.isSame("2022-05-05", "day") ||
                current.isSame("2022-06-01", "day") ||
                current.isSame("2022-06-06", "day") ||
                current.isSame("2022-08-15", "day") ||
                current.isSame("2022-09-09", "day") ||
                current.isSame("2022-09-12", "day") ||
                current.isSame("2022-10-03", "day") ||
                current.isSame("2022-10-10", "day") ||
                current.isSame("2022-12-30", "day")
              ) {
                return true;
              }
          let weekNum = current.weekday() - 1;
          if (weekNum <= 0) {
            return true;
          } else {
            let isDisabled = true;
            for (let i = 0; i < weekNum; i++) {
              let tempCur = current.clone();
              if (
                tempCur.weekday(1).add(i, "days").isSame("2020-04-30", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-05-01", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-05-05", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-08-17", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-09-30", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-10-01", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-10-02", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-10-09", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-12-25", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2020-12-31", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-01-01", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-02-11", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-02-12", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-03-01", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-05-05", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-05-19", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-08-16", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-09-20", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-09-21", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-09-22", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-10-04", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-10-11", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2021-12-31", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-01-31", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-02-01", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-02-02", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-03-01", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-03-09", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-05-05", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-06-01", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-06-06", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-08-15", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-09-09", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-09-12", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-10-03", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-10-10", "day") ||
                tempCur.weekday(1).add(i, "days").isSame("2022-12-30", "day")
              ) 
              {
                isDisabled = false;
              }
              else
              {
                return true;
              }
            
      
            }
            if (isDisabled) {
              return true;
            } 
            else
            {
                return false;
            }
          }
        }
      }

    return <>
        <div className="container">
            {/* <Row justify={'space-around'}>
                <Col span={15}>
                    <div  className={`smallBox shadow`}>
                        <DatePicker
                            value={curDate}
                            dateFormat="yyyy-MM-dd"
                            onChange={(date) => {if(date) {
                                let lastFriday = moment(date).subtract(1, 'week').weekday(5);
                                setDate(lastFriday);
                                setCurDate(date)
                                setStartDate(date);
                                setEndDate(date);
                            }}} 
                            disabledDate={disabledDate}
                            style={{width: '90%'}}
                        />
                    </div>
                </Col>
            </Row> */}
            <SignalSector changeSector={setSector} sector={sector} />
            <Row justify={'space-between'}>
                <h4 style={{margin: 12}}>TOP 5</h4>
            </Row>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 20
              }}
            >
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#F1F6F9",
                  borderRadius: 100,
                  marginRight: 10
                }}
              >
                <span style={{fontWeight: 'bold'}}>매수 날짜:</span> {buyDate}
              </div>
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#F1F6F9",
                  borderRadius: 100
                }}
              >
                <span style={{fontWeight: 'bold'}}>매도 날짜:</span> {sellDate}
              </div>
            </div>
            <div
              className='vectorContainer'
            >
              <div
                className='vectorBackground'
                style={{
                  backgroundColor: '#2e37a8'
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 20
                  }}
                >
                  <Moving 
                    className='vectorIcon'
                  />
                </div>
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <div
                    className='vectorPerc'
                  >
                    {
                      sector == "attack" ?
                        predKospi == 0 ?
                          '100%'
                        :
                        predKospi == 1 ?
                          '0%'
                        :
                          '0%'
                      :
                      sector == "mixed" ?
                        predKospi == 0 ?
                          '50%'
                        :
                        predKospi == 1 ?
                          '0%'
                        :
                          '0%'
                      :
                        predKospi == 0 ?
                            '50%'
                          :
                          predKospi == 1 ?
                            '0%'
                          :
                            '0%'
                    }
                  </div>
                </div>
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <div
                    className='vectorTitle'
                  >
                    KOSPI 200
                  </div>
                </div>
              </div>
              <div
                className='vectorBackground'
                style={{
                  backgroundColor: '#3084ec'
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 20
                  }}
                >
                  <SwapVert 
                    className='vectorIcon'
                  />
                </div>
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <div
                    className='vectorPerc'
                  >
                    {
                      sector == "attack" ?
                        predKospi == 0 ?
                          '0%'
                        :
                        predKospi == 1 ?
                          '0%'
                        :
                          '100%'
                      :
                      sector == "mixed" ?
                        predKospi == 0 ?
                          '0%'
                        :
                        predKospi == 1 ?
                          '0%'
                        :
                          '50%'
                      :
                        predKospi == 0 ?
                            '0%'
                          :
                          predKospi == 1 ?
                            '0%'
                          :
                            '0%'
                    }
                  </div>
                </div>
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <div
                    className='vectorTitle'
                  >
                    KOSPI 200 Inverse
                  </div>
                </div>
              </div>
              <div
                className='vectorBackground'
                style={{
                  backgroundColor: '#795fff'
                }}
              >
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 20
                  }}
                >
                  <CurrencyExchange 
                    className='vectorIcon'
                  />
                </div>
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <div
                    className='vectorPerc'
                  >
                    {
                      sector == "attack" ?
                        predKospi == 0 ?
                          '0%'
                        :
                        predKospi == 1 ?
                          '100%'
                        :
                          '0%'
                      :
                      sector == "mixed" ?
                        predKospi == 0 ?
                          '50%'
                        :
                        predKospi == 1 ?
                          '100%'
                        :
                          '50%'
                      :
                        predKospi == 0 ?
                            '50%'
                          :
                          predKospi == 1 ?
                            '100%'
                          :
                            '100%'
                    }
                  </div>
                </div>
                <div
                  style={{
                    textAlign: 'center'
                  }}
                >
                  <div
                    className='vectorTitle'
                  >
                    CASH
                  </div>
                </div>
              </div>
            </div>
            {/* {date && <AutoTop5 date={date.format('YYYY-MM-DD')} model={model} sector={sector} changeStartDate={setStartDate} changeEndDate={setEndDate}/>} */}

            {/* <div className="notification">
                
                * 본 페이지의 수익률은 {moment(startDate).format('YYYY년 MM월 DD일')} 시초가에 매수하여 {
                    moment() > moment(endDate + ' ' + '15:30')
                        ? moment(endDate).format('YYYY년 MM월 DD일') + ' 종가' : '현재가'}
                    에 매도한 것을 가정합니다.

            </div>
            <div className="notification">

                * 종목 가격은 5분 단위로 제공되고 있습니다.

            </div> */}
        </div>
    </>
}

export default SignalAutoWeek