import React, {useState, useEffect} from "react";
import s from './SignalSector.module.css'

import {Col, message, Row} from "antd";
// import BankIcon from "../Icons/Bank";
// import BitCoinIcon from "../Icons/BitCoin";
// import PlaneIcon from "../Icons/PlaneIcon";
// import BioIcon from "../Icons/BioIcon";
// import ProductIcon from "../Icons/ProductsIcon";
// import GasIcon from "../Icons/GasIcon";
// import GameIcon from "../Icons/GameIcon";
// import ServiceIcon from "../Icons/ServiceIcon";
import useCheckPermission from "../../features/CheckPermission";
import {FEATURE_PERMISSIONS} from "../../constants/permissions";
import { useSelector } from "react-redux";
import { getUserSelector } from "../../redux/user-selector";
import { autoApi } from "../../api/api";

const SignalSector = (props) => {
    const user = useSelector(getUserSelector)

    const [selected, setSelected] = useState('attack')
    const {couldShow} = useCheckPermission()
    const [surveyResult, setSurveyResult] = useState("")
    const showTopSectors = couldShow(FEATURE_PERMISSIONS.TOP_SECTORS)
    const showSectors = couldShow(FEATURE_PERMISSIONS.SECTORS)

    const onClick = (value, permission) => {
        if (!permission)
            return message.error('현재 등급에서 이용 불가능합니다.', 1)

        setSelected(value)
        props.changeSector(value)
    }

    const getSurvey = async() => {
        let survey_result = await autoApi.getSurveyResult(user.username);
        setSurveyResult(survey_result)
    }

    useEffect(() => {
        getSurvey();
    }, [])

    return <Row justify={'center'}>
        <Col xs={24} sm={24} md={24} lg={18} xl={12}>
            {
                user.is_push ?
                    <h4 style={{margin: 12}}>투자자 성향</h4>
                :
                    <h4 style={{margin: 12}}>그룹</h4>
            }

        {user.is_push ?
        <>
        <Row justify={'start'}>
            <div style={{
                fontSize: 40,
                fontWeight: 'bold',
                marginLeft: 12
            }}>
                {
                    user.survey_result == "attack" ?
                    "공격형"
                    :
                    user.survey_result == "mixed" ?
                    "혼합형"
                    :
                    "안정형"
                }
            </div>
            
        </Row>
        <Row justify={'space-around'} style={{marginTop: 20}}>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                    <button className={`btn shadow ${props.sector === 'safe' ? s.selectedButton : s.button}`} onClick={() => onClick('safe', true)}>
                        <p className={`text-center ${s.text}`} style={{color: props.sector === 'safe' && 'white'}}>
                            안정형
                        </p>
                    </button>
                </Col>
                {
                    (user.survey_result == "mixed" || user.survey_result == "attack") &&
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                        <button className={`btn shadow ${showTopSectors ? (props.sector === 'mixed' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('mixed', true)}>
                            <p className={`text-center ${s.text}`} style={{color: (props.sector === 'mixed' || !showTopSectors) && 'white'}}>
                                혼합형
                            </p>
                        </button>
                    </Col>

                }
                {
                    (user.survey_result == "attack") &&
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                        <button className={`btn shadow ${showTopSectors ? (props.sector === 'attack' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('attack', true)}>
                            <p className={`text-center ${s.text}`} style={{color: (props.sector === 'attack' || !showTopSectors) && 'white'}}>
                                공격형
                            </p>
                        </button>
                    </Col>

                }
            </Row>
        </>
        :
        <Row justify={'space-around'}>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${selected === '전체' ? s.selectedButton : s.button}`} onClick={() => onClick('전체', true)}>
                    <p className={`text-center ${s.text}`} style={{color: selected === '전체' && 'white'}}>
                        전체
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showTopSectors ? (selected === 'KOSPI200' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('KOSPI200', couldShow(FEATURE_PERMISSIONS.TOP_SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: (selected === 'KOSPI200' || !showTopSectors) && 'white'}}>
                        코스피<br/>200
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showTopSectors ? (selected === 'KOSDAQ150' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('KOSDAQ150', couldShow(FEATURE_PERMISSIONS.TOP_SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: (selected === 'KOSDAQ150' || !showTopSectors) && 'white'}}>
                        코스닥<br/>150
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === 'IT' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('IT', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === 'IT' ? 'white' : null) : '#9b9b9b'}}>
                        IT
                    </p>
                </button>
            </Col>
            
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '반도체' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('반도체', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '반도체' ? 'white' : null) : '#9b9b9b'}}>
                        반도체
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '금융' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('금융', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '금융' ? 'white' : null) : '#9b9b9b'}}>
                        금융
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '자원및에너지' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('자원및에너지', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '자원및에너지' ? 'white' : null) : '#9b9b9b'}}>
                        자원<br/>에너지
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '유통' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('유통', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '유통' ? 'white' : null) : '#9b9b9b'}}>
                        유통
                    </p>
                </button>
            </Col>
            
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '바이오및의료' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('바이오및의료', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '바이오및의료' ? 'white' : null) : '#9b9b9b'}}>
                        바이오<br/>의료
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '문화생활및서비스' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('문화생활및서비스', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '문화생활및서비스' ? 'white' : null) : '#9b9b9b'}}>
                        문화생활<br/>서비스
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '건설및기간산업' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('건설및기간산업', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '건설및기간산업' ? 'white' : null) : '#9b9b9b'}}>
                        건설<br/>기간산업
                    </p>
                </button>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                <button className={`btn shadow ${showSectors ? (selected === '중공업' ? s.selectedButton : s.button) : s.disabledButton}`} onClick={() => onClick('중공업', couldShow(FEATURE_PERMISSIONS.SECTORS))}>
                    <p className={`text-center ${s.text}`} style={{color: showSectors ? (selected === '중공업' ? 'white' : null) : '#9b9b9b'}}>
                        중공업
                    </p>
                </button>
            </Col>
        </Row>}

        </Col>
    </Row>
}

export default SignalSector