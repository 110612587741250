import {NavLink, useHistory} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {Form, Input, Button} from 'antd';
import s from './LoginAuto.module.css'
import HeaderUnsigned from "../../components/Header/UnSigned/HeaderUnsigned";
import {useDispatch} from "react-redux";
import {loginAuto} from "../../redux/user-reducer";
import {WEB_APP_ROUTES} from "../../constants/routes";
import { useSelector } from "react-redux";
import { getUserSelector } from "../../redux/user-selector";

const LoginAuto = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch()

    const onFinish = async (values) => {
        console.log('values')
        console.log(values)
        let log = await dispatch(loginAuto(values))

        console.log('log')
        console.log(log)

        log && history.push('/signal-auto-week')

        form.resetFields()
    };

    return <>
        <HeaderUnsigned/>
        <div className={s.layout}>
            <div className={s.login}  data-aos={"flip-left"} data-aos-duration={"1000"}>
                <Form
                    className={s.form}
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                >
                    <h3 className={`text-center pb-1`}>
                        DeepTrade
                    </h3>
                    <Form.Item
                        className={s.item}
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '아이디를 입력하세요!',
                            },
                        ]}
                    >
                        <Input className={s.input} placeholder={'아이디'}/>
                    </Form.Item>

                    <Form.Item
                        className={s.item}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '비밀번호를 입력하세요!',
                            },
                        ]}
                    >
                        <Input.Password className={s.input} placeholder={'비밀번호'}/>
                    </Form.Item>
                    <Form.Item
                        className={`${s.item} justify-content-center`}
                    >
                        <button  type="submit" className={"btn btn-dark col-12"} id={'btnLogin'}>
                            로그인
                        </button>
                    </Form.Item>

                    <div className="signup-div">
                        로그인이 안 되나요? <NavLink to={WEB_APP_ROUTES.FIND_LOGIN_DATA} className="signup-button">아이디/비밀번호 찾기</NavLink>
                    </div>

                    
                </Form>

            </div>
        </div>
    </>
}
export default LoginAuto