import s from "./Admin.module.css";
import { adminApi, appAdminApi } from "../../api/api";
import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { Input, Dropdown, Menu, Icon, message } from "antd";
import { Bar, Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DaywiseLineChart = ({ data }) => {
  const daywise_chart_data = {
    labels: data.dates,
    datasets: [
      {
        label: "3일 User Counts",
        data: data.user_counts,

        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        pointLabelFontColor: "rgba(0, 0, 0, 0)",
        fill: false,
      },
      {
        label: "7일 User Counts",
        data: data.sevenday_user_counts,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        pointLabelFontColor: "rgba(0, 0, 0, 0)",
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "지난 3개월간 일별 방문자 수",
        fontSize: 20,
      },
    },
  };

  return (
    <div className="chart-container">
      <Line data={daywise_chart_data} options={options} />
    </div>
  );
};

const LineChart = ({ data }) => {
  const chart_data = {
    labels: data.dates,
    datasets: [
      {
        label: "1일 User Counts",
        data: data.user_counts,

        borderColor: "rgb(255, 159, 64)",
        backgroundColor: "rgba(255, 159, 64, 0.5)",
        borderWidth: 2,
        fill: false,
        pointLabelFontColor: "rgba(0, 0, 0, 0)",
      },
      {
        label: "3일 User Counts",
        data: data.unique_three_day_user_counts,

        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderWidth: 2,
        fill: false,
        pointLabelFontColor: "rgba(0, 0, 0, 0)",
      },
      {
        label: "7일 User Counts",
        data: data.unique_sevenday_user_counts,

        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderWidth: 2,
        fill: false,
        pointLabelFontColor: "rgba(0, 0, 0, 0)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "지난 3개월간 고유 방문자 수",
        fontSize: 20,
      },
    },
  };

  return (
    <div className="chart-container">
      <Line data={chart_data} options={options} />
    </div>
  );
};

const MobileAppAnalytics = (props) => {
  const chartRef = useRef();
  const [users, setUsers] = React.useState([]);

  const [analyticsData, setAnalyticsData] = useState(null);

  const [uniqueUsersData, setUniqueUsersData] = useState(null);
  const [threeDayWiseData, ThreeDayWiseData] = useState(null);

  // Get 3months data of unique logged in user
  const getUniqueUsersData = async () => {
    let res = await appAdminApi.fetchUniqueUsers("unique_user");
    if (res.status == 200) {
      console.log("res.data", res.data);
      setUniqueUsersData(res.data);
    } else {
      message.info("데이터를 가져오지 못했습니다.");
    }
    let threedaywise_res = await appAdminApi.fetchUniqueUsers(
      "three_seven_daywise"
    );
    if (res.status == 200) {
      console.log("res.data", threedaywise_res.data);
      ThreeDayWiseData(threedaywise_res.data);
    } else {
      message.info("데이터를 가져오지 못했습니다.");
    }
  };

  const getAppData = async () => {
    let res = await appAdminApi.getaapanalytics();
    if (res.status == 200) {
      console.log("res.data", res.data);
      prepareBargraphData(res.data);
    } else {
      message.info("데이터를 가져오지 못했습니다.");
    }
  };

  const prepareBargraphData = async (analyticsData) => {
    const labels = Object.keys(analyticsData);
    const dataValues = Object.values(analyticsData);

    const data = {
      labels: labels,
      datasets: [
        {
          label: "기능별 클릭 조회수",
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75,192,192,0.4)",
          hoverBorderColor: "rgba(75,192,192,1)",
          data: dataValues,
        },
      ],
    };
    setAnalyticsData(data);
  };
  console.log("analyticsData", analyticsData);
  useEffect(() => {
    getAppData();
    getUniqueUsersData();
  }, []);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "기능별 클릭 조회수",
      },
    },
  };

  return (
    <>
      <div
        className={s.list_container}
        style={{
          height: props.isSummary ? 677 : "300vh",
          overflow: props.isSummary ? "scroll" : "visible",
        }}
      >
        <div className={s.title}>App Analytics </div>
        {analyticsData && <Bar data={analyticsData} options={options} />}
        <div
          style={{
            marginTop: 12,
            borderTop: "1px solid #cecece",
            marginTop: 18,
            padding: "20px 0px",
          }}
        >
          <div className={s.title}>로그인 기록 분석</div>
          {uniqueUsersData && <LineChart data={uniqueUsersData} />}
        </div>

        <div
          style={{
            marginTop: 12,
            borderTop: "1px solid #cecece",
            marginTop: 18,
            padding: "20px 0px",
          }}
        >
          {threeDayWiseData && <DaywiseLineChart data={threeDayWiseData} />}
        </div>
      </div>
    </>
  );
};

export default MobileAppAnalytics;
