import { WEB_APP_ROUTES } from "../../constants/routes";
import authRoles from "../../constants/authRoles";

import { VerifyAuth } from "./VerifyAuth";
import Main from "../../pages/Main";
import Login from "../../pages/Login";
import LoginAuto from "../../pages/LoginAuto";
import SignUp from "../../pages/SignUp";
import Signal from "../../pages/Signal";
import Home from "../../pages/Home";
import About from "../../pages/About";
import Profile from "../../pages/Profile";
import TermsOfUse from "../../pages/TermsOfUse";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import ForgetUserInfo from "../../pages/ForgotUserInfo";
import ErrorPage from "../../pages/ErrorPage";
import PaymentPage from "../../pages/Payment";
import FAQPage from "../../pages/FAQPage";
import Inquiry from "../../pages/Inquiry";
import Tutorial from "../../pages/Tutorial";
import PaymentForm from "../../pages/PaymentForm";
import Admin from "../../pages/Admin";
import PushAdmin from "../../pages/Admin/PushAdmin";
import Refund from "../../pages/Refund/Refund";
import SignUpAuto from "../../pages/SignUpAuto/SignUpAuto";
import SignalAutoDay from "../../pages/SignalAutoDay/SignalAutoDay";
import SignalAutoWeek from "../../pages/SignalAutoWeek/SignalAutoWeek";
import PaymentSuccess from "../../pages/PaymentSuccess";
import PaymentFail from "../../pages/PaymentFail";
import AutoTransaction from "../../pages/AutoTransaction";
import Simulation from "../../pages/Simulation";
import Announcement from "../../pages/Announcement";
import WritePost from "../../pages/WritePost";
import UserList from "../../pages/Admin/AdminStackScreen/UserListStackScreen";
import RefundList from "../../pages/Admin/AdminStackScreen/RefundListStackScreen";
import InquiryList from "../../pages/Admin/AdminStackScreen/InquiryListStackScreen";
import PaymentList from "../../pages/Admin/AdminStackScreen/PaymentListStackScreen";
import CouponList from "../../pages/Admin/AdminStackScreen/CouponListStackScreen";
import Auto from "../../pages/Admin/AdminStackScreen/AutoStackScreen";
import BattleList from "../../pages/Admin/AdminStackScreen/BattleStackScreen";
import AnnouncementAdminStackScreen from "../../pages/Admin/AdminStackScreen/AnnouncementAdminStackScreen";
import AppAdmin from "../../pages/AppAdmin";
import MobileUserListStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobileUserListStackScreen";

import MobileAnnouncementAdminStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobileAnnouncementAdminStackScreen";
import MobileInquiryListStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobileInquiryListStackScreen";
import XpercentTips from "../../pages/AppScreens/XpercentTips/XpercentTips";
import MobilePaymentListStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobilePaymentListStackScreen";
import MobileInviteAdminStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobileInviteAdminStackScreen";
import MobileXpercentTipAdminStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobileXpercentTipAdminStackScreen";
import MobileLoginRecordStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobileLoginRecordStackScreen";
import MobileAppAnalyticsStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/MobileAppAnalyticsStackScreen";
import EngineMonitoringAdminStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/EngineMonitoringAdminStackScreen";
import DtEnterpriseAdminStackScreen from "../../pages/AppAdmin/AppAdminStackScreen/DtEnterpriseAdminStackScreen";
/**
 *       path: string              'url'
 *       key: string (unique)      could be used to create site map , so you name the path
 *       exact: boolean            set to true when there are embed pages ,  check the react-router-dom for details
 *       component: React Node     the page you would to render!
 *
 *       if you would like to protect any route use VerifyAuth
 *
 *
 * */

const ROUTES = [
  {
    path: WEB_APP_ROUTES.MAIN,
    key: "main",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={Main}
        authRoles={authRoles.onlyGuest}
        props={props}
      />
    ),
    // component: Main // For Test
  },
  {
    path: WEB_APP_ROUTES.LOGIN,
    key: "login_admin_app",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={Login}
        authRoles={authRoles.onlyGuest}
        props={props}
      />
    ),
    // component: Login // For Test
  },
  {
    path: WEB_APP_ROUTES.LOGINAUTO,
    key: "login",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={LoginAuto}
        authRoles={authRoles.onlyGuest}
        props={props}
      />
    ),
    // component: Login // For Test
  },
  // {
  //     path: WEB_APP_ROUTES.SIGNUP,
  //     key: "sign-up",
  //     exact: true,
  //     component: props => <VerifyAuth component={SignUp} authRoles={authRoles.onlyGuest} props={props}/>
  //     // component: SignUp // For Test
  // },
  // {
  //     path: WEB_APP_ROUTES.SIGNUPAUTO,
  //     key: "sign-up-auto",
  //     exact: true,
  //     component: props => <VerifyAuth component={SignUpAuto} authRoles={authRoles.onlyGuest} props={props}/>
  //     // component: SignUp // For Test
  // },
  // {
  //     path: WEB_APP_ROUTES.HOME,
  //     key: "home",
  //     exact: true,
  //     component: props => <VerifyAuth component={Home} authRoles={authRoles.free} props={props}/>
  //     // component: Home // For Test/
  // },
  // {
  //     path: WEB_APP_ROUTES.SIGNAL,
  //     key: "signal",
  //     exact: true,
  //     component: props => <VerifyAuth component={Signal} authRoles={authRoles.free} props={props}/>
  //     // component: Signal // For Test
  // },
  // {
  //     path: WEB_APP_ROUTES.SIGNALAUTODAY,
  //     key: "signal-auto-day",
  //     exact: true,
  //     component: props => <VerifyAuth component={SignalAutoDay} authRoles={authRoles.free} props={props}/>
  //     // component: Signal // For Test
  // },
  {
    path: WEB_APP_ROUTES.SIGNALAUTOWEEK,
    key: "signal-auto-week",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={SignalAutoWeek}
        authRoles={authRoles.free}
        props={props}
      />
    ),
    // component: Signal // For Test
  },
  // {
  //     path: WEB_APP_ROUTES.PROFILE,
  //     key: "profile",
  //     exact: true,
  //     component: props => <VerifyAuth component={Profile} authRoles={authRoles.free} props={props}/>
  //     // component: Profile // For Test
  // },
  // {
  //     path: WEB_APP_ROUTES.ABOUT,
  //     key: "about",
  //     exact: true,
  //     // component: props => <VerifyAuth component={About} authRoles={authRoles.free} props={props}/>
  //     component: About // For Test
  // },
  // {
  //     path: WEB_APP_ROUTES.TERMS_OF_USE,
  //     key: "terms-of-use",
  //     exact: true,
  //     component: TermsOfUse
  // },
  // {
  //     path: WEB_APP_ROUTES.PRIVACY_POLICY,
  //     key: "privacy-policy",
  //     exact: true,
  //     component: PrivacyPolicy
  // },
  // {
  //     path: WEB_APP_ROUTES.FIND_LOGIN_DATA,
  //     key: "privacy-policy",
  //     exact: true,
  //     component: ForgetUserInfo
  // },
  // {
  //     path: WEB_APP_ROUTES.PAYMENT,
  //     key: "payment",
  //     exact: true,
  //     component: props => <VerifyAuth component={PaymentPage} authRoles={authRoles.free} props={props}/>
  //     // component:  PaymentPage
  // },
  // {
  //     path: WEB_APP_ROUTES.FAQ,
  //     key: "faq",
  //     exact: true,
  //     component:  FAQPage
  // },
  // {
  //     path: WEB_APP_ROUTES.INQUIRY,
  //     key: "inquiry",
  //     exact: true,
  //     component: props => <VerifyAuth component={Inquiry} authRoles={authRoles.free} props={props}/>
  //     // component:  Inquiry
  // },
  // {
  //     path: WEB_APP_ROUTES.TUTORIAL,
  //     key: "survey",
  //     exact: true,
  //     // component: props => <VerifyAuth component={About} authRoles={authRoles.free} props={props}/>
  //     component:  Tutorial
  // },
  // {
  //     path: WEB_APP_ROUTES.PAYMENTFORM,
  //     key: "form",
  //     exact: true,
  //     component:  PaymentForm
  // },
  // {
  //     path: WEB_APP_ROUTES.ADMIN,
  //     key: "admin",
  //     exact: true,
  //     component: props => <VerifyAuth component={Admin} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.USERLIST,
  //     key: "userlist",
  //     exact: true,
  //     component: props => <VerifyAuth component={UserList} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.REFUNDLIST,
  //     key: "refundlist",
  //     exact: true,
  //     component: props => <VerifyAuth component={RefundList} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.INQUIRYLIST,
  //     key: "inquirylist",
  //     exact: true,
  //     component: props => <VerifyAuth component={InquiryList} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.PAYMENTLIST,
  //     key: "paymentlist",
  //     exact: true,
  //     component: props => <VerifyAuth component={PaymentList} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.COUPONLIST,
  //     key: "couponlist",
  //     exact: true,
  //     component: props => <VerifyAuth component={CouponList} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.AUTOADMIN,
  //     key: "autoadmin",
  //     exact: true,
  //     component: props => <VerifyAuth component={Auto} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.BATTLE,
  //     key: "battle",
  //     exact: true,
  //     component: props => <VerifyAuth component={BattleList} authRoles={authRoles.admin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.PUSHADMIN,
  //     key: "push_admin",
  //     exact: true,
  //     component: props => <VerifyAuth component={PushAdmin} authRoles={authRoles.pushadmin} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.PAYMENTSUCCESS,
  //     key: "payment_success",
  //     exact: true,
  //     component: props => <VerifyAuth component={PaymentSuccess} authRoles={authRoles.free} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.PAYMENTFAIL,
  //     key: "payment_fail",
  //     exact: true,
  //     component: props => <VerifyAuth component={PaymentFail} authRoles={authRoles.free} props={props}/>
  //     // component:  Admin
  // },
  // {
  //     path: WEB_APP_ROUTES.AUTOTRANSACTION,
  //     key: "form",
  //     exact: true,
  //     component:  AutoTransaction
  // },
  {
    path: WEB_APP_ROUTES.SIMULATION,
    key: "simulation",
    exact: true,
    component: Simulation,
  },
  {
    path: WEB_APP_ROUTES.ANNOUNCEMENT,
    key: "announcement",
    exact: true,
    component: Announcement,
  },
  {
    path: WEB_APP_ROUTES.WRITEPOST,
    key: "write",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={WritePost}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.ANNOUNCEMENTADMIN,
    key: "announcementadmin",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={AnnouncementAdminStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.APPADMIN,
    key: "appadmin",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={AppAdmin}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
    // component:  Admin
  },
  {
    path: WEB_APP_ROUTES.APPUSERLIST,
    key: "mobileuserlist",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobileUserListStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.APPINQUIRYLIST,
    key: "mobileinquirylist",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobileInquiryListStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.APPANNOUNCEMENTADMIN,
    key: "mobileannouncement",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobileAnnouncementAdminStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.TIP,
    key: "tip",
    exact: true,
    component: XpercentTips,
  },
  {
    path: WEB_APP_ROUTES.APPPAYMENTADMIN,
    key: "mobilepayment",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobilePaymentListStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.APPXPERCENTTIPADMIN,
    key: "xpercenttipuserlist",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobileXpercentTipAdminStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.APPINVITERADMIN,
    key: "inviterlist",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobileInviteAdminStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.APPLOGINRECORD,
    key: "loginrecord",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobileLoginRecordStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.APPANALYTICS,
    key: "mobileanalytics",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={MobileAppAnalyticsStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.ENGINEMONITORING,
    key: "enginemonitoring",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={EngineMonitoringAdminStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  {
    path: WEB_APP_ROUTES.DTENTERPRISE,
    key: "dtenterpriseadmin",
    exact: true,
    component: (props) => (
      <VerifyAuth
        component={DtEnterpriseAdminStackScreen}
        authRoles={authRoles.admin}
        props={props}
      />
    ),
  },
  /***
 * use this structure to embed pages
    {
        path: "/app",
        key: "APP",
        component: props => {
            if (!localStorage.getItem("user")) {
                alert("You need to log in to access app routes");
                return <Redirect to={"/"} />;
            }
            return <RenderRoutes {...props} />;
        },
        routes: [
            {
                path: "/app",
                key: "APP_ROOT",
                exact: true,
                component: () => <h1>App Index</h1>,
            },
            {
                path: "/app/page",
                key: "APP_PAGE",
                exact: true,
                component: () => <h1>App Page</h1>,
            },
        ],
    },
 ***/
];

export default ROUTES;
