import * as axios from "axios";
import moment from "moment";

const csrftoken = getCookie("csrftoken");

const instance = axios.create({
  baseURL: "/api/",
  headers: {
    "X-CSRFToken":
      getCookie("csrftoken") /** Need for making request to Django*/,
  },
  validateStatus: function (status) {
    return (
      (status >= 200 && status < 300) || 401
    ); /** Will except responses without error*/
  },
});

instance.interceptors.request.use(
  function (config) {
    config.headers["Content-Type"] = "application/json";
    config.headers["X-CSRFToken"] = getCookie("csrftoken");
    return config;
  },
  function (error) {
    // console.log(error);
    return Promise.reject(error);
  }
);

export const infoAPI = {
  timeView() {
    return instance.get("info/time");
  },
  getWeather() {
    return instance.get("mobile/get_weather");
  },
};

export const overviewAPI = {
  get_overview_data(model) {
    // console.log(model)
    return instance.get(`overview`).then((res) => {
      // console.log(res)
      // console.log(res.data)
      return res.data;
    });
  },
};

export const signalAPI = {
  // get_signal_data(date, model, sector) {
  //     console.log(date)
  //     console.log(model)
  //     console.log(sector)
  //     return instance.get(`auth/stockinfokr/`, {params: {start_date:date, model:model, sector:sector}})
  //     .then(res => {
  //         console.log(res)
  //         console.log(res.data)
  //         return res.data
  //     });
  // },
  get_signal_data(date, model, sector) {
    console.log(date);
    if (date === undefined) {
      date = moment().format("YYYY-MM-DD");
    }

    return instance.get(`auth/stockinfokr/`, {
      params: { start_date: date, model: model, sector: sector },
    });
  },

  get_indices_data(date, model) {
    return instance.get(`auth/indices`, {
      params: { start_date: date, model: model },
    });
  },

  get_stock_detail_info(start_date, model, stock_id) {
    let body = JSON.stringify({ start_date, model, stock_id });

    return instance
      .post(`auth/stockdetailinfokr/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  get_signal_profit(start_date, model, sector) {
    let body = JSON.stringify({ start_date, model, sector });

    return instance
      .post(`return_view/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
};

export const paymentAPI = {
  payView(pay_type, card_number, expiry, birth, pwd_2digit) {
    let body = JSON.stringify({
      pay_type,
      card_number,
      expiry,
      birth,
      pwd_2digit,
    });

    return instance
      .post(`payment/pay/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  paymentListView() {
    return instance.get(`auth/paymentlist/`);
  },

  repayView() {
    return instance.get("payment/repay/");
  },

  cancelSchedulePayView() {
    return instance.get("payment/cancelschedulepay/");
  },

  refundPayView() {
    return instance.get("payment/refund/");
  },
  calculateRefund() {
    return instance.get("payment/calculaterefund");
  },
  payVBankView(pay_type, bank_code, month, coupon_code) {
    let body = JSON.stringify({ pay_type, bank_code, month, coupon_code });

    return instance
      .post(`payment/payvbank/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  repayVBankView(
    refund_holder,
    refund_bank,
    refund_account,
    refund_tel,
    pay_type,
    bank_code
  ) {
    let body = JSON.stringify({
      refund_holder,
      refund_bank,
      refund_account,
      refund_tel,
      pay_type,
      bank_code,
    });

    return instance
      .post(`payment/repayvbank/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },

  refundVBankView(
    refund_holder,
    refund_bank,
    refund_account,
    refund_tel,
    refund_type,
    refund_amount
  ) {
    let body = JSON.stringify({
      refund_holder,
      refund_bank,
      refund_account,
      refund_tel,
      refund_type: refund_type,
      refund_amount: refund_amount,
    });

    return instance
      .post(`payment/refundvbank/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  readyPayTrans(pay_type, month, coupon_code) {
    let body = JSON.stringify({ pay_type, month, coupon_code });

    return instance
      .post(`payment/readypaytrans/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  preparePayTrans(object) {
    let body = JSON.stringify(object);

    return instance
      .post(`payment/preparepaytrans/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  failedPayTrans(Moid, coupon_code) {
    let body = JSON.stringify({ Moid, coupon_code });

    return instance
      .post(`payment/failedpaytrans/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  payTrans(object) {
    let body = JSON.stringify(object);
    console.log(body);

    return instance
      .post(`payment/paytrans/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let code = res.code;
        if (code == 0) {
          console.log("success");
        }
      });
  },
  cancelTrans(refund_type, refund_amount) {
    let body = JSON.stringify({
      refund_type: refund_type,
      refund_amount: refund_amount,
    });

    return instance
      .post(`payment/canceltrans/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      });
  },
  readyRepayTrans(pay_type) {
    let body = JSON.stringify({ pay_type });

    return instance
      .post(`payment/readyrepaytrans/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  repayTrans(object) {
    let body = JSON.stringify(object);

    return instance
      .post(`payment/repaytrans/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let code = res.code;
        if (code == 0) {
          console.log("success");
        }
      });
  },
  getCoupon(coupon_code, role, subType) {
    let body = JSON.stringify({
      coupon_code: coupon_code,
      use_membership: role,
      use_subscription: subType,
    });
    return instance
      .post("auth/get_coupon/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
};

export const boardAPI = {
  createAnnouncementView({ title, content, announce_type }) {
    let body = JSON.stringify({ title, content, announce_type });

    return instance
      .post(`createAnnouncement/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  announcementListView() {
    return instance.get(`listAnnouncement`, { params: { type: 0 } });
  },
  adminAnnouncementListView() {
    return instance.get(`listAnnouncement`, { params: { type: 2 } });
  },
  updateAnnouncementView({ id, title, content, type }) {
    let body = JSON.stringify({ id, title, content, type });
    return instance
      .post("updateAnnouncement/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  deleteAnnouncementView(id) {
    let body = JSON.stringify({ id });
    return instance
      .post("deleteAnnouncement/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
};

export const surveyAPI = {
  surveyView(
    job,
    q1_ageclass,
    q2_income_status,
    q3_investment_ratio,
    q4_investment_exp,
    q5_investment_period,
    q6_investment_purpose,
    q7_risk_cap,
    q8_finance_know,
    q9_derivative_exp,
    q10_finance_weak,
    q11_investment_money,
    q12_distribute_num,
    q13_register_source
  ) {
    let body = JSON.stringify({
      job,
      q1_ageclass,
      q2_income_status,
      q3_investment_ratio,
      q4_investment_exp,
      q5_investment_period,
      q6_investment_purpose,
      q7_risk_cap,
      q8_finance_know,
      q9_derivative_exp,
      q10_finance_weak,
      q11_investment_money,
      q12_distribute_num,
      q13_register_source,
    });
    return instance
      .post(`auth/login/survey/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
};

export const inquiryAPI = {
  sendInquiryView(title, inquiry, inquiryTypeFlag) {
    let body = JSON.stringify({ title, inquiry, inquiryTypeFlag });
    console.log("sendInquiryView", body);
    return instance
      .post(`auth/inquiry/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  inquiryListView() {
    return instance.get("auth/inquirylist/");
  },
};

export const findInfoAPI = {
  findIDWithEmailView(name, email) {
    let body = JSON.stringify({ name, email });
    return instance
      .post(`auth/find/id/email/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  findIDWithPhonView(name, phone_no) {
    let body = JSON.stringify({ name, phone_no });
    return instance
      .post(`auth/find/id/phone/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  findPwWithEmailView(username, email) {
    let body = JSON.stringify({ username, email });
    return instance
      .post(`auth/find/password/email/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  findPwWithPhoneView(username, phone_no) {
    let body = JSON.stringify({ username, phone_no });
    return instance
      .post(`auth/find/password/phone/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
};

export const profileAPI = {
  profileView() {
    return instance.get("auth/profile");
  },

  passwordChangeView(password, new_password) {
    let body = JSON.stringify({ password, new_password });
    return instance
      .post(`auth/profile/password/`, body, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      })
      .then((res) => {
        return res.status;
      });
  },
};

export const authAPI = {
  login(userData) {
    let body = JSON.stringify(userData);

    return instance
      .post(`auth/login/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  loginAuto(userData) {
    let body = JSON.stringify(userData);
    return instance
      .post(`auth/auto_login/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  login_get(userData) {
    let body = JSON.stringify(userData);
    return instance
      .post(`auth/login/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  logout() {
    return instance.get("user/logout");
  },
  isValidUserName(username) {
    let body = JSON.stringify({ username: username });
    return instance
      .post(`auth/register/doesusernameexist/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  getCheckCode(phone_no) {
    let body = JSON.stringify({ phone_no: phone_no });
    return instance
      .post(`auth/verifycode/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  register(data) {
    let body = JSON.stringify(data);

    const isPush = "auto_code" in data;
    const uri = isPush ? `auth/registerautotest/` : `auth/register/`;

    return instance
      .post(uri, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  unregister(password, IsAgreed) {
    let body = JSON.stringify({ password, IsAgreed });
    return instance
      .post(`auth/unregister/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
};
export const appXpercentTip = {
  addParticipate() {
    return instance.post("addpartitip/");
  },

  addUserToXperentTip(formdata) {
    let body = JSON.stringify({ formdata });

    return instance.post("addusertoxpercenttip/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  fetchParticipate() {
    return instance.get("getallpartitip/");
  },

  fetchXpercentTipParticipate() {
    return instance.get("getallxpercentpartitip/");
  },

  sharedLinkXperentTip(formdata) {
    let body = JSON.stringify({ formdata });

    return instance.post("sharedlinkxperenttip/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  pressAppStoreBtnXperentTip(formdata) {
    let body = JSON.stringify({ formdata });

    return instance.post("pressappstorebtnxperenttip/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  //old
  shareLinkToUser(formdata) {
    let body = JSON.stringify({ formdata });

    return instance.post("sharelinktouser/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export const appAdminApi = {
  // This api is for DT Enterprise
  fetchDtUserInfoAdmin() {
    return instance.get("getadmindtuserinfo/");
  },
  updateDTUserInfoAdmin(res_check, username, model_type) {
    let body = JSON.stringify({
      checkbox_value: res_check,
      model_type: model_type,
      username,
    });

    return instance.post("updateadmindtuserinfo/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  appUserLoginRecordListView() {
    return instance.get("appuserloginrecordlist/");
  },
  getEngineMonitoringData(filterkey) {
    let body = JSON.stringify({ model_type: filterkey });

    return instance.post("appenginemonitoringlist/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // return instance.get("appenginemonitoringlist/");

  // using this for login record
  appadminfilterloginrecords() {
    return instance.get("appadminfilterloginrecords/");
  },

  getaapanalytics() {
    return instance.get("appadminanalytics/");
  },

  //3months unique user's data api
  fetchUniqueUsers(filterkey) {
    let body = JSON.stringify({ type: filterkey });

    return instance.post("appadminloginrecordanalysis/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  appUserLoginPastRecords() {
    return instance.get("appadminfilterlastloginuser/");
  },
  appUserListView() {
    return instance.get("appuserlist/");
  },
  appPaidUserListView() {
    return instance.get("apppaiduserlist/");
  },

  appInquiryListView() {
    return instance.get("appinquirylist/");
  },
  //new api
  appXpercentTipUserList() {
    return instance.get("appxpercenttipuserlist/");
  },
  appMobileUserFilter(filterkey) {
    let body = JSON.stringify({ filterkey });

    return instance.post("mobileuserfilter/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  appMobileLoginRecordUserFilter(filterkey) {
    let body = JSON.stringify({ filterkey });

    return instance.post("mobileloginrecorduserfilter/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  appInvitersUserList() {
    return instance.get("mobileinvitersuserlist/");
  },

  appMobilePaymentUserFilter(filterkey) {
    let body = JSON.stringify({ filterkey });

    return instance.post("mobilepaymentuserfilter/", body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  appupdateInquiry(name, inquiry, answer, id, user_id, inquiryTypeFlag) {
    let body = JSON.stringify({
      title: name,
      inquiry: inquiry,
      answer: answer,
      id: id,
      user_id: user_id,
      inquiry_type_flag: inquiryTypeFlag,
    });
    return instance
      .post("appupdateinquiry/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },

  //board api announcement
  appAdminAnnouncementListView() {
    return instance.get(`appadminlistAnnouncement/`);
  },

  appAdminUpdateAnnouncementView({ id, title, content, type }) {
    let body = JSON.stringify({ id, title, content, type });
    return instance
      .post("appadminupdateAnnouncement/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  appAdminDeleteAnnouncementView(id) {
    let body = JSON.stringify({ id });
    return instance
      .post("appadmindeleteAnnouncement/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },

  appAdminCreateAnnouncementView({ title, content, announce_type }) {
    let body = JSON.stringify({ title, content, announce_type });

    return instance
      .post(`appadmincreateAnnouncement/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
};

export const adminApi = {
  userListView() {
    return instance.get("auth/userlist/");
  },
  refundListView() {
    return instance.get("auth/refundlist/");
  },
  inquiryListView() {
    return instance.get("auth/inquirylist/");
  },
  autoCodeListView() {
    return instance.get("auth/autocodelist/");
  },
  paymentListView() {
    return instance.get("auth/adminpaymentlist/");
  },
  autoUserListView() {
    return instance.get("auto/autouserlist/");
  },
  couponListView() {
    return instance.get("auth/coupon_list");
  },
  autoCode() {
    return instance.get("auth/autocode/");
  },
  generateCouponCode() {
    return instance.get("auth/generate_coupon_code");
  },
  battleListView() {
    return instance.get("auth/battlelist");
  },

  registerAuto(data) {
    let body = JSON.stringify(data);
    return instance
      .post("auto/register_auto/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  unregisterAuto(data) {
    let body = JSON.stringify(data);
    return instance
      .post("auto/unregister/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  updateAutoDate(data) {
    let body = JSON.stringify(data);
    return instance
      .post("auto/update_date/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  updateAutoModel(data) {
    let body = JSON.stringify(data);
    return instance
      .post("auto/update_model/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  updateAutoSector(data) {
    let body = JSON.stringify(data);
    return instance
      .post("auto/update_sector/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  userSpecificView(user_id) {
    let body = JSON.stringify({ user_id: user_id });
    return instance
      .post("auth/userspecific/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      });
  },
  updateRefund(merchant_uid, refunded) {
    let body = JSON.stringify({
      merchant_uid: merchant_uid,
      refunded: refunded,
    });
    return instance
      .post("auth/updaterefund/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  updateInquiry(name, inquiry, answer, id, user_id, inquiryTypeFlag) {
    let body = JSON.stringify({
      title: name,
      inquiry: inquiry,
      answer: answer,
      id: id,
      user_id: user_id,
      inquiry_type_flag: inquiryTypeFlag,
    });
    return instance
      .post("auth/updateinquiry/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  createCoupon(output) {
    let body = JSON.stringify(output);
    return instance
      .post("auth/create_coupon/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  updateCoupon(output) {
    let body = JSON.stringify(output);
    return instance
      .post("auth/update_coupon/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  updateAutoWeights(output) {
    let body = JSON.stringify(output);
    return instance
      .post("auto/update/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      });
  },
  createBattle(data) {
    let body = JSON.stringify(data);
    return instance
      .post("auth/createbattle/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
  updateBattle(data) {
    let body = JSON.stringify(data);
    return instance
      .post("auth/updatebattle/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.status;
      });
  },
};

export const autoApi = {
  autoSignal(date) {
    if (date) {
      return instance.get("auto/stocklist/?date=" + date);
    } else {
      return instance.get("auto/stocklist");
    }
  },
  getSurveyResult(username) {
    let body = JSON.stringify({ username: username });
    return instance
      .post(`auto/get_survey_result/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
  getRatb(type) {
    let body = JSON.stringify({ type: type });
    console.log();
    return instance
      .post(`ratb/get_rebalancing_info/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res.data;
      });
  },
};

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].replace(/^\s\s*/, "").replace(/\s\s*$/, "");
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
