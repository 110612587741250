/**
 * Authorization Roles
 */
 const authRoles = {
    admin: ['ADMIN'],
    pro: ['PRO','ADMIN'],
    lite: ['LITE', 'PRO', 'LITE-FREE','ADMIN'],
    free: ['FREE', 'LITE', 'LITE-FREE', 'PRO','ADMIN'],
    onlyGuest: [],
    pushadmin: ['PUSH_ADMIN']
};

export default authRoles;