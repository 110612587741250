import { useMediaQuery } from 'react-responsive'

const useWidth = () => {
    /**
     *    You could add/delete any properties up to your willing
     *
     *    now we use only  isDesktop and isTabletOrMobile
     *
     * */
    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return {
        isDesktop,
        isBigScreen,
        isTabletOrMobile,
        isPortrait,
        isRetina
    };
};

export default useWidth;
