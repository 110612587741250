import {useState, useEffect} from "react";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {WEB_APP_ROUTES} from "../../../constants/routes";
import useWidth from "../../../features/useWidth/useWidth";
import './styles.css'
import {useDispatch} from "react-redux";
import {logout} from "../../../redux/user-reducer";
import MenuIcon from "../../Icons/MenuIcon";
import { useSelector } from "react-redux";
import { getUserSelector } from "../../../redux/user-selector";
import { message } from "antd";

const Header = ( ) => {
    const user = useSelector(getUserSelector)

    const location = useLocation()
    const {isTabletOrMobile} = useWidth()
    const history = useHistory()
    const dispatch = useDispatch()
    
    const handleClick = ()=> {
        dispatch(logout())
        history.push(WEB_APP_ROUTES.MAIN)
    }

    const [showMenu, setShowMenu] = useState(false);

    const dropdownClick = () => setShowMenu(!showMenu)
    const closeDropdown = () => setShowMenu(false)

    // useEffect(() => {
    //     function handleHeaderClass () {
    //         const stickyMenu = document.querySelector(".header-head");
    //         const headerPadding = document.querySelector('.header');
    //         if (window.scrollY > 10) {
    //             headerPadding.classList.add('padder')
    //             stickyMenu.classList.add("fixed");
    //         } else {
    //             headerPadding.classList.remove('padder')
    //             stickyMenu.classList.remove("fixed");
    //         }
    //     }
    //     window.addEventListener("scroll", handleHeaderClass , false);
    //     return window.removeEventListener('click', handleHeaderClass, false);
    // });

    return <>{
        ! isTabletOrMobile &&
            <nav className={user.is_push ? "navbar navbar-expand navbar-dark navbar-custom" : "navbar navbar-expand-lg navbar-expand-md navbar-dark navbar-custom"}>
                <div className="container">
                    <div className={user.is_push ?"collapse navbar-collapse d-flex flex-row-reverse justify-content-center" : "collapse navbar-collapse d-flex flex-row-reverse"} id="navbarsExampleDefault">
                    {user.role == "PUSH_ADMIN" ? 
                        <ul className="navbar-nav ml-auto ">
                            <li className="nav-item">
                                <NavLink className="nav-link" to={WEB_APP_ROUTES.PUSHADMIN}>관리자</NavLink>
                            </li>
                            <li className="nav-item">
                                <button className={'btn btn-outline-light'}
                                onClick={handleClick}
                                >
                                    로그아웃
                                </button>
                            </li>
                        </ul>
                        :
                        // user.is_push ?  // 푸시 유저
                        //     <ul className="navbar-nav ml-auto ">
                        //         <li className="nav-item">
                        //             <NavLink className="nav-link" exact to={WEB_APP_ROUTES.SIGNALAUTOWEEK}>SIGNAL</NavLink>
                        //         </li>
                        //         {/* <li className="nav-item">
                        //             {user.model_option ?  // 1일 모델 사용 여부
                        //                 <NavLink className="nav-link" exact to={WEB_APP_ROUTES.SIGNALAUTODAY}>SIGNAL(DAY)</NavLink> :
                        //                 <NavLink className="nav-link" exact to={WEB_APP_ROUTES} onClick={() => {
                        //                     // const hide = message.error('현재 등급에서 이용 불가능합니다.', 0.5)
                        //                     message.open({
                        //                         type: 'error',
                        //                         content: '현재 등급에서 이용 불가능합니다.',
                        //                         duration: 0.5,
                        //                         onClick: () => {
                        //                             message.destroy()
                        //                         }
                        //                     })
                        //                 }}>SIGNAL(DAY)</NavLink>
                        //             }
                        //         </li> */}
                        //         {/* <li className="nav-item">
                        //             <NavLink className="nav-link" to={WEB_APP_ROUTES.HOME}>예측성과</NavLink>
                        //         </li> */}
                        //         <li className="nav-item">
                        //             <NavLink className="nav-link" exact to={WEB_APP_ROUTES.ABOUT}>소개</NavLink>
                        //         </li>
                        //     </ul> :
                            <ul className="navbar-nav ml-auto ">
                                {/* {user.role == 'ADMIN' &&
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={WEB_APP_ROUTES.ADMIN}>웹 관리자</NavLink>
                                </li>
                                } */}
                                  {user.role == 'ADMIN' &&
                                <li className="nav-item">
                                    <NavLink className="nav-link app_link" to={WEB_APP_ROUTES.APPADMIN}>앱 관리자</NavLink>
                                </li>
                                }
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link" to={WEB_APP_ROUTES.HOME}>HOME</NavLink>
                                </li> */}
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link" exact to={WEB_APP_ROUTES.SIGNAL}>SIGNAL</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to={WEB_APP_ROUTES.PROFILE}>프로필</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to={WEB_APP_ROUTES.PAYMENT}>결제하기</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to={WEB_APP_ROUTES.INQUIRY}>문의하기</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to={WEB_APP_ROUTES.FAQ}>FAQ</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to={WEB_APP_ROUTES.ABOUT}>소개</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to={WEB_APP_ROUTES.ANNOUNCEMENT}>공지사항</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <button className={'btn btn-outline-light'}
                                    onClick={handleClick}
                                    >
                                        로그아웃
                                    </button>
                                </li>
                            </ul>
                        }

                    </div>
                </div>
            </nav>
    }
        <div className={`header ${!isTabletOrMobile && 'comp'}`}>
            <div className={`header-head row mx-0`}>
                <div className="col-6 header-title ">
                    <span id="screen-title">
                        {/* {location.pathname.includes(WEB_APP_ROUTES.HOME) && 'Home'}
                        {location.pathname.includes(WEB_APP_ROUTES.SIGNAL) && 'Signal'}
                        {location.pathname.includes(WEB_APP_ROUTES.PROFILE) && '프로필'}
                        {location.pathname.includes(WEB_APP_ROUTES.PAYMENT) && '결제하기'}
                        {location.pathname.includes(WEB_APP_ROUTES.FAQ) && 'FAQ'}
                        {location.pathname.includes(WEB_APP_ROUTES.INQUIRY) && '문의하기'}
                        {location.pathname.includes(WEB_APP_ROUTES.ABOUT) && '소개'}
                        {location.pathname.includes(WEB_APP_ROUTES.SIMULATION) && '시뮬레이터'}
                        {location.pathname.includes(WEB_APP_ROUTES.ANNOUNCEMENT) && '공지사항'} */}
                        {location.pathname.includes(WEB_APP_ROUTES.APPADMIN) && '앱 관리자'}
                    </span>
                    <div className='header-dropdown-button' onClick={dropdownClick}>
                        <MenuIcon/>
                    </div>
                    <ul className={showMenu ? "nav-menu active" : "nav-menu"}>
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" to={WEB_APP_ROUTES.HOME}>HOME</NavLink>
                        </li> */}
                           {user.role == 'ADMIN' &&
                                <li className="nav-item">
                                    <NavLink className="nav-link app_link" to={WEB_APP_ROUTES.APPADMIN}>앱 관리자</NavLink>
                                </li>
                                }
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" exact to={WEB_APP_ROUTES.SIGNAL}>SIGNAL</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to={WEB_APP_ROUTES.PROFILE}>프로필</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to={WEB_APP_ROUTES.PAYMENT}>결제하기</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to={WEB_APP_ROUTES.INQUIRY}>문의하기</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to={WEB_APP_ROUTES.FAQ}>FAQ</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to={WEB_APP_ROUTES.ABOUT}>소개</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to={WEB_APP_ROUTES.ANNOUNCEMENT}>공지사항</NavLink>
                        </li> */}
                        <li className="nav-item">
                            <button className={'btn btn-outline-light '}
                            onClick={handleClick}
                            >
                                로그아웃
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
}

export default Header