import useWidth from "../../../features/useWidth/useWidth";
import {useHistory} from "react-router-dom";
import {WEB_APP_ROUTES} from "../../../constants/routes";
import s from "./HeaderUnsigned.module.css"

const HeaderUnsigned = ( ) => {
    const styles = {
        boxShadow: '0 0 7px #2f2f2f',
        justifyContent: 'center',
        height: '60px'
    }
    const history = useHistory()
    const {isTabletOrMobile} = useWidth()
    const handleLogin = ( ) => {
        history.push(WEB_APP_ROUTES.LOGIN)
    }
    const handleSignUp = ( ) => {
       history.push(WEB_APP_ROUTES.SIGNUP)
    }
    const navigateToMain = ( ) => {
        history.push(WEB_APP_ROUTES.MAIN)
    }

    const HeaderPC = () => ( <nav className={`navbar navbar-light bg-light row justify-content-around gx-0`}
                                   style={styles}>

        <div className={`col-3 ${s.col3}`} >
            <img src="/static/icons/logo_ver2.png" width="30" height="30" style={{marginTop: '-15px', marginRight: '10px',cursor: 'pointer'}} alt=""  onClick={navigateToMain}/>
            <span style={{fontSize: '30px', fontWeight: 'bold',cursor: 'pointer'}} onClick={navigateToMain}>XPERCENT</span>
        </div>
        <div className={`col-4 row justify-content-around ${s.col4}`}>
            <button className={`btn btn-outline-dark col-5 ${s.small_screen_text}`} style={{borderRadius: '50px'}}
            onClick={handleLogin}
            >
                로그인
            </button>
            <button className={`btn btn-dark col-5 ${s.small_screen_text}`} style={{borderRadius: '50px'}}
            onClick={handleSignUp}
            >
                무료 회원가입
            </button>
        </div>

    </nav>)

    const HeaderMobile = () =>   (
        <nav className={`navbar navbar-light bg-light`}
             style={styles}>
                <img src="/static/icons/logo_ver2.png" width="30" height="30" alt=""  onClick={navigateToMain}/>
        </nav>
    )

    return isTabletOrMobile ? <HeaderMobile/> : <HeaderPC/>
}

export default HeaderUnsigned