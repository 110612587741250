import {authAPI} from '../api/api'
import {message} from 'antd';

/** ACTION TYPES */
const SET_USER          = 'SET_USER'
const SET_AUTO_USER     = 'SET_AUTO_USER'
const SET_DEFAULT       = 'SET_DEFAULT'
const TOGGLE_FETCHING   = 'TOGGLE_FETCHING'
const SET_PROGRESS      = 'SET_PROGRESS'


let initialState = {
    user: {
        name: '홍길동',
        username: 'hong',
        email: 'hong@example.com',
        // role: 'LITE', // use it for test
        role: '', //actual user role
        membership_type: '',
        invite_code: 'hong123400',
        possible_invite_num: 10,
        xcredit: 10,
        date_joined: '2021-12-01T16:27:58.661198',
        date_next_pay: '2021-12-31T16:27:58.661198',
        is_surveydone: true,
        is_push: false,
        pay_method: null,
        survey_result: 'attack'
        // weekday_option: [1,1,1,1,1],
        // day_option: 0,
        // sector_option: [0,0]
    },
    fetching: true,
}

const userReducer = (state=initialState, action)=>{
    console.log('action.user')
    console.log(action.user)
    switch (action.type) {
        case SET_USER: {
            return{
                ...state,
                user:{
                    ...state.user,
                    name: action.user.name,
                    username: action.user.username,
                    birthdate: action.user.birthdate,
                    // gender: action.user.gender,
                    email: action.user.email,
                    lastLoginDate: action.user.lastLoginDate,
                    membership_type: action.user.membership_type,
                    role: action.user.role,
                    invite_code: action.user.invite_code,
                    possible_invite_num: action.user.possible_invite_num,
                    xcredit: action.user.xcredit,
                    date_joined: action.user.date_joined,
                    date_next_pay: action.user.date_next_pay,
                    is_surveydone: action.user.issurveydone,
                    is_push: action.user.ispush,
                    pay_method: action.user.pay_method,
                }
            }
        }
        case SET_AUTO_USER: {
            return{
                ...state,
                user:{
                    ...state.user,
                    id: action.user.id,
                    name: action.user.name,
                    username: action.user.username,
                    // birthdate: action.user.birthdate,
                    // gender: action.user.gender,
                    // email: action.user.email,
                    phone_no: action.user.phoen_no,
                    lastLoginDate: action.user.last_login,
                    membership_type: 'LITE',
                    role: 'LITE',
                    is_push: true,
                    date_joined: action.user.date_joined,
                    weekday_option: action.user.weekday_option,
                    model_option: action.user.model_option,
                    sector_option: action.user.sector_option,
                    survey_result: action.user.survey_result
                }
            }
        }
        case SET_DEFAULT: {
            return {
                ...state,
                user:{
                    name: '',
                    username: '',
                    birthdate: '',
                    // gender: '',
                    email: '',
                    lastLoginDate: '',
                    role: '',
                    invite_code: '',
                },
            }
        }
        case TOGGLE_FETCHING:
            return {...state, fetching: action.fetching}
        case SET_PROGRESS:
            return {...state, course:{...state.course, progress:action.progress}}
        default:
            return state
    }
}

/** ACTION CREATORS
 * use to change state
 * */

export const setUser = newuser =>{
    console.log(newuser)
    return{
        
        type: SET_USER,
        user: newuser
    }
}

export const setAutoUser = newuser =>{
    console.log(newuser)
    return{
        
        type: SET_AUTO_USER,
        user: newuser
    }
}

export const setDefault = () => {
    return{
        type: SET_DEFAULT,
    }
}

export const toggleFetching = (fetching)=>({type:TOGGLE_FETCHING, fetching})



/** THUNKS
 *  use when after getting response from server, you need change the redux-state
 * */
export const login = (userData)=>{
    return async (dispatch)=>{
        try{
           const result = await authAPI.login(userData)
           console.log(result)
            if(!result.user) {
                message.error('잘못된 아이디나 비밀번호입니다.')
                return false
            }
            if(result.membership_type === 'free' )
                result.role = 'FREE'
            else if(result.membership_type === 'free_lite')
                result.role = 'LITE-FREE'
            else if(result.membership_type === 'lite')
                result.role = 'LITE'
            else if(result.membership_type === 'pro')
                result.role = 'PRO'
            else if(result.membership_type === 'admin')
                result.role = 'ADMIN'
            else if(result.membership_type === 'pushadmin')
                result.role = 'PUSH_ADMIN'
            dispatch(setUser(result))
            return [true, result.ispush, result.role]

        }catch(error){
            console.log(error)
        }
    }
}

export const loginAuto = (userData)=>{
    return async (dispatch)=>{
        try{
           const result = await authAPI.loginAuto(userData)
            if(!result.user) {
                message.error('잘못된 아이디나 비밀번호입니다.')
                return false
            }
            console.log('result')
            console.log(result)
            // if(result.membership_type === 'free' )
            //     result.role = 'FREE'
            // else if(result.membership_type === 'free_lite')
            //     result.role = 'LITE-FREE'
            // else if(result.membership_type === 'lite')
            //     result.role = 'LITE'
            // else if(result.membership_type === 'pro')
            //     result.role = 'PRO'
            // else if(result.membership_type === 'admin')
            //     result.role = 'ADMIN'
            dispatch(setAutoUser(result))
            return true

        }catch(error){
            console.log(error)
        }
    }
}

export const logout = ( ) => {
    return async (dispatch)=>{
        try{
            dispatch(setDefault())
        }catch(error){
            console.log(error)
        }
    }
}

export const register = (userData)=>{
    return async (dispatch)=>{
        console.log("register userData")
        console.log(userData)
        try{
            const result = await authAPI.register(userData)
            console.log("register result")
            console.log(result)

            if(!result.user) {
                if (result.code === 401) {
                    message.error('이미 등록 되어 있는 이메일입니다.')
                } else if (result.code === 402) {
                    message.error('이미 등록 되어 있는 휴대전화 번호입니다.')
                } else if (result.code === 403 || result.code === 404) {
                    message.error('사용할 수 없는 초대 코드입니다.')
                } else if (result.code === 500) {
                    message.error('오류가 발생했습니다. 다시 시도해보십시오.')
                } else {
                    message.error('오류가 발생했습니다. 관리자에게 문의 바랍니다.')
                }

                return false
            }
            // dispatch(setUser(result))
            message.success('회원가입이 완료되었습니다. 로그인 해주세요.')
            return true

        }catch(error){
            console.log(error)
        }
    }
}


export default userReducer;
