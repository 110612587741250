import s from "./Admin.module.css";
import { adminApi, appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Dropdown, Menu, Icon, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";

const MobileLoginRecord = (props) => {
  const [dropdownLabel, setDropdownLabel] = useState("전체");
  const [users, setUsers] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [totaloneday_count, setTotaloneday_count] = useState(0);
  const [totalthreeday_count, setTotalthreeday_count] = useState(0);
  const [totalsevenday_count, setTotalsevenday_count] = useState(0);
  const [uniqueoneday_count, setUniqueoneday_count] = useState(0);
  const [uniquethreeday_count, setUniquethreeday_count] = useState(0);
  const [uniquesevenday_count, setUniquesevenday_count] = useState(0);
  const [onedaywise_count, setOnedaywise_count] = useState(0);
  const [threedaywise_count, setThreedaywise_count] = useState(0);
  const [sevendaywise_count, setSevendaywise_count] = useState(0);
  const handleMenuClick = async (e) => {
    setDropdownLabel(e.domEvent.target.innerText);
    let res = await appAdminApi.appadminfilterloginrecords();
    if (res.status == 200) {
      setUsers(res.data.data);
      setFilteredUsers(res.data.data);
    } else {
      message.info("데이터를 가져오지 못했습니다.");
    }
    // let filterResult = await appAdminApi.appMobileLoginRecordUserFilter(e.key);
    // if (filterResult.status == 200) {
    //   setUsers(filterResult.data);
    //   setFilteredUsers(filterResult.data);
    // } else {
    //   message.info("데이터를 가져오지 못했습니다.");
    // }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">전체</Menu.Item>
      <Menu.Item key="2">Free</Menu.Item>
      <Menu.Item key="3">Free_join_benefit</Menu.Item>
      <Menu.Item key="4">Free_referral_benefit</Menu.Item>
      <Menu.Item key="5">Basic_2week_benefit</Menu.Item>
      <Menu.Item key="6">Basic</Menu.Item>
      <Menu.Item key="7">Premium</Menu.Item>
      <Menu.Item key="8">Admin</Menu.Item>
    </Menu>
  );
  const columns = [
    {
      name: "이름",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "아이디",
      selector: (row) => row.username,
      sortable: true,
      wrap: true,
    },

    {
      name: "최근 로그인 일시",
      selector: (row) => row.login_at,
      sortable: true,
      wrap: true,
    },
  ];

  useEffect(async () => {
    let res = await appAdminApi.appadminfilterloginrecords();
    if (res.status == 200) {
      console.log(res.data);
      setTotaloneday_count(res.data.totaloneday_count);
      setTotalthreeday_count(res.data.totalthreeday_count);
      setTotalsevenday_count(res.data.totalsevenday_count);

      setUniqueoneday_count(res.data.uniqueoneday_count);
      setUniquethreeday_count(res.data.uniquethreeday_count);
      setUniquesevenday_count(res.data.uniquesevenday_count);

      setOnedaywise_count(res.data.onedaywise_count);
      setThreedaywise_count(res.data.threedaywise_count);
      setSevendaywise_count(res.data.sevendaywise_count);

      setUsers(res.data.data);
      setFilteredUsers(res.data.data);
    } else {
      setUsers([]);

      setFilteredUsers([]);
    }
    // let res = await appAdminApi.appUserLoginPastRecords();
    // if (res.status == 200) {
    //   setYesterdayTotalUser(res.data.one_daylogin);
    //   setThreeDayTotalUser(res.data.three_daylogin);
    //   setOneWeekTotalUser(res.data.seven_daylogin);
    // }
    // let result = await appAdminApi.appUserLoginRecordListView();
    // if (result.status == 200) {
    //   setUsers(result.data);
    //   setFilteredUsers(result.data);
    // } else {
    //   setUsers([]);

    //   setFilteredUsers([]);
    // }
  }, []);

  const search = (keyword) => {
    let data = users.filter((val) => {
      return (
        val["username"].includes(keyword) ||
        val["name"].includes(keyword) ||
        val["login_at"] == keyword
      );
    });
    setFilteredUsers(data);
  };

  return (
    <>
      <div
        className={s.list_container}
        style={{ height: props.isSummary ? 677 : "calc(100vh - 90px)" }}
      >
        <div className={s.title}>앱 로그인 회원 목록</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div className={s.sub_title}>총 방문자 수 : {users.length}</div>
          {/* <Dropdown.Button overlay={menu} icon={<DownOutlined />}>
            {dropdownLabel}
          </Dropdown.Button> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          {/* <div className={s.sub_title}>1일 방문자 수: {yesterdayTotalUser}</div>
          <div className={s.sub_title}>
            최근 3일 방문자 수: {threeDayTotalUser}
          </div>
          <div className={s.sub_title}>
            최근 7일 방문자 수: {oneWeekTotalUser}
          </div> */}
          <div className={s.sub_title}>
            방문자 수: 1일({totaloneday_count}), 3일({totalthreeday_count}),{" "}
            7일({totalsevenday_count})
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          <div className={s.sub_title}>
            일별 방문 id 수: 1일({onedaywise_count}), 3일(
            {threedaywise_count}), 7일({sevendaywise_count})
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
          }}
        >
          <div className={s.sub_title}>
            고유 방문자 수: 1일({uniqueoneday_count}), 3일(
            {uniquethreeday_count}), 7일({uniquesevenday_count})
          </div>
        </div>

        <Input
          onChange={(e) => {
            search(e.target.value);
          }}
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="회원 검색 (아이디, 이름)"
          prefix={<SearchOutlined />}
        />
        <DataTable
          columns={columns}
          data={filteredUsers}
          pagination="true"
          paginationPerPage={props.rows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            tableWrapper: {
              style: {
                display: "block",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default MobileLoginRecord;
