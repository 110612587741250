import React from "react";
import headerlogo from "../../../../../src/images/headerlogo.png";
import "./Header.css";
function Header() {
  return (
    <div className="header__container">
      <div className="header__inner">
      <img src={headerlogo} alt="logo" className="header__logo" />
      </div>
     
    </div>
  );
}

export default Header;
