import React from 'react'
import s from './WritePost.module.css'
import Header from "../../components/Header/Signed/Header";
import {Checkbox, Col, Form, Input, message, Row} from "antd";
import TextArea from 'rc-textarea';
import {boardAPI} from "../../api/api";
import { useHistory } from 'react-router-dom';
import { WEB_APP_ROUTES } from '../../constants/routes';
import {useSelector} from "react-redux";
import { getUserSelector } from '../../redux/user-selector';

const WritePost = ( ) => {
    const [form] = Form.useForm();
    const {TextArea} = Input;

    const user = useSelector(getUserSelector);
    const history = useHistory();

    const onFinish = async (values) => {
        const ann_type = 0;  // 0 if web and 1 for app anouncment
        // console.log(values)
        const outputValues = {
            'title': values['title'], 
            'content': values['body'],
            'type': ann_type
            
        }

        let result = await boardAPI.createAnnouncementView(outputValues)

        console.log(result)
        if (result === 201) {
            form.resetFields()
            // history.push(WEB_APP_ROUTES.ANNOUNCEMENT)
            return message.success('등록이 완료되었습니다.')
        } else if (result === 400) {
            return message.error('에러가 발생하였습니다. 잠시 후 다시 시도해주시기 바랍니다.')
        } else {
            console.log('unexpected error')
        }
    };

    return <>
    <Header/>

    <Row justify={'center'}>
        <Col xs={24} sm={24} md={24} lg={18} xl={12}>

    <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={(values)=> console.log(values)}
    >

    <div className={`${s.layer}`}>
        <Row justify={'space-start'}>
            <h5>게시글 작성</h5>
        </Row>
        <div className={s.inLayer}>
            <Row justify={"center"}  align="middle">
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <label htmlFor="title" >제목:</label>
                    <Form.Item
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: '제목을 입력해주세요.',
                            },
                        ]}
                    >
                        <Input className={s.field} placeholder={'제목을 입력해주세요.'}/>
                    </Form.Item>
                </Col>
                
            </Row>
            <Row justify={"center"}  align="middle">
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                    <label htmlFor="body" >내용:</label>
                    <Form.Item
                        name="body"
                        rules={[
                            {
                                required: true,
                                message: '게시글 내용을 입력해주세요.',
                            },
                        ]}
                    >
                        <TextArea className={s.field} placeholder={'내용을 입력해주세요.'} type={'text'} rows={10} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    </div>

    <div>
        <Row justify={'center'} >
            <Col xs={22} sm={22} md={22} lg={23} xl={15}>
                <button className={`btn btn-danger deep_bg shadow ${s.button} mb-3`}>
                    등록하기
                </button>
            </Col>
        </Row>
    </div>

    </Form>

        </Col>
    </Row>
    </>
}

export default WritePost