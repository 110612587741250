import s from './Announcement.module.css'
import Header from "../../components/Header/Signed/Header";
import HeaderUnsigned from "../../components/Header/UnSigned/HeaderUnsigned";
import {Col, message, Modal, Row} from "antd";
import {useSelector} from "react-redux";
import {getUserSelector} from "../../redux/user-selector";
import { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import {NavLink} from "react-router-dom";
import {WEB_APP_ROUTES} from "../../constants/routes";

import ViewList from '../../components/Board/ViewList/ViewList';
import ViewPost from '../../components/Board/ViewPost/ViewPost';

import {boardAPI} from "../../api/api";

const Announcement = ( ) => {
    const user = useSelector(getUserSelector);
    const history = useHistory();

    useEffect(async () => {
        let result = await boardAPI.announcementListView();
        // let result = {"data": []}
        // console.log(result.data)
        // result.data = [  // TODO: API
        //     {
        //         "id": 5,
        //         "title": "제목이 충분히 긴 공지사항의 예시",
        //         "content": "한 줄의 내용이 적당히 긴 공지사항의 예시가 되는 공지사항 내용입니다.",
        //         "create_date": "2022-04-04",
        //     },
        //     {
        //         "id": 4,
        //         "title": "네번째 공지입니다.",
        //         "content": "네번째 게시글 내용입니다.",
        //         "create_date": "2022-04-01",
        //     },
        //     {
        //         "id": 3,
        //         "title": "세번째 공지입니다.",
        //         "content": "세번째 게시글 내용입니다.",
        //         "create_date": "2022-03-01",
        //     },
        //     {
        //         "id": 2,
        //         "title": "두번째 공지입니다.",
        //         "content": "두번째 게시글 내용입니다.",
        //         "create_date": "2022-02-01",
        //     },
        //     {
        //         "id": 1,
        //         "title": "첫번째 공지입니다.",
        //         "content": "첫번째 게시글 내용입니다.",
        //         "create_date": "2022-01-01",
        //     },  
        // ];
        console.log(result.data);
        result.data.reverse();

        setDataList(result.data);
        postNo && setPost(getPostInitial(result.data, postNo));
    }, [])

    const [ dataList, setDataList ] = useState([]);
    const [ post, setPost ] = useState({});

    const url = new URL(window.location.href)
    const postNo = url.searchParams.get('no')

    const getPostInitial = (data, no) => {
        const array = data.filter(x => x.id == no);

        console.log(dataList)
        if (array.length == 1) {
          return array[0];
        }
        return null;
    }

    const getPostByNo = no => {
        const array = dataList.filter(x => x.id == no);

        console.log(dataList)
        if (array.length == 1) {
          return array[0];
        }
        return null;
    }

    const listView = () => {
        history.push(WEB_APP_ROUTES.ANNOUNCEMENT)
        // setDataList(data)
    }

    const postView = no => {
        history.push(`/announcement?no=${no}`);
        setPost(getPostByNo(no))
    }

    const createPost = () => {
        history.push(WEB_APP_ROUTES.WRITEPOST)
    }

    return <>
        {user.role ? <Header/> :
            <HeaderUnsigned/>}
        
        <div style={{margin: '5vh'}}/>

        {postNo ?
            <ViewPost user={user} post={post} listView={listView}/> :
            <ViewList user={user} dataList={dataList} postView={postView} createPost={createPost}/>
        }
    </>
}

export default Announcement