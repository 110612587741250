import s from './Simulation.module.css'
import Header from "../../components/Header/Signed/Header";
import SimulationInfo from "../../components/SimulationInfo";
import {useSelector} from "react-redux";
import {getUserSelector} from "../../redux/user-selector";
import { useState } from 'react';

const Simulation = ( ) => {
    const user = useSelector(getUserSelector);
    
    return <>
        <Header/>

        <SimulationInfo user={user}/>

        {/* <SimulationModel/> */}
    </>
}

export default Simulation