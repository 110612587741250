import React, { useState } from 'react';
import {Col, message, Modal, Row} from "antd";
import s from './Survey.module.css';
import {surveyAPI} from "../../api/api";
import { useHistory } from "react-router-dom";
import {WEB_APP_ROUTES} from "../../constants/routes";
import { getUserSelector } from '../../redux/user-selector';
import { useSelector } from 'react-redux';

const numQuestion = 14 // 총 문항 개수
const multiSelect = 5  // 복수 선택 문항 번호

let reply = new Array(numQuestion);

const defaultMultiSelect = {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false}
reply[multiSelect-1] = {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false}

function clickAnswer (index, key) {
    let currentReply = reply[index - 1]
    
    if (index === multiSelect) {
        currentReply[key - 1] = !currentReply[key - 1]

        // 답변 '1' 선택 상태
        if (currentReply[0]) {
            if (key === '1') {  // 답변 '1' 선택 => 다른 답변 선택 취소
                for (const i in currentReply)
                    currentReply[i] = false
                currentReply[0] = true
            } else {  // 다른 답변 선택 => 답변 '1' 선택 취소
                currentReply[0] = false
            }
        }
    } else {
        currentReply = key
    }
    console.log(currentReply)
    reply[index - 1] = currentReply
}



const Survey = ( {questions, answers} ) => {
    const user = useSelector(getUserSelector);

    const [index, setIndex] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const submit = async () => {
        // console.log(!job)
        // console.log(reply.includes(undefined))
        // console.log(reply[multiSelect - 1] === defaultMultiSelect)
        if (user.is_push) {
            reply[numQuestion - 1] = '0'
        }

        if (reply.includes(undefined) || reply[multiSelect - 1] === defaultMultiSelect) {
            return message.error('모든 문항에 답변 해주세요')
        }
        
        console.log(reply)

        let result = await surveyAPI.surveyView(reply[0], reply[1], reply[2], reply[3], reply[4], reply[5], reply[6], reply[7], reply[8], reply[9], reply[10], reply[11], reply[12], reply[13])
        
        console.log('res.status (result): ', result)
        if (result === 201) {
            user.is_surveydone = true
            setShowModal(true)
        } else if (result === 400) {
            message.error('에러가 발생하였습니다. 잠시 후 다시 시도해주시기 바랍니다.')
        } else {
            message.error('관리자에게 문의해주시기 바랍니다.')
        }
    }

    return <Row justify={'center'}>
        <Col xs={24} sm={24} md={24} lg={18} xl={12}>

        <div className={`${s.layer}`}>
            {/** 질문: 단순 스트링 */}
            <div className={`${s.question}`}>
            <Row justify="space-between" align="middle">
                    <Col> <h2>질문 {index}. {questions[index]}</h2> </Col>
                    <Col> <p>{index} / {user.is_push ? numQuestion - 1 : numQuestion}</p> </Col>
            </Row>
            </div>
            {/** 답변: 객관식 (마지막 질문 주관식) */}
            <div className={`${s.inLayer}`}>
                {Object.keys(answers[index]).map(key =>
                    <button className={`${s.answerBox} mt-3 mb-3`}
                        style={reply[index-1] === key || (index === multiSelect && reply[index-1][key-1] === true) ? {border: 'medium solid #990000'} : null}
                        onClick={() => {clickAnswer(index, key); forceUpdate();}}>
                            {answers[index][key]}
                    </button>)}
            </div>
            <div className='buttons' style={{margin: 15}}>
            {/** 버튼: "뒤로" (첫번째 질문 X) & "다음" (마지막 질문 "제출"로 대체) */}
                <Row justify="space-between">
                    {index === 1 ? <div className={`${s.button}`}/>
                        : <button className={`btn btn-dark shadow ${s.button}`} onClick={() => setIndex(index-1)}>뒤로</button>}
                    {index === numQuestion || (user.is_push && index === numQuestion - 1) ? <button className={`btn btn-danger deep_bg shadow ${s.button}`} onClick={() => {submit(); forceUpdate();}}>제출</button>
                        : <button className={`btn btn-dark shadow ${s.button}`} onClick={() => setIndex(index+1)}>다음</button>}
                </Row>
            </div>
        </div>

        </Col>

        <Modal
            centered
            visible={showModal}
            closable={false}
            footer={null}
            >
                <Row justify={'center'}>
                    <Col span={15}>
                        <img src="/static/icons/deepLogo.png" alt="deepTrade logo" width={'100%'}/>
                    </Col>
                    <Col span={24}>
                        <div className={`${s.inLayer} my-3`}>
                            <Row justify={"center"}>
                                <Col span={20} className={'text-center'}>
                                    고객님의 답변이 정상적으로 전송되었습니다.
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <button className={`btn btn-dark`} onClick={() => history.replace(WEB_APP_ROUTES.HOME)}>
                        홈으로
                    </button>
                </Row>
            </Modal>
    </Row>
}

export default Survey