import React from 'react'
import s from './updateInfo.module.css'
import ChangePassword from "../../components/changePassword";
import {Col, Form, Input, message, Modal, Rate, Row} from "antd";
import { Typography } from 'antd';
import { useHistory, useLocation } from "react-router-dom";
import { useState } from 'react';

const { Paragraph } = Typography;

const UpdateInfo = ( {user} ) => {
    const [form] = Form.useForm();
    // const history = useHistory();
    // const location = useLocation();

    const [showModal, setShowModal] = useState(false);

    const onFinish = (values) => {
        console.log('values')
        form.resetFields()
    };


    console.log({user})

    return <Row justify={'center'}>
        <Col xs={24} sm={24} md={24} lg={18} xl={12}>
            <Form
                form={form}
                name="basic"
                onFinish={null}
                initialValues={{
                    name: user.name,
                    id_username: user.username,
                    email: user.email,
                    code: user.invite_code,
                }}

                onFinishFailed={values=> console.log(values)}
            >
                <div className={`${s.layer} update_info`}
                     data-aos={"zoom-in"} data-aos-duration={"1000"}
                     >
                    <Row justify={'space-between'}>
                        <Col xs={12} sm={10} md={6} lg={8} xl={10}>
                            <h5>
                                나의 가입 정보
                            </h5>
                        </Col>
                        <Col xs={12} sm={10} md={6} lg={8} xl={10}>
                            최초 가입일: {user.date_joined.substring(0, 10)}
                        </Col>
                    </Row>
                    <div className={s.inLayer}>
                        <Row justify={'space-around'}>
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <label htmlFor="id_username" className={s.label}>아이디</label>
                                <Form.Item
                                    name="id_username"
                                    rules={[
                                        {
                                            required: true,
                                            message: '아이디를 입력하세요!',
                                        }
                                    ]}
                                >
                                    <Input className={s.field} value={user.username} readOnly/>
                                </Form.Item>
                            </Col>
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <label htmlFor="id_username" >성함</label>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            disabled: true,
                                            // required: true,
                                            // message: '이름을 입력하세요!',
                                        },
                                    ]}

                                >
                                    <Input className={s.field} value={user.name} readOnly/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={"space-around"}  align="middle">
                            <Col span={22}>

                                <label htmlFor="id_username" className={s.label}>이메일</label>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: '이메일을 입력하세요!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'input normal email'
                                        }
                                    ]}
                                >
                                    <Input className={s.field} value={user.email} readOnly/>
                                </Form.Item>
                            </Col>
                            {/* <Col span={5}>
                                <button className={`btn btn-warning shadow mb-3 ${s.button}`}> 수정</button>
                            </Col> */}
                        </Row>
                        <Row justify={"space-around"}  align="middle">
                            <Col span={10}>
                                <label htmlFor="invite_code" className={s.label}>추천코드</label>
                                <Form.Item
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message: '추천코드를 입력하세요!',
                                        }
                                    ]}
                                >
                                    <Input className={s.field}  value={user.invite_code} readOnly/>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <label htmlFor="invite_link" className={s.label}>남은 횟수: {user.possible_invite_num} / 10</label>
                                <Form.Item>
                                    <button className={`btn btn-warning shadow my-auto ${s.button}`}  // TODO: 링크 xpct.net으로 수정
                                        onClick={() => navigator.clipboard.writeText('https://xpct.net/sign-up?code=' + user.invite_code).then(function() {
                                                message.success('초대 링크가 클립보드에 복사되었습니다.')
                                            }, function() {
                                                message.error('초대 링크를 복사할 수 없습니다.')
                                            }
                                        )}>
                                        초대 링크 복사
                                    </button>
                                </Form.Item>
                            </Col>
                            {/* <Col span={10}>
                                <label htmlFor="invite_link" className={s.label}>남은 횟수: {user.possible_invite_num} / 10</label>
                                <Paragraph
                                    className={s.copy}
                                    copyable={{
                                        icon: [<button className={`btn btn-danger shadow my-auto ${s.button}`} key={'copy-icon'}>초대 링크</button>],
                                        tooltips: false,
                                        text: '147.46.174.153:8000/sign-up?code=' + user.invite_code  // TODO: 추후 실사용 도메인으로 변경
                                    }}
                                    style={{marginRight: '5px'}}
                                />
                            </Col> */}
                        </Row>
                        <Row justify={'space-around'}>
                            <Col span={22}>
                                (추천 코드를 이용하여 가입시, 추천 코드 제공자의 Lite 무료 사용 기간이 2주 연장됩니다)
                            </Col>
                        </Row>
                    </div>
                    <Row justify={'center'}>
                        <Col  xs={10} sm={12} md={12} lg={23} xl={15}>
                            <button className={`btn shadow ${s.button}`} onClick={() => {setShowModal(true)}} style={{backgroundColor: '#0a66c2'}}>
                                비밀번호 변경
                            </button>
                        </Col>
                    </Row>
                </div>
            </Form>
            <Modal
                bodyStyle={{padding: 0}}
                style={{padding: 0}}
                centered
                className="passwordModal"
                visible={showModal}
                onCancel={() => {setShowModal(false)}}
                footer={null}
            >
                <ChangePassword style={{margin: 0}} />
            </Modal>
        </Col>
    </Row>

}

export default UpdateInfo