import "./XpercentTips.css";
import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";

import shannon from "./assets/files/shannon_stocks.json";
import nonShannon from "./assets/files/nonshannon_stocks.json";
import { appXpercentTip } from "../../../api/api";
import signalImage from "../../../images/signaltip.png";
import copy from "copy-to-clipboard";
import MetaTags from "react-meta-tags";
import axios from "axios";
function XpercentTips(props) {
  const [totalUser, setTotalUser] = useState(0);
  const [isClickedSurveyBtn, setisClickedSurveyBtn] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [recommendedStockList, setRecommendedStockList] = useState([]);
  const [shannonList, setShannonList] = useState(shannon);
  const [nonShannonList, setNonShannonList] = useState(nonShannon);
  const [fiveStockUserChoosed, setFiveStockUserChoosed] = useState([]);
  const [currentStockListState, setCurrentStockListState] = useState([]);
  const [showProgressbar, setShowProgressbar] = useState(false);
  const [finishResult, setFinishResult] = useState(false);
  const [resultDataState, setResultDataState] = useState({});
  const [userResultColorState, setUserResultColorState] = useState("");
  const [shannnoResultColorState, setShannonResultColorState] = useState("");
  const [callMakeConclusion, setCallMakeConclusion] = useState(false);
  const totalScreens = 5;
  const [browserName, setBrowserName] = useState("");
  const [userIp, setUserIp] = useState("");
  const [phoneType, setphoneType] = useState("");

  // let dbUserId = null;
  const [userId, setUserId] = useState(null);
  const progress = (currentScreen / totalScreens) * 100;
  // const fetchTipParticpatedUsers = async () => {
  //   const res_data = await appXpercentTip.fetchParticipate();
  //   if (res_data.status == 200) {
  //     // console.log("into fetchParticipate users", res_data.data)
  //     setTotalUser(res_data.data.totat_users);
  //   }
  // };
  const fetchTipParticpatedUsers = async () => {
    const res_data = await appXpercentTip.fetchXpercentTipParticipate();
    if (res_data.status == 200) {
      // console.log("into fetchParticipate users", res_data.data)
      let _totalUser = parseInt(res_data.data.totat_users);
      _totalUser = _totalUser + 10000;
      setTotalUser(_totalUser);
    } else {
      setTotalUser(0);
    }
  };
  const getBrowserName = async () => {
    const userAgent = window.navigator.userAgent;
    let name = "";
    if (userAgent.indexOf("Chrome") !== -1) {
      name = "Google Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
      name = "Apple Safari";
    } else if (userAgent.indexOf("Firefox") !== -1) {
      name = "Mozilla Firefox";
    } else if (
      userAgent.indexOf("MSIE") !== -1 ||
      userAgent.indexOf("Trident/") !== -1
    ) {
      name = "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") !== -1) {
      name = "Microsoft Edge";
    } else if (
      userAgent.indexOf("Opera") !== -1 ||
      userAgent.indexOf("OPR/") !== -1
    ) {
      name = "Opera";
    } else if (userAgent.indexOf("YaBrowser") !== -1) {
      name = "Yandex Browser";
    } else {
      name = "Unknown browser";
    }
    console.log("browser name", name);
    setBrowserName(name);
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);
    if (isIOS == true) {
      setphoneType("ios");
    } else if (isAndroid == true) {
      setphoneType("android");
    } else {
      setphoneType("web");
    }
  };

  console.log("\n", "phonetype", phoneType);
  const getUserIp = async () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        console.log("ip address", response.data.ip);
        setUserIp(response.data.ip);
      })
      .catch((error) => {
        setUserIp("");
        console.log(error);
      });
  };
  useEffect(() => {
    let ismountedComponent = true;
    if (ismountedComponent) {
      fetchTipParticpatedUsers();
      getBrowserName();
      getUserIp();
    }

    return () => {
      ismountedComponent = false;
    };
  }, []);

  const handleNext = () => {
    if (currentScreen < totalScreens) {
      setCurrentScreen(currentScreen + 1);
    }

    return;
  };

  const handlePrev = () => {
    if (currentScreen > 1) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  const handleUserSharedLink = async (type) => {
    const formData = {
      id: userId,
      sharedtype: type,
    };

    await appXpercentTip.sharedLinkXperentTip(formData);

    // const res_userShared = await appXpercentTip.shareLinkToUser(formData);
    // if (res_userShared.status == 200) {
    //   // console.log("successfully shared and saved in db");
    // } else {
    //   // console.log("failed shared");
    // }
  };

  const handlePressAppBtn = async (type) => {
    const formData = {
      id: userId,
      appstoreBtn_type: type,
    };

    await appXpercentTip.pressAppStoreBtnXperentTip(formData);
  };

  const handleClose = () => {
    setNonShannonList(nonShannonList);
    setShannonList(shannonList);
    setCurrentStockListState([]);
    setFiveStockUserChoosed([]);
    setRecommendedStockList([]);
    setisClickedSurveyBtn(false);
    setCurrentScreen(1);
    setShowProgressbar(false);
    setFinishResult(false);
    window.location.reload(true);
    // history.push('/tip')
    // const newWindow = window.open("https://xpercent.io/tip", "noopener,noreferrer");
    // if (newWindow) newWindow.opener = null;
  };

  const handleReload = () => {
    setNonShannonList(nonShannonList);
    setShannonList(shannonList);

    setCurrentStockListState([]);
    setFiveStockUserChoosed([]);
    setRecommendedStockList([]);
    setisClickedSurveyBtn(false);
    setCurrentScreen(1);
    setShowProgressbar(false);
    setFinishResult(false);
    fetchTipParticpatedUsers();
    window.location.reload(true);
  };

  useEffect(() => {
    let ismountedComponent = true;
    if (ismountedComponent) {
      if (isClickedSurveyBtn) {
        let show_stock_current = [];
        setCurrentStockListState([]);

        // Get a random item 5 times without repeats
        const index = Math.floor(Math.random() * shannonList.length);
        const item = shannonList[index];

        shannonList.splice(index, 1);
        show_stock_current.push(item);
        setRecommendedStockList((prevState) => prevState.concat(item)); // 마지막에 수익률 계산 할 때 사용
        for (let i = 0; i < 2; i++) {
          const index = Math.floor(Math.random() * nonShannonList.length);
          const non_shannonitem = nonShannonList[index];
          nonShannonList.splice(index, 1);
          show_stock_current.push(non_shannonitem);
          setRecommendedStockList((prevState) =>
            prevState.concat(non_shannonitem)
          );
        }
        shuffleList(show_stock_current);
      }
    }
    return () => {
      ismountedComponent = false;
    };
  }, [isClickedSurveyBtn, currentScreen]);
  // console.log(`recommendedStockList`, recommendedStockList);
  // console.log(`currentStockListState`, currentStockListState);
  // console.log(`fivestockuser choosed`, fiveStockUserChoosed);
  // console.log(`resultDataState`, resultDataState);

  const makeconclusion = async () => {
    let isUserWin = false;
    let user_total_sum = 0;
    let shannon_total_sum = 0;

    fiveStockUserChoosed.map((item) => {
      user_total_sum += parseFloat(item.test_profit);
    });

    let user_total_avg = user_total_sum / 5;

    recommendedStockList.map((s_item) => {
      if (s_item.shannon == 1) {
        shannon_total_sum += parseFloat(s_item.test_profit);
      }
    });

    let shannon_total_avg = shannon_total_sum / 5;

    const usercolorClassName =
      user_total_avg > 0
        ? "red_class"
        : user_total_avg == 0
        ? "gray_class"
        : "blue_class";
    setUserResultColorState(usercolorClassName);
    const shannnocolorClassName =
      shannon_total_avg > 0
        ? "red_class"
        : shannon_total_avg == 0
        ? "gray_class"
        : "blue_class";
    setShannonResultColorState(shannnocolorClassName);
    let count = 0;

    for (let i = 0; i < fiveStockUserChoosed.length; i++) {
      if (fiveStockUserChoosed[i].shannon === 1) {
        count += 1;
      }
    }
    if (count >= 4 && count <= 5) {
      //user wins for db
      isUserWin = true;

      setResultDataState({
        id: 1,
        resultTitle: "당신은 곧 부자가 될 주고수!",
        resultImage:
          "https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/wealthy-businessman.png",
        myResult: user_total_avg,
        shannonResult: shannon_total_avg,
      });
    } else if (count >= 1 && count < 4) {
      //user loses
      isUserWin = false;

      setResultDataState({
        id: 2,
        resultTitle: "당신은 주식 공부하다 지친 주초보!",
        resultImage:
          "https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/studygirl.jpg",
        myResult: user_total_avg,
        shannonResult: shannon_total_avg,
      });
    } else {
      //user lose
      isUserWin = false;

      setResultDataState({
        id: 3,
        resultTitle: "당신은 주식이 어려운 주린이!",
        resultImage:
          "https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/questions.webp",
        myResult: user_total_avg,
        shannonResult: shannon_total_avg,
      });
    }

    setTimeout(async () => {
      setShowProgressbar(false);
      setFinishResult(true);
      //save the data into db and plus the count by 1
      const formData = {
        user_ip: userIp,
        browser_type: browserName,
        phone_type: phoneType,
        user_score: String(user_total_avg.toFixed(2)),
        shannon_score: String(shannon_total_avg.toFixed(2)),
        winner: String(isUserWin),
      };
      const res_data = await appXpercentTip.addUserToXperentTip(formData);
      if (res_data.status == 201) {
        setUserId(res_data.data.id);
      } else {
        console.log("participated failed");
      }
      const add_parti_res = await appXpercentTip.addParticipate();
      if (add_parti_res.status == 200) {
        fetchTipParticpatedUsers();
      } else {
        // console.log("participated failed");
      }
    }, 2000);
  };

  // console.log("fiveStockUserChoosed", fiveStockUserChoosed);
  // console.log("CurrentStockListState", currentStockListState);
  useEffect(() => {
    let isComonentMounted = true;
    if (isComonentMounted) {
      if (callMakeConclusion === true) {
        makeconclusion();
        setCallMakeConclusion(false);
      }
    }

    return () => {
      isComonentMounted = false;
    };
  }, [callMakeConclusion]);

  const handleStockContent = (stock_obj) => {
    if (currentScreen == 5) {
      // console.log("stock_obj in current screeen 4", stock_obj);
      //show progress bar

      // setFiveStockUserChoosed(prevState => [...prevState, stock_obj]);
      setFiveStockUserChoosed([...fiveStockUserChoosed, stock_obj]);
      setShowProgressbar(true);
      setCallMakeConclusion(true);
      return;
    }
    setFiveStockUserChoosed((prevState) => [...prevState, stock_obj]);
    // setFiveStockUserChoosed([...fiveStockUserChoosed, stock_obj]);
    // console.log("stock_obj", stock_obj);

    handleNext();
    //change stock list item
    // console.log("clicked");
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const stockItemRender = (stockData) => {
    return (
      <div className="stock_view">
        <div
          onClick={() => {
            handleStockContent(stockData);
          }}
          className="stock_view_wrapper"
        >
          <div className="stock_view_left">
            {stockData?.stock_img ? (
              <div className="tip_stockimgtext">
                <div className="tip_stock_img">
                  <img
                    src={stockData?.stock_img}
                    alt="stock image"
                    width="102"
                  />
                </div>
                <div className="tip_stock_name">
                  <p style={{ color: "#000", fontSize: 15 }}>
                    {stockData?.stock_name}
                  </p>
                </div>
              </div>
            ) : (
              stockData?.stock_name
            )}
          </div>
          <div className="stock_view_right">
            <div className="img_container">
              {" "}
              <img src={stockData?.graph_img} alt="graph image" width="120" />
            </div>
            <p
              className="stock_percentage"
              style={{
                color:
                  parseFloat(stockData?.graph_profit) > 0
                    ? "#e7544c"
                    : parseFloat(stockData?.graph_profit) == 0
                    ? "#000000"
                    : "#3272D9",
              }}
            >
              {parseFloat(stockData?.graph_profit).toFixed(2) > 0
                ? "+" + String(parseFloat(stockData?.graph_profit).toFixed(2))
                : parseFloat(stockData?.graph_profit).toFixed(2)}
              %
            </p>
          </div>
        </div>
      </div>
    );
  };
  const shuffleList = (show_stock_current) => {
    const randomIndices = [];
    while (randomIndices.length < 3) {
      const randomIndex = Math.floor(Math.random() * show_stock_current.length);
      if (!randomIndices.includes(randomIndex)) {
        randomIndices.push(randomIndex);
      }
    }
    const shuffledList = [...show_stock_current];
    [
      shuffledList[randomIndices[0]],
      shuffledList[randomIndices[1]],
      shuffledList[randomIndices[2]],
    ] = [
      shuffledList[randomIndices[2]],
      shuffledList[randomIndices[0]],
      shuffledList[randomIndices[1]],
    ];
    // console.log("shuffledList", shuffledList);
    setCurrentStockListState(shuffledList);
  };

  const handleClickCopyClipboard = () => {
    copy("https://xpercent.io/tip");
    alert("링크가 복사 되었습니다. ");
    handleUserSharedLink("link");
  };

  const insertCommaToNumber = (number) => {
    return parseInt(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="App">
      <MetaTags>
        <title>주식 투자 IQ 테스트</title>
        <meta charset="UTF-8"></meta>
        <meta
          name="keywords"
          content="주식 테스트, 주식 능력 테스트, 주식, 주가 예측, AI 주가, 주식 투자 능력 테스트, 인공지능, xpercent, 로보어드바이저, 주식 투자, 주식 투자 테스트"
          data-rh="true"
        />
        <meta
          name="naver-site-verification"
          content="6cffb7f32eb414fa729dc91769edb6448f626083"
        />
        <meta
          name="google-site-verification"
          content="eskhp_h6atdJibs_oYCACz7CuA3Jh6QQRPVe3ERrv1U"
        />
        <meta
          name="google-site-verification"
          content="hQwiBNhwBcYETG8Fl836BsdDS4ucAd78lHtT7DRKo_o"
        />
        <meta
          name="description"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        />
        <meta
          name="og:image"
          content="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/Screenshot+2023-04-05+at+4.17.40+PM.png"
        />
        <meta name="og:name" content="주식 투자 IQ 테스트" />
        <meta
          name="og:image"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        />
        <meta name="blogcatalog" />
        <meta name="target" content="all" />
        <meta name="audience" content="all" />
        {/* apple meta tag */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta content="yes" name="apple-touch-fullscreen" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="format-detection" content="telephone=no"></meta>
        <meta name="subject" content="주식 투자 테스트"></meta>
        <meta name="copyright" content="xpercent.io"></meta>
        <meta name="language" content="KR"></meta>
        <meta name="robots" content="index,follow" />
        <meta name="topic" content="주식 투자 IQ 테스트" />
        <meta name="Classification" content="financial"></meta>
        <meta name="identifier-URL" content="https://xpercent.io"></meta>
        <meta name="coverage" content="Worldwide" />
        <meta name="distribution" content="Global" />

        <meta
          name="summary"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        ></meta>
        <meta name="og:locale" content="ko_KR" />

        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="주식 투자 IQ 테스트" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="주식 투자 IQ 테스트" />
        <meta
          name="twitter:description"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        />
        <meta
          name="image"
          content="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/Screenshot+2023-04-05+at+4.17.40+PM.png"
        />
        <meta name="name" content="주식 투자 IQ 테스트" />
        <meta
          name="image"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        />
        <meta name="locale" content="ko_KR" />
        <meta name="type" content="website" />
        <meta name="site_name" content="주식 투자 IQ 테스트" />
        <meta name="facebook:card" content="summary" />
        <meta name="facebook:title" content="주식 투자 IQ 테스트" />
        <meta
          name="facebook:description"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        />
        <meta itemProp="name" content="주식 투자 IQ 테스트" />
        <meta
          itemProp="description"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        />
        <meta
          itemProp="image"
          content="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/Screenshot+2023-04-05+at+4.17.40+PM.png"
        />
        <meta property="og:title" content="주식 투자 IQ 테스트" />
        <meta property="og:site_name" content="https://xpercent.io/tip" />
        <meta
          property="og:description"
          content="나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면! 주식 투자 IQ 테스트"
        />
        <meta property="og:locale" content="ko_KR" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="주식 투자 IQ 테스트" />
        <meta
          property="og:image"
          content="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/Screenshot+2023-04-05+at+4.17.40+PM.png"
        />
        <meta property="og:image:alt" content="주식 투자 IQ 테스트" />
      </MetaTags>

      <div className="container_app">
        {!isClickedSurveyBtn ? (
          <>
            <h1 className="title_h1">주식 투자 IQ 테스트</h1>
            <p className="text_p">한달 후에 나는 부자가 될 수 있을까?</p>
            <p className="text_p">AI 섀넌과 대결해보세요!</p>
            <img
              className="img_app"
              src="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/mainscreenimg.jpg"
              alt="image"
              width="290"
              height="180"
            />

            <p className="total_user_p">
              현재{" "}
              <strong
                style={{ fontWeight: "900", color: "#4c8be7" }}
                className="total_user_app"
              >
                {insertCommaToNumber(totalUser)}
              </strong>{" "}
              명이 테스트 완료!
            </p>
            <button
              onClick={() => {
                setisClickedSurveyBtn(true);
              }}
              className="test_btn"
            >
              시작하기
            </button>
          </>
        ) : (
          <>
            {showProgressbar ? (
              <div className="loader">
                <h2 className="progress_text">포트폴리오 수익률 계산 중</h2>
                <Oval
                  height="50"
                  width="50"
                  radius="3"
                  color="#3b67aa"
                  secondaryColor="#3b67aa"
                  ariaLabel="loading"
                  wrapperStyle
                  wrapperClass
                />
              </div>
            ) : !finishResult ? (
              <>
                <div className="header_container">
                  <h4 className="title_h4"> 주식 투자 IQ 테스트</h4>
                  <div className="header_left_container">
                    <div className="close" onClick={handleClose}>
                      <svg
                        width="35"
                        height="35"
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          fill-opacity="0.01"
                        />
                        <path
                          d="M14 14L34 34"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 34L34 14"
                          stroke="#333"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="tip_progress_container">
                  <div className="tip_progress-bar">
                    <div
                      className="tip_progress"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <div className="screen-number">
                    {currentScreen} / {totalScreens}
                  </div>
                </div>
                <div className="inner_content_container">
                  <h4 className="header_h4">
                    한달 후에 상승할 것 같은 종목을 1개 골라주세요
                  </h4>
                  <div className="content_headers">
                    <p
                      style={{
                        color: "#000",
                        fontSize: 19,
                        fontWeight: "normal",
                      }}
                    >
                      종목명
                    </p>
                    <p
                      style={{
                        color: "#000",
                        fontSize: 19,
                        fontWeight: "normal",
                      }}
                    >
                      과거 주가(두달)
                    </p>
                  </div>
                  {currentStockListState &&
                    currentStockListState.map((item) => stockItemRender(item))}
                </div>
              </>
            ) : (
              <>
                {/* final result of the user */}
                <div className="result_container">
                  <h1 className="result_header_title">
                    {resultDataState.resultTitle}
                  </h1>
                </div>
                <img
                  src={resultDataState.resultImage}
                  alt="image"
                  width={
                    resultDataState.id == 2
                      ? "242"
                      : resultDataState.id == 1
                      ? "210"
                      : "250"
                  }
                  height={resultDataState.id == 2 ? "243" : "180"}
                />
                <div className="rsltcontent_headers">
                  <div className="left_content_header">
                    <p className="result_p_text">
                      당신이 선택한 종목의 <br />
                      수익률은
                    </p>
                    <p
                      className={userResultColorState}
                      style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        padding: 0,
                        margin: 0,
                        color:
                          userResultColorState === "red_class"
                            ? "#e7544c"
                            : userResultColorState === "gray_class"
                            ? "#000"
                            : "#5d97d9",
                      }}
                    >
                      {resultDataState.myResult.toFixed(2) > 0
                        ? "+" + String(resultDataState.myResult.toFixed(2))
                        : resultDataState.myResult.toFixed(2)}
                      %
                    </p>
                  </div>
                  <div className="right_content_header">
                    <p className="result_p_text">
                      AI 섀넌이 선택한 종목의 <br />
                      수익률은
                    </p>
                    <p
                      style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        padding: 0,
                        margin: 0,
                        color:
                          shannnoResultColorState === "red_class"
                            ? "#e7544c"
                            : shannnoResultColorState === "gray_class"
                            ? "#000"
                            : "#5d97d9",
                      }}
                    >
                      {resultDataState.shannonResult.toFixed(2) > 0
                        ? "+" + String(resultDataState.shannonResult.toFixed(2))
                        : resultDataState.shannonResult.toFixed(2)}
                      %
                    </p>
                  </div>
                </div>
                <div
                  className="tip_askaicontainer"
                  onClick={() => {
                    openInNewTab("https://xpercent.io/");
                  }}
                >
                  <div className="tip_askaishannon_box">
                    <div className="tip_askaishannon_left">
                      <p>
                        주식이 어렵다면? <br /> AI 섀넌에게 물어봐!
                      </p>
                    </div>
                    <div className="tip_askaishannon_right">
                      <img src={signalImage} alt="signal image" />
                    </div>
                  </div>
                </div>

                {/* <div
                  style={{ cursor: "pointer" }}
                  className="staticImg"
                  onClick={() => {
                    openInNewTab("https://xpercent.io/");
                  }}
                >
                  <img
                    src="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/Group+1.png"
                    alt=""
                    width="377"
                    height="190"
                  />
                </div> */}
                {/* <button
                  onClick={() => {
                    openInNewTab("https://xpercent.io/");
                  }}
                  className="result_btn"
                >
                  AI 섀넌에게 물어보러 가기
                </button> */}
                <div className="sns_bottom_appstore">
                  <div className="sns_title">
                    <div className="sns_icon_container">
                      <a
                        onClick={() => {
                          handlePressAppBtn("ios");
                        }}
                        style={{ textDecoration: "none", color: "#000" }}
                        className="icontext_wrapper"
                        href="https://apps.apple.com/kr/app/xpercent/id1632561591?l"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/appleBtn.png"
                          alt=""
                          width="100"
                          height="35"
                        />
                      </a>

                      <div className="icontext_wrapper">
                        {" "}
                        <a
                          onClick={() => {
                            handlePressAppBtn("android");
                          }}
                          style={{ textDecoration: "none", color: "#000" }}
                          className="icontext_wrapper"
                          href="https://play.google.com/store/apps/details?id=com.xpct"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/playstoreBtn.png"
                            alt=""
                            width="100"
                            height="35"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sns_bottom">
                  <div className="sns_title">
                    <p
                      style={{
                        marginBottom: "5%",
                        color: "black",
                        fontSize: 18,
                      }}
                    >
                      친구에게 링크 공유하기
                    </p>

                    <div className="sns_icon_container">
                      <a
                        id="kakaotalk-sharing-btn"
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          cursor: "pointer",
                        }}
                        className="icontext_wrapper"
                        onClick={() => {
                          handleUserSharedLink("kakao");
                        }}
                        href="javascript:shareMessage()"
                      >
                        <img
                          src="https://developers.kakao.com/assets/img/about/logos/kakaotalksharing/kakaotalk_sharing_btn_medium.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          style={{
                            marginTop: "2%",
                            color: "black",
                            fontSize: 14,
                          }}
                        >
                          카카오로 공유하기
                        </p>
                      </a>
                      <div
                        style={{ cursor: "pointer" }}
                        className="icontext_wrapper"
                        onClick={handleClickCopyClipboard}
                      >
                        <img
                          src="https://xpercent-bucket.s3.ap-northeast-2.amazonaws.com/shareicon.png"
                          alt=""
                          width="40"
                          height="40"
                        />{" "}
                        <p
                          style={{
                            marginTop: "2%",
                            color: "black",
                            fontSize: 14,
                          }}
                        >
                          링크 복사하기
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="reload_box"
                  onClick={() => {
                    handleReload();
                  }}
                >
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgt5JN5xB4TrOdGTjBvROPOB8aVHMfA0IPUeEQjDzwsXzUQ3MDNijBjWRmwPNG0Odhbk4&usqp=CAU"
                    alt=""
                    width="15"
                    height="15"
                  />
                  <p
                    style={{
                      color: "#000000",
                      paddingLeft: 14,
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    다시 해보기
                  </p>
                </div>
                <p className="bottom_text">
                  *본 수익률은 2022년 10월 31일에 섀넌 예측한 결과에 따라 2022년
                  11월 1일부터 2022년 11월 31일까지 투자를 수행한 성과입니다.{" "}
                </p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default XpercentTips;
