// import s from './Main.module.css'
import { NavLink } from "react-router-dom";
import HeaderUnsigned from "../../components/Header/UnSigned/HeaderUnsigned";
import useWidth from "../../features/useWidth/useWidth";
import AboutPart from "./about/About";
import { WEB_APP_ROUTES } from "../../constants/routes";
import { Col, Modal, Row } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "./styles.css";
import MultiAxisLine from "../../components/Charts/examples/MultiAxisLine";
import moment from "moment";
import useIntersection from "./useIntersection";
import { overviewAPI } from "../../api/api";
import Header from "../AppIntroScreens/components/Header/Header";
import AppStoreComponent from "../AppIntroScreens/components/AppStoreComponent/AppStoreComponent";
import Home from "../AppIntroScreens/pages/Home/Home";
import VideoSection from "../AppIntroScreens/components/VideoSection/VideoSection";
import XpercentTipComponent from "../AppIntroScreens/pages/XpercentTipSection/XpercentTipComponent";
SwiperCore.use([Pagination]);

const Main = () => {
  const cookie = getCookie("mycookie");
  const [showModal, setShowModal] = useState(
    cookie !== "popupEnd" ? true : false
  );
  const [downButtonHidden, setDownButtonHidden] = useState(false);
  const [upButtonHidden, setUpButtonHidden] = useState(true);
  const [touchStart, setTouchStart] = useState(0);
  const { isTabletOrMobile } = useWidth();
  const [model, setModel] = useState();
  const [kospi, setKospi] = useState();
  const [kosdaq, setKosdaq] = useState();
  const [isShowTipBannner, setIsShowTipBannner] = useState(false);
  const introduction = useRef(null);
  const main = useRef(null);
  const introSwiper = useRef(null);
  const introductionInViewport = useIntersection(introSwiper, "0px");

  function setCookie(name, value, expiredays) {
    var today = new Date();

    console.log(today.getDate());

    today.setDate(today.getDate() + expiredays); // 현재시간에 하루를 더함

    document.cookie =
      name + "=" + escape(value) + "; expires=" + today.toGMTString();

    setShowModal(false);
  }

  function getCookie(name) {
    var cookie = document.cookie;

    if (document.cookie != "") {
      var cookie_array = cookie.split("; ");
      console.log(cookie_array);
      for (var index in cookie_array) {
        var cookie_name = cookie_array[index].split("=");
        if (cookie_name[0] == "mycookie") {
          return cookie_name[1];
        }
      }
    }
    return;
  }

  // function scrollDown() {
  //     introduction.current.scrollIntoView({ behavior: "smooth"});
  //     setDownButtonHidden(true);
  //     setUpButtonHidden(false);
  // }

  // function scrollUp() {
  //     main.current.scrollIntoView({ behavior: "smooth"});
  //     setDownButtonHidden(false);
  //     setUpButtonHidden(true);
  // }

  // useEffect(() => {
  //     const get_data_from_api = async () => {
  //         let overview_data = await overviewAPI.get_overview_data(5)

  //         var model_rate = overview_data.model_rate;
  //         var kospi_rate = overview_data.kospi_rate;
  //         var kosdaq_rate = overview_data.kosdaq_rate;
  //         var model_value = (((model_rate[model_rate.length - 1]) - 1) * 100);
  //         var kospi_value = (((kospi_rate[kospi_rate.length - 1]) - 1) * 100);
  //         var kosdaq_value = (((kosdaq_rate[kosdaq_rate.length - 1]) - 1) * 100);
  //         console.log(model_value)
  //         console.log(kospi_value)
  //         console.log(kosdaq_value)
  //         setModel(model_value);
  //         setKospi(kospi_value);
  //         setKosdaq(kosdaq_value);
  //     }

  //     get_data_from_api();

  //     if(introductionInViewport)
  //     {
  //         setDownButtonHidden(true);
  //         setUpButtonHidden(false);
  //         console.log('introduction in viewport')
  //     }
  //     else
  //     {
  //         setDownButtonHidden(false);
  //         setUpButtonHidden(true);
  //     }
  // },[introductionInViewport, downButtonHidden, upButtonHidden])

  // const MobileMain = () => (
  //     <>
  //         <div>
  //             <div className={`row mx-0 gy-1 justify-content-around row-cols-sm-1 row-cols-md-2 ${s.buttons}`}>

  //                 <NavLink to={WEB_APP_ROUTES.ABOUT} className={`col-lg-3 col-md-4 col-sm-4 col-4 ${s.link}`}>
  //                     <button type="button" className="btn btn-outline-dark col-12" id='btnToAboutPage'>
  //                         소개
  //                     </button>
  //                 </NavLink>
  //                 <NavLink to={WEB_APP_ROUTES.LOGIN} className={`col-lg-3 col-md-4 col-sm-4 col-4 ${s.link}`}>
  //                     <button type="button" className="btn btn-dark col-12" id='btnToLoginPage'>
  //                         로그인
  //                     </button>
  //                 </NavLink>

  //             </div>
  //             <h6 className={'text-center'}> 아직 회원이 아니세요? <NavLink to={WEB_APP_ROUTES.SIGNUP}>무료 회원가입</NavLink></h6>
  //             <h6 className={`text-center ${s.footer}`}> Service by DeepTrade</h6>
  //             <div className={`${s.footer} text-center justify-content-center`}>
  //                 <NavLink className={s.link} to={WEB_APP_ROUTES.TERMS_OF_USE}>
  //                     회원이용약관
  //                 </NavLink>
  //                 &#8226;
  //                 <NavLink className={s.link} to={WEB_APP_ROUTES.PRIVACY_POLICY}>
  //                     개인정보처리방침
  //                 </NavLink>
  //             </div>
  //         </div>
  //     </>
  // )

  // useEffect(() => {
  //   let isMountedComponent = true;
  //   if (isMountedComponent) {
  //     setTimeout(() => {
  //       setIsShowTipBannner(true);
  //     }, 1000);
  //   }

  //   return () => {
  //     isMountedComponent = false;
  //   };
  // }, []);
  const handleClose = () => {
    setIsShowTipBannner(false);
    console.log("clicked");
  };
  const handleClick = () => {
    const newWindow = window.open(
      "https://xpercent.io/tip",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
    console.log("clicked");
  };
  const styles = {
    button: {
      display: "inline-block",
      padding: "0.3rem 1rem",
      backgroundColor: "rgb(131 146 162)",
      color: "#fff",
      border: "none",
      borderRadius: "0.25rem",
      cursor: "pointer",
      width: "127px",
    },
  };
  return (
    <div ref={main}>
      {/* <HeaderUnsigned/> */}
      {/* {isShowTipBannner && (
        <div className="tip_banner">
          <div className="tip_banner_inner">
            <div className="tip_banner_wrapper">
              <div
                style={{
                  color: "#fff",
                  paddingBottom: "0px",
                  fontSize: "17px",
                  paddingBottom: "5px",
                }}
              >
                나의 투자 능력은 어떨까? AI 섀넌과 대결해보고 싶다면!
              </div>
              <div
                className="primary-btn"
                style={styles.button}
                onClick={handleClick}
              >
                테스트하러가기
              </div>
            </div>
            <div className="tip_banner_close" onClick={handleClose}>
              <svg
                width="35"
                height="35"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" fill="white" fill-opacity="0" />
                <path
                  d="M14 14L34 34"
                  stroke="#fff"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14 34L34 14"
                  stroke="#fff"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>{" "}
        </div>
      )} */}

      <Header />

      <AppStoreComponent />
      <Home />
      <XpercentTipComponent />
      <hr style={{ margin: 0 }} />
      <VideoSection />

      <AboutPart />

      {/* <div 
            className={s.mainContainer}
            style={{
                height: isTabletOrMobile && 'calc(100vh - 60px)',
                display: !isTabletOrMobile && 'flex',
                alignItems: !isTabletOrMobile && 'center'
            }}
        >
            <div className={`container justify-content-center `}>
                <div className={`row justify-content-center mx-auto ${s.logo}`}
                    style={{
                        paddingTop: !isTabletOrMobile && 0
                    }}
                >
                    <h2 className={`col-12 text-center m-0 ${s.xpercent}`}>XPercent</h2>
                    <svg className={s.svg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21.1,-21.7C27.9,-14.3,34.5,-7.1,36.1,1.7C37.8,10.4,34.5,20.9,27.7,27.6C20.9,34.3,10.4,37.4,1.6,35.7C-7.2,34.1,-14.4,27.9,-19.6,21.1C-24.8,14.4,-27.9,7.2,-28.4,-0.5C-28.8,-8.1,-26.6,-16.2,-21.4,-23.6C-16.2,-31.1,-8.1,-37.8,-0.5,-37.3C7.1,-36.8,14.3,-29.1,21.1,-21.7Z"
                            width="100%" height="100%" transform="translate(50 50)"
                            style={{transition: 'all 0.3s ease 0s'}} stroke="url(#sw-gradient)"/>
                    </svg>
                    <svg className={s.svg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M30,-28.9C37.3,-22.7,40.6,-11.4,38.4,-2.1C36.3,7.2,28.9,14.3,21.6,21.2C14.3,28.1,7.2,34.7,-0.2,34.9C-7.5,35,-15,28.7,-20.2,21.9C-25.4,15,-28.3,7.5,-30.1,-1.8C-31.9,-11.2,-32.7,-22.3,-27.5,-28.4C-22.3,-34.6,-11.2,-35.6,0.1,-35.8C11.4,-35.9,22.7,-35,30,-28.9Z"
                            width="100%" height="100%" transform="translate(50 50)"/>
                    </svg>
                    <svg className={s.svg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24,-23C29.9,-18,32.8,-9,32.1,-0.7C31.4,7.6,27,15.2,21.1,20.5C15.2,25.9,7.6,29.1,-0.4,29.5C-8.4,29.9,-16.7,27.5,-23,22.1C-29.3,16.7,-33.5,8.4,-33.2,0.3C-33,-7.9,-28.3,-15.7,-22,-20.6C-15.7,-25.6,-7.9,-27.5,0.6,-28.1C9,-28.7,18,-27.9,24,-23Z"
                            width="100%" height="100%" transform="translate(50 50)"/>
                    </svg>
                    <svg className={s.svg} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20.6,-19.3C26.9,-14.4,32.3,-7.2,32.9,0.6C33.5,8.4,29.3,16.8,23,24.1C16.8,31.5,8.4,37.9,0.4,37.5C-7.7,37.1,-15.3,30,-22.4,22.7C-29.4,15.3,-35.9,7.7,-36.4,-0.5C-37,-8.7,-31.5,-17.4,-24.5,-22.4C-17.4,-27.3,-8.7,-28.5,-0.8,-27.7C7.2,-27,14.4,-24.3,20.6,-19.3Z"
                            width="100%" height="100%" transform="translate(50 50)"
                            style={{transition: 'all 0.3s ease 0s'}} stroke="url(#sw-gradient)"/>
                    </svg>
                </div>
                <div className={`text-center ${s.text}`}>
                    <span>The one and only AI Investor</span>
                </div>
                {isTabletOrMobile &&
                <div>
                    <MobileMain/>
                    <div className={`${s.downArrow} ${downButtonHidden ? s.hidden : ''}`}
                        onClick={() => {
                            scrollDown();
                        }}
                    >
                        <div style={{fontWeight: 'bold'}}>
                            서비스 소개
                        </div>
                        <DownOutlined style={{fontSize: 30}} />
                    </div>
                </div>
                }
            </div>
        </div> */}
      {/* <div
            ref={introduction}
            className={s.introContainer}
            style={{
                display: !isTabletOrMobile && 'none'
            }}
        >
            <div className={`${s.upArrow} ${upButtonHidden ? s.hidden : ''}`}
                onClick={() => {
                    scrollUp();
                }}
            >
                <div style={{fontWeight: 'bold'}}>
                    로그인
                </div>
                <UpOutlined style={{fontSize: 30}} />
            </div>
            <Swiper
            ref={introSwiper}
            spaceBetween={50}
            pagination={{
                "dynamicBullets": true
                }}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            > 
         <SwiperSlide>
                    <div className={s.swiperSlideContainer}>
                        <div className='row overview-chart'>
                            <div className="col-12 pt-3 pb-2 text-center">
                                XPercent 예측 현황
                            </div>
                            <div className="col-12 px-3" id="chart_plot">
                                <MultiAxisLine/>
                            </div>
                        </div>
                        <p>
                            (XPercent 연 수익률: 1주 모델)
                            <br/><br/>
                            XPercent 기본 모델은 지난 <span className={s.red_bold}>1년 간 {parseFloat(model).toFixed(1)}%</span>의 <span className={s.red_bold}>수익률</span>을 기록하였으며, <br />
                            이는 동일 기간 코스피 지수보다 <span className={s.red_bold}>{parseFloat(model-kospi).toFixed(1)}%</span>, 코스닥 지수보다 <span className={s.red_bold}>{parseFloat(model-kosdaq).toFixed(1)}%</span> 높은 수치입니다.
                        </p>
                    </div>
                </SwiperSlide>  */}
      {/* <SwiperSlide>
                <div className={s.swiperSlideContainer}>
                        <img className={s.mainInfoImage} src="/static/main_signal_example.png"/>
                        <p>
                            <br/>
                            (XPercent 서비스: Signal)
                        </p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={s.swiperSlideContainer}>
                        <p style={{margin: '0px 20px'}}>
                            XPercent는 <strong>인공지능 기반의 모델</strong>이 상승 종목을 선정해 추천하는 주가 예측 서비스입니다.
                            일반적인 투자 프로그램, 사람이 보지 못하는 패턴을 파악해서 안정적이고 높은 수익률을 제공합니다.
                            <br/><br/><br/>
                            투자자는 XPercent를 이용해 본인의 투자 성향 등 여러 조건을 고려, 예측 기간이나 분야별 섹터 등을 설정할 수 있습니다.
                        </p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div> */}
      {/* {!isTabletOrMobile && <AboutPart/>} */}
      {/* <Modal
            // bodyStyle={{padding:'0'}}
            centered
            closable={false}
            closeIcon={<CloseOutlined style={{color: 'white'}} />}
            visible={false}
            onCancel={() => setShowModal(false)}
            width={400}
            footer=
                {<Row justify={'space-between'}>
                    <div class="form-check" style={{marginTop:'auto', marginBottom:'auto'}}>
                        <input class="form-check-input modal-today-close" type="checkbox" id="flexCheckDefault" 
                               onClick={() => setCookie("mycookie", 'popupEnd', 7)} />
                        <label class="form-check-label" for="flexCheckDefault">
                            일주일동안 보지 않기 
                        </label>
                    </div>

                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setShowModal(false)}>닫기</button>
                </Row>}
        >
            <Row justify={'center'}>
                <Col span={24}>
                    {isTabletOrMobile 
                    ? 
                    <img src="/static/event_img/220608_event_moblie.png" alt="deepTrade logo" width={'100%'} onClick={() => setShowModal(false)} 
                         style={{borderRadius: '10px 10px 0 0'}}/>
                    :
                    <img src="/static/event_img/220608_event_pc.png" alt="deepTrade logo" width={'100%'} onClick={() => setShowModal(false)} 
                    style={{borderRadius: '10px 10px 0 0'}}/>
                    }
                </Col>
            </Row>
            </Modal> */}
      {/* <Row justify={'space-between'}>
                <span onClick={() => setCookie("mycookie", 'popupEnd', 1)}>다시 보지 않기</span>
                <span onClick={() => setShowModal(false)}>닫기</span>
            </Row> */}
    </div>
  );
};

export default Main;
