

const SET_TOPIK = 'SET_TOPIK'
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING'
const TOGGLE_ANSWERED = 'TOGGLE_ANSWERED'
const TOPIK_START = 'TOPIK_START'
const TEST_FINISH = 'TEST_FINISH'
const ADD_CORRECT_ANSWERS = 'ADD_CORRECT_ANSWERS'
const REFRESH = 'REFRESH'

const SET_DATE = 'SET_DATE'
const SET_MODEL = 'SET_MODEL'
const SET_SECTOR = 'SET_SECTOR'


// let readingTasks = [];

let initialState = {
    // topik_tests: [],
    // correct: 0,
    // isFetching: false,
    // answered: [],
    // reading_tasks: readingTasks,
    // topik_start: false,
    // test_finish: false,

    date: '',
    model: '',
    sector: 'ALL',

}

const signalReducer = (state=initialState, action)=>{
    switch (action.type) {
        // case TOPIK_START: {
        //     return {...state, topik_start: action.topik_start}
        // }
        // case SET_TOPIK: {
        //     return{...state, topik_tests: action.topik_tests}
        // }
        // case TOGGLE_IS_FETCHING: {
        //     return {...state, isFetching: action.isFetching}
        // }
        // case TOGGLE_ANSWERED: {
        //     return {
        //         ...state,
        //         answered: state.answered.find(a=>{
        //             return a.qNum === action.answer.qNum;
        //         })? state.answered.map(a=>{
        //             if(a.qNum === action.answer.qNum){
        //                 return {...a, uAnswer: action.answer.uAnswer}
        //             }
        //             return a
        //         }): [...state.answered, action.answer],
        //       }
        // }
        // case TEST_FINISH: {
        //     return {...state, test_finish: action.test_finish}
        // }
        // case ADD_CORRECT_ANSWERS: {
        //     return {...state, correct: state.correct += 1}
        // }
        case REFRESH: {
            return {...state, ...initialState}
        }

        
        case SET_DATE: {
            return {
                ...state,
                date: action.date,
            }
        }

        case SET_MODEL: {
            return {
                ...state,
                model: action.model,
            }
        }

        case SET_SECTOR: {
            return {
                ...state,
                secotr: action.secotr,
            }
        }
        default:
            return state

    }
}

export const setDate = newdate =>{
    return{
        type: SET_DATE,
        date: newdate
    }
}

export const setModel = newmodel =>{
    return{
        type: SET_MODEL,
        model: newmodel
    }
}

export const setSector = newsector =>{
    return{
        type: SET_SECTOR,
        secotr: newsector
    }
}






export default signalReducer;