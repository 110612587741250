import React from "react";
import "./Home.css";
import favImage from "../../../../../src/images/fav.png";
import ilgiImage from "../../../../../src/images/ilgi.png";
import signalImage from "../../../../../src/images/signal.png";

function Home() {
  return (
    <div>
      <div className="home__container1">
        <div className="home__inner">
          <div className="home__content">
            <div className="home__left">
              <h1>주식 시장 일기 예보</h1>
              <h2 className="white__text_desc">
                AI 섀넌이 국내외 데이터를 통해 분석한 앞으로의 1개월 증시 전망을
                제공합니다.{" "}
              </h2>{" "}
            </div>

            <div
              className="home__right1"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {" "}
              <img src={ilgiImage} alt="favimage" />{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="home__container2">
        <div className="home__inner">
          <div className="home__content">
            <div
              className="home__left"
              data-aos-duration="500"
              data-aos="fade-right"
              data-aos-offset="300"
            >
              {" "}
              <h1 className="black__text">주식 도깨비</h1>
              <h2 className="black__text_desc">
                나의 관심 종목을 추가하면 AI섀넌이 최적의 매매 타이밍을
                제공합니다.{" "}
              </h2>
            </div>
            <div
              className="home__right1"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src={favImage} alt="favimage" />{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="home__container1">
        <div className="home__inner">
          <div className="home__content">
            <div
              className="home__left"
              data-aos-duration="500"
              data-aos="fade-right"
              data-aos-offset="300"
            >
              <h1>AI 섀넌의 추천 종목</h1>
              <h2 className="white__text_desc">
                기간별 (장/단기), 섹터 등 옵션을 선택하면 상승 종목을 선정해
                추천합니다.{" "}
              </h2>
            </div>
            <div
              className="home__right1"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {" "}
              <img src={signalImage} alt="signalImage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
