import React from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";
import {getUserSelector} from "../../redux/user-selector";
import {WEB_APP_ROUTES} from "../../constants/routes";

/**
 *    Recommended - Don't touch
 * */


export const VerifyAuth = ({component: Component, authRoles, ...rest}) => {
    const user = useSelector(getUserSelector)

    if(authRoles.includes(user.role)) { //for signed users and user is signed in
        if(user.is_push)
        {
            return <Component {...rest} />
        }
        if(!user.is_surveydone) { // if user did not completed the survey
            return <Redirect  to={WEB_APP_ROUTES.TUTORIAL} />
        }

        return <Component {...rest} />
    }
    if(authRoles.length === 0 && user.role === '') { //page is for only guest and user is signed OUT
        return <Component {...rest} />
    }
    if(authRoles.length === 0) { // if page for only guest but user is signed IN
        return user.is_push ? <Redirect  to={WEB_APP_ROUTES.SIGNALAUTOWEEK} /> : <Redirect  to={WEB_APP_ROUTES.SIGNAL} /> // Signal is the Main page for Push users
    }
    if(user.role == "PUSH_ADMIN")
    {
        return <Redirect  to={WEB_APP_ROUTES.PUSHADMIN} />
    }
    // if page for signed users but user is unsigned IN
    return <Redirect  to={WEB_APP_ROUTES.MAIN} />

}