/**
 * List of Feature Permissions
 */
export const FEATURE_PERMISSIONS = {
    EIGHT_WEEKS_MODEL: 'EIGHT_WEEKS_MODEL',
    FOUR_WEEKS_MODEL: 'FOUR_WEEKS_MODEL',
    TWO_WEEKS_MODEL: 'TWO_WEEKS_MODEL',
    ONE_WEEK_MODEL: 'ONE_WEEK_MODEL',
    ONE_DAY_MODEL: "ONE_DAY_MODEL",
    SECTORS: 'SECTORS',
    TOP_SECTORS: 'TOP_SECTORS'
}
