import React from "react";
import "./AppStoreComponent.css";

import appleBtn from "../../../../../src/images/appleBtn.png";
import appstoreIcon from "../../../../../src/images/appstoreIcon.png";
import playStoreBtn from '../../../../../src/images/playStoreBtn.png'
function AppStoreComponent() {
  return (
    <div className="appstore__container">
      <div className="appstore__inner">
        <div className="appstore__content">
          <div className="appstore__left">
            <div className="header" data-aos="fade-right">
              <h1>
                인공지능 기반의 모델이 상승 종목을 선정해 추천하는{" "}
                <span style={{ color: "#374351", fontWeight: "bold" }}>
                  주식 시장 예측 서비스
                </span>
              </h1>
            </div>
            <div className="desc" data-aos="fade-right"
    data-aos-duration="500"
    >
              <p>
                일반적인 투자 프로그램, 사람이 보지 못하는 패턴을 파악해서
                안정적이고 높은 수익률을 제공합니다.
              </p>
            </div>

            <div className="tags">
              <div className="tag__box" data-aos="zoom-in-up" data-aos-duration="500">
                #주식
              </div>
              <div className="tag__box" data-aos="zoom-in-up" data-aos-duration="500">
                #로보어드바이저
              </div>
              <div className="tag__box" data-aos="zoom-in-up" data-aos-duration="500">
                #AI
              </div>
              <div className="tag__box" data-aos="zoom-in-up" data-aos-duration="500">
                #인공지능
              </div>
              <div className="tag__box" data-aos="zoom-in-up" data-aos-duration="500">
                #Xpercent
              </div>
            </div>
            <div className="buttons">
              <div className="btn" data-aos="zoom-in" data-aos-duration="500">
                <a
                  href="https://play.google.com/store/apps/details?id=com.xpct"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={playStoreBtn}
                    alt="img"
                    height={"50rem"}
                    width={"140rem"}
                  />
                </a>
              </div>
              <div className="btn" data-aos="zoom-in">
                <a
                  href="https://apps.apple.com/kr/app/xpercent/id1632561591?l"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={appleBtn}
                    alt="img"
                    height={"50rem"}
                    width={"140rem"}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="appstore__right" data-aos="fade-left"
     data-aos-anchor="#example-anchor"
     data-aos-offset="500"
     data-aos-duration="500">
            <img src={appstoreIcon} alt="appstoreimage" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppStoreComponent;
