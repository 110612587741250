import s from "./Admin.module.css";
import { adminApi, appAdminApi } from "../../api/api";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Input, Dropdown, Menu, Icon, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";

const MobilePaymentAdmin = (props) => {
  const [dropdownLabel, setDropdownLabel] = useState("All");
  const [users, setUsers] = React.useState([]);
  const [freeUsers, setFreeUsers] = useState([]);
  const [basicUsers, setBasicUsers] = useState([]);
  const [premiumUsers, setPremiumUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [value, setValue] = React.useState();

  const payment_status_options = {
    0: "Failed",
    1: "Success",
  };

  const handleMenuClick = async (e) => {
    setDropdownLabel(e.domEvent.target.innerText);

    let filterResult = await appAdminApi.appMobilePaymentUserFilter(e.key);
    if (filterResult.status == 200) {
      let payment_user = [];
      filterResult.data.map((u) => {
        if (u.productId === "premium") {
          u["total_payment"] = "50,000";
          payment_user.push(u);
        } else if (u.productId === "basic") {
          u["total_payment"] = "25,000";
          payment_user.push(u);
        }
      });
      setUsers(payment_user);
      setFilteredUsers(payment_user);
    } else {
      message.info("Something went wrong");
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">전체</Menu.Item>
      <Menu.Item key="2">Basic</Menu.Item>
      <Menu.Item key="3">Premium</Menu.Item>
    </Menu>
  );
  const columns = [
    // {
    //     cell:(row) => <button className={s.button_cancel}>Ż��</button>,
    //     ignoreRowClick: true,
    //     allowOverflow: true,
    //     button: true,
    // },
    {
      name: "결제일시",
      selector: (row) => row.purchase_date,
      sortable: true,
    },
    {
      name: "아이디",
      selector: (row) => row.username,
      sortable: true,
    },

    {
      name: "멤버십",
      selector: (row) => row.productId,
      sortable: true,
    },
    {
      name: "결제금액",
      selector: (row) => row.total_payment,
      sortable: true,
    },
    {
      name: "만료일",
      selector: (row) => row.expires_date,
      sortable: true,
    },
    {
      name: "취소일",
      selector: (row) => row.cancellation_date,
      sortable: true,
    },
    // {
    //   name: "결제 상태",
    //   selector: (row) => payment_status_options[row.payment_status],
    //   sortable: true,
    // },
  ];

  useEffect(async () => {
    let result = await appAdminApi.appPaidUserListView();

    let payment_user = [];
    result.data.map((u) => {
      if (u.productId === "premium") {
        u["total_payment"] = "50,000";

        payment_user.push(u);
      } else if (u.productId === "basic") {
        u["total_payment"] = "25,000";
        payment_user.push(u);
      }
    });

    setUsers(payment_user);

    setFilteredUsers(payment_user);
    result.data?.map((u) => {
      if (u.productId === "basic") {
        setBasicUsers((basicUsers) => [...basicUsers, u]);
      } else if (u.productId === "premium") {
        setPremiumUsers((premiumUsers) => [...premiumUsers, u]);
      }
    });
  }, []);

  const search = (keyword) => {
    let data = users.filter((val) => {
      return (
        val["username"].includes(keyword) ||
        val["membership_type"].includes(keyword)
      );
    });
    setFilteredUsers(data);
  };

  return (
    <>
      <div
        className={s.list_container}
        style={{ height: props.isSummary ? 677 : "calc(100vh - 90px)" }}
      >
        <div className={s.title}>결제 내역</div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div className={s.sub_title}>유료 회원수: {users.length}</div>
          <Dropdown.Button overlay={menu} icon={<DownOutlined />}>
            {dropdownLabel}
          </Dropdown.Button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "50%",
          }}
        >
          <div className={s.sub_title}>Basic: {basicUsers?.length}</div>
          <div className={s.sub_title}>Premium: {premiumUsers?.length}</div>
        </div>

        <Input
          onChange={(e) => {
            search(e.target.value);
          }}
          style={{ marginTop: 10, marginBottom: 10 }}
          placeholder="Search (username, name, membership)"
          prefix={<SearchOutlined />}
        />
        <DataTable
          columns={columns}
          data={filteredUsers}
          pagination="true"
          paginationPerPage={props.rows}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            tableWrapper: {
              style: {
                display: "block",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default MobilePaymentAdmin;
