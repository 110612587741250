import React from 'react'
import s from './SimulationInfo.module.css'
import ChangePassword from "../changePassword";
import {Col, Form, Input, message, Modal, Rate, Row} from "antd";
import { Typography } from 'antd';
import { useHistory, useLocation } from "react-router-dom";
import { useState } from 'react';

const { Paragraph } = Typography;

const SimulationInfo = ( {user} ) => {
    const [form] = Form.useForm();
    // const history = useHistory();
    // const location = useLocation();

    const [overallAmount, setOverallAmount] = useState(0)
    const [inputAmount, setInputAmount] = useState(0)
    const [remainderAmount, setRemainderAmount] = useState(0)

    const onFinish = (values) => {
        console.log('values')
        form.resetFields()
    };


    console.log({user})

    return <Row justify={'center'}>
        <Col xs={24} sm={24} md={24} lg={18} xl={12}>
            <Form
                form={form}
                name="basic"
                onFinish={null}
                onFinishFailed={values=> console.log(values)}
                initialValues={{
                    overallAmount: overallAmount,
                    inputAmount: inputAmount,
                    remainderAmount: remainderAmount,
                }}
            >
                <div className={`${s.layer}`}
                     data-aos={"zoom-in"} data-aos-duration={"1000"}
                     >
                    <Row justify={'space-between'}>
                        <Col xs={12} sm={10} md={6} lg={8} xl={10}>
                            <h5>
                                나의 기본 정보
                            </h5>
                        </Col>
                    </Row>
                    <div className={s.inLayer}>
                        <div style={{marginRight: 20}}>
                            <Row justify={'end'}>
                                <Col span={12}>
                                    <label htmlFor="id_username" className={s.label}>총 가용금액</label>
                                    <Form.Item
                                        name="overallAmount"
                                        rules={[
                                            {
                                                required: true,
                                                message: '금액을 입력하세요.',
                                            }
                                        ]}
                                    >
                                        <Input className={s.field} value={overallAmount}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={'end'}>
                                <Col span={12}>
                                    <label htmlFor="id_username" className={s.label}>적용할 금액</label>
                                    <Form.Item
                                        name="inputAmount"
                                        rules={[
                                            {
                                                required: true,
                                                message: '금액을 입력하세요.',
                                            }
                                        ]}
                                    >
                                        <Input className={s.field} value={inputAmount}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify={'end'}>
                                <Col span={12}>
                                    <label htmlFor="id_username" className={s.label}>보유할 금액</label>
                                    <Form.Item
                                        name="remainderAmount"
                                        rules={[
                                            {
                                                required: true,
                                                message: '금액을 입력하세요.',
                                            }
                                        ]}
                                    >
                                        <Input className={s.field} value={remainderAmount} readOnly/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Row justify={'center'}>
                        <Col  xs={10} sm={12} md={12} lg={23} xl={15}>
                            <button className={`btn btn-dark shadow ${s.button}`} onClick={() => null}>
                                적용하기
                            </button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Col>
    </Row>

}

export default SimulationInfo